import React, { useState,useRef,useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Topbar from "../../Layout/Topbar";
import "./BreakAndViolationReport.css";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS
import Select from 'react-select';
import filterResetIcon from '../../../assets/filter_reset3.png';
import filterResetIconOne from '../../../assets/1filter_reset.png'
import PaidIcon from '../../../assets/filter_icons/paid.png';
import UnpaidIcon from '../../../assets/filter_icons/unpaid.png';
import RestIcon from '../../../assets/filter_icons/rest_break.png';
import MealIcon from '../../../assets/filter_icons/meal_break.png';
import AcceptedIcon from '../../../assets/filter_icons/break_accepted.png';
import RejectedIcon from '../../../assets/filter_icons/break_reject.png';
import ExpiredIcon from '../../../assets/filter_icons/break_expired.png';
import ActiveIcon from '../../../assets/filter_icons/break_send.png';
import OpenViolation from '../../../assets/filter_icons/open_violation.png';
import ApprovedViolation from '../../../assets/filter_icons/violation_approved.png';
import Shimmer from "../../Common/Shimmer";
import * as XLSX from 'xlsx';
import moment from 'moment';
import { apiRequest } from "../../../services/api";
import { encryptData } from "../../Common/encryptionUtils";
import CSVExportImage from "../../../assets/csv.png";
import Close from '../../../assets/close.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { faL } from "@fortawesome/free-solid-svg-icons";


const BreakAndViolationReport = ({selectedPremise,premiseBackButton}) => {
  
  const [filterOpen, setFilterOpen] = useState(true);
  const [resetFilter, setResetFilter] = useState(false);
  const [resetFilterBtn, setResetFilterBtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);// Keep track of the selected status
  const [showDateOptions, setShowDateOptions] = useState(true); // For Payment Status toggle
  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // For Payment Status toggle
  const [showBreakOptions, setShowBreakOptions] = useState(false); // For Break Type toggle
  const [showStatusOptions, setShowStatusOptions] = useState(false); 
  const [showViolationStatusOptions, setShowViolationStatusOptions] = useState(false); 
  const [showRemedyOptions, setShowRemedyOptions] = useState(false); // For Remedy toggle
  const [showWaivableOptions, setShowWaivableOptions] = useState(false); // For Remedy toggle
  const [isLoading, setIsLoading] = useState(true);

  const [paymentStatusData, setPaymentStatusData] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]); 
  const [breakTypeData, setbreakTypeData] = useState([]);
  const [selectedBreakType, setSelectedBreakType] = useState([]);
  const [selectedBreakStatus, setSelectedBreakStatus] = useState([]);
  const [selectedWaivableReasons, setSelectedWaivableReasons] = useState([]);
  const [selectedViolationStatuses, setSelectedViolationStatuses] = useState([]);
  const [selectedRemedies, setSelectedRemedies] = useState([]);
  const [violationRemedyData, setViolationRemedyData] = useState([]);
  const [waivableReasonsData, setWaivableReasonsData] = useState([]);
  const [breakStatusData, setBreakStatusData] = useState([]);
  const [violationStatusData, setViolationStatusData] = useState([]);
  const [breakCountData, setBreakCountData] = useState([]);
  const [violationCountData, setViolationCountData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [breakTotalCounts,setbreakTotalCounts] = useState(false);

  const [filterTableData, setFilterTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedOption, setSavedOption] = useState(); // State to store the saved object
  const [remedyOption, setRemedyOption] = useState([]);
  const [selectedData, setSelectedData] = useState();

  const handleButtonClick = (data) => {
    console.log('dataValue--->',data)
    setIsModalOpen(true);
    setSelectedData(data);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  console.log('selectedViolationStatuses--->',selectedViolationStatuses)

  const saveSelection = (values) => {
    setIsLoading(true);
    console.log('values.selectedOption--->',values.selectedOption)
    if (values.selectedOption) {
      setSavedOption(values.selectedOption); // Save the entire selected object
    }
    handleCloseModal(); // Close modal
    const handleViolationApprovedAPI = async() => {
      try{
        const authToken = localStorage.getItem('authToken');
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        console.log('rules_and_alerts_rule_notification_data_id--->',selectedData.rules_and_alerts_rule_notification_data_id);
        console.log('rules_and_alerts_violation_notification_data_id--->',selectedData.rules_and_alerts_violation_notification_data_id);
        console.log('rules_and_alerts_violation_notification_data_id--->',selectedData.rules_and_alerts_violation_notification_data_id);

    
        const payload = {
          premise_id: encryptData(selectedPremise.premise_id.toString()),
          rules_and_alerts_rule_notification_data_id: encryptData(""),
          rules_and_alerts_violation_notification_data_id: encryptData(selectedData.rules_and_alerts_violation_notification_data_id.toString()),
          rules_and_alerts_waivable_reasons_id: encryptData(""),
          rules_and_alerts_violation_remedy_id: encryptData(values.selectedOption.rules_and_alerts_violation_remedy_id.toString()),
          status: encryptData("approved"),
        };
    
        console.log('Approve Payload:', payload);
    
        const response = await apiRequest(
          'api/user/insertNotificationStatus',
          'POST',
          payload,
          headers
        );
    
        console.log('Response:', response);
    
        if (response?.data?.status_code === 200) {
            toast.success(response?.data?.message, {
              position: 'top-right',
              autoClose: 4000,
            });
            setIsLoading(false);
            fetchUserAndDateDetails();
        }else{
          toast.error(response?.data?.message, {
            position: 'top-right',
            autoClose: 4000,
          });
          setIsLoading(false);
        }

      }catch(error){
        console.error('Error fetching data:', error);
        toast.error(`An error occurred: ${error.message}`, {
          position: 'top-right',
          autoClose: 4000,
        });
        setIsLoading(false);
      }
    }
    handleViolationApprovedAPI();
  };

  // Filter out the option with "remedy": "empty"
  const filteredRemedyOption = remedyOption.filter(option => option.remedy !== "empty");

  // Map the filtered remedy options into a format suitable for react-select
  const options = filteredRemedyOption.map((option) => ({
    value: option.rules_and_alerts_violation_remedy_id,
    label: `${option.remedy}`,
    ...option, // Include the entire object in the option
  }));

  // Formik Validation Schema using Yup
  const validationSchema = Yup.object({
    selectedOption: Yup.object().required('Please select a remedy option'),
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [tempDateRange, setTempDateRange] = useState(dateRange);

  const handleApplyDatePicker = () => {
    setDateRange(tempDateRange); // Apply the selected date range
    setShowDatePicker(false); // Close the modal
  };

  const handleCloseDatePicker = () => {
    setTempDateRange(dateRange); // Revert to the original date range
    setShowDatePicker(false); // Close the modal
  };

  const filterOptionReset = (data) => {
    setResetFilterBtn(true);
    setDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ])
    setTempDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ])
    setStaffId([]);
    setBreakCountData([]);
    setViolationCountData([]);
  }

  const [isRotating, setIsRotating] = useState(false);

  const handleResetClick = () => {
    // setResetFilter(true);
    fetchFilterSectionDetails()
    setIsRotating(true);
    filterOptionReset(resetFilter);

    // Remove the class after animation ends
    setTimeout(() => setIsRotating(false), 600); // Match animation duration
  };

  console.log('selectedPremise--->',selectedPremise)

  const scrollRef = useRef(null);
  const scrollViolationRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollViolation = (direction) => {
    if (scrollViolationRef.current) {
      const scrollAmountValue = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollViolationRef.current.scrollBy({ left: scrollAmountValue, behavior: "smooth" });
      
      // Debugging scroll position
      console.log(scrollViolationRef.current.scrollLeft);
    }
  };
  
  const handleBreakTypeChange = (status) => {
    setSelectedBreakType((prevStatus) => {
      const updatedStatus = prevStatus.includes(status)
        ? prevStatus.filter((item) => item !== status)
        : [...prevStatus, status];
      console.log('Updated Break Type Status:s', updatedStatus);
      // Use latest state for payment statuses
      applyCombinedFilters(updatedStatus, [...selectedPaymentStatus],[...selectedBreakStatus],[...selectedWaivableReasons],[...selectedViolationStatuses],[...selectedRemedies]);
      return updatedStatus;
    });
  };
  
  const handleStatusChange = (status) => {
    setSelectedPaymentStatus((prevStatus) => {
      const updatedStatus = prevStatus.includes(status)
        ? prevStatus.filter((item) => item !== status)
        : [...prevStatus, status];
      console.log('Updated Payment Status:', updatedStatus);
      // Use latest state for break types
      applyCombinedFilters([...selectedBreakType], updatedStatus,[...selectedBreakStatus],[...selectedWaivableReasons],[...selectedViolationStatuses],[...selectedRemedies]);
      return updatedStatus;
    });
  };

  const handleStatusCheckboxChange = (data) => {
    setSelectedStatusvalue((prevStatus) => {
      const updatedStatus = prevStatus.some((item) => item.breakstatus === data.breakstatus)
        ? prevStatus.filter((item) => item.breakstatus !== data.breakstatus)
        : [...prevStatus, data]; // Add the new status if it's not already selected
      
      console.log('Updated Selected Status Value:', updatedStatus);

      const finalValue = updatedStatus.map(item => item.breakstatus);
      setSelectedBreakStatus(finalValue);
  
      console.log('finalValue:', finalValue);
      // Apply filters using the latest selected values for break types and payment statuses
      applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus],finalValue,[...selectedWaivableReasons],[...selectedViolationStatuses],[...selectedRemedies]);
      
      return updatedStatus;
    });
  };
  
  
  
  const applyCombinedFilters = (breakTypes, paymentStatuses, statusValues,waivableReasons,violationStatusValue,violationRemedy) => {
    console.log('Applied Break Types:', breakTypes);
    console.log('Applied Payment Statuses:', paymentStatuses);
    console.log('Applied Status Values:', statusValues);
    console.log('Applied waivableReasons:', waivableReasons);
    console.log('Applied violationStatusValue:', violationStatusValue);
    console.log('Applied violationRemedy:', violationRemedy);

    setResetFilterBtn(false);
  
    let filteredData;
    const counts = [
      { "title": "paid", "count": 0 },
      { "title": "unpaid", "count": 0 },
      { "title": "Rest break", "count": 0 },
      { "title": "Meal break", "count": 0 },
      { "title": "accepted", "count": 0 },
      { "title": "rejected", "count": 0 },
      { "title": "expired", "count": 0 }
    ];

    const violationCounts = [
      { "title": "open", "count": 0 },
      { "title": "approved", "count": 0 },
    ];
    if (breakTypes.length === 0){
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    }
    else if (paymentStatuses.length === 0){
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    }
    else if (statusValues.length === 0){
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    }
    else if (waivableReasons.length === 0){
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    }
    else if (violationStatusValue.length === 0){
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    }
    else if (violationRemedy.length === 0){
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    }
    else if (breakTypes.length === 0 && paymentStatuses.length === 0 && statusValues.length === 0 
      && waivableReasons.length === 0 && violationStatusValue.length === 0 && violationRemedy.length === 0) {
      // If no filters are selected, return all data
      filteredData = tableData;
      console.log('All data:', filteredData);
    } else {
      // Apply all filters
      filteredData = tableData.filter((item) => {
        const matchesBreakType = breakTypes.length === 0 || breakTypes.includes(item.rules_and_alerts_breaktype ? item.rules_and_alerts_breaktype.trim() : "");
        const matchesPaymentStatus = paymentStatuses.length === 0 || paymentStatuses.includes(item.paid ? item.paid.trim() : "");
        const matchesStatusValue = statusValues.length === 0 || statusValues.includes(item.event ? item.event.trim() : "");
        const matchesWaivableReasons = waivableReasons.length === 0 || waivableReasons.includes(item.waivable_reasons);
        const matchesViolationStatus = violationStatusValue.length === 0 || 
        violationStatusValue.includes(item.violation_status === 'yes' ? item.v_event === 'sent' ? 'open' : 'approved' : 'empty');
        const matchesViolationRemedy = violationRemedy.length === 0 || 
        violationRemedy.includes(item.violation_status === 'yes' ? item.v_event === 'approved' ? item.remedy : 'empty' : 'empty');
      

        return matchesBreakType && matchesPaymentStatus && matchesStatusValue && matchesWaivableReasons && matchesViolationStatus && matchesViolationRemedy;
      });
      console.log('Filtered Data:', filteredData);

    const updatedBreakCounts = countBreakData(filteredData);
    const updatedViolationCounts = countViolationData(filteredData);

    setBreakCountData(updatedBreakCounts);
    setViolationCountData(updatedViolationCounts);
    
    // console.log('updatedCounts:', updatedCounts);
    }
  
    // Update the filtered table data
    setFilterTableData(filteredData);
  };

  const countBreakData = (data) => {
    const counts = [
        { "title": "paid", "count": 0 },
        { "title": "unpaid", "count": 0 },
        { "title": "Rest break", "count": 0 },
        { "title": "Meal break", "count": 0 },
        { "title": "accepted", "count": 0 },
        { "title": "rejected", "count": 0 },
        { "title": "expired", "count": 0 }
    ];
    data.forEach(item => {
        // Count for "paid" and "unpaid"
        if (item.paid) {
            const paidIndex = counts.findIndex(entry => entry.title === item.paid);
            if (paidIndex !== -1) counts[paidIndex].count += 1;
        }

        // Count for "rules_and_alerts_breaktype" like "Rest break" and "Meal break"
        if (item.rules_and_alerts_breaktype) {
            const breakTypeIndex = counts.findIndex(entry => entry.title.trim() === item.rules_and_alerts_breaktype.trim());
            if (breakTypeIndex !== -1) counts[breakTypeIndex].count += 1;
        }

        // Count for "status" like "accepted", "rejected", and "expired"
        console.log('item.status-->',item.event)
        if (item.event) {
            console.log('item.status-->',item.event)
            const statusIndex = counts.findIndex(entry => entry.title === item.event);
            if (statusIndex !== -1) counts[statusIndex].count += 1;
        }
    });

    return counts;
  };

  const countViolationData = (data) => {
    const violationCounts = [
        { "title": "open", "count": 0 },
        { "title": "approved", "count": 0 },
    ];
    data.forEach(item => {
        if (item.v_event === 'sent') {
          const openIndex = violationCounts.findIndex(entry => entry.title === "open");
          if (openIndex !== -1) violationCounts[openIndex].count += 1;
        }else if (item.v_event === 'approved') {
            const approvedIndex = violationCounts.findIndex(entry => entry.title === "approved");
            if (approvedIndex !== -1) violationCounts[approvedIndex].count += 1;
        }
    });
    return violationCounts;
  };
  
  
  
  console.log('filterTableData-->',filterTableData)

  console.log('selectedBreakType-->',selectedBreakType)

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchReasonTerm, setSearchReasonTerm] = useState("");
  const [searchRemedyTerm, setSearchRemedyTerm] = useState("");
  const [searchStatusTerm, setSearchStatusTerm] = useState("");
  const [searchViolationStatusTerm, setSearchViolationStatusTerm] = useState("");

  const violationRemedy = violationRemedyData;

  const [selectedRemedy, setSelectedRemedy] = useState(violationRemedy); 

  const filteredRemedy = violationRemedy.filter((data) =>
    data.remedy.toLowerCase().includes(searchRemedyTerm.toLowerCase())
  );

  // const handleRemedyCheckboxChange = (user) => {
  //   if (selectedRemedy.some((selected) => selected.rules_and_alerts_violation_remedy_id === user.rules_and_alerts_violation_remedy_id)) {
  //     setSelectedRemedy(selectedRemedy.filter((u) => u.rules_and_alerts_violation_remedy_id !== user.rules_and_alerts_violation_remedy_id)); // Remove user
  //   } else {
  //     setSelectedRemedy([...selectedRemedy, user]); // Add user
  //   }
  // };

  const handleRemedyCheckboxChange = (user) => {
    setSelectedRemedy((prevRemedy) => {
      const updatedRemedy = prevRemedy.some(
        (selected) => selected.rules_and_alerts_violation_remedy_id === user.rules_and_alerts_violation_remedy_id
      )
        ? prevRemedy.filter(
            (selected) => selected.rules_and_alerts_violation_remedy_id !== user.rules_and_alerts_violation_remedy_id
          ) // Remove user
        : [...prevRemedy, user]; // Add user if not already selected
  
      const finalRemedyValues = updatedRemedy.map(item => item.remedy);
  
      setSelectedRemedies(finalRemedyValues);
  
      console.log('finalRemedyValues:', finalRemedyValues);
  
      // Apply filters using the latest selected remedies
      applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus],[...selectedBreakStatus],[...selectedWaivableReasons],[...selectedViolationStatuses],finalRemedyValues);
  
      return updatedRemedy;
    });
  };
  

  // const handleRemedySelectAll = () => {
  //   if (selectedRemedy.length === violationRemedy.length) {
  //     setSelectedRemedy([]); // Deselect all
  //   } else {
  //     setSelectedRemedy([...violationRemedy]); // Select all users
  //   }
  // };

  const handleRemedySelectAll = () => {
    const allSelected = selectedRemedy.length === violationRemedy.length; // Check if all are selected
    const updatedRemedies = allSelected ? [] : [...violationRemedy]; // Toggle between select all and deselect all
    setSelectedRemedy(updatedRemedies);
    const finalRemedies = updatedRemedies.map(item => item.remedy); // Assuming 'remedy' is the property you want
    console.log('finalRemedies-->',finalRemedies)
    setSelectedRemedies(finalRemedies);
    applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus],[...selectedBreakStatus],[...selectedWaivableReasons],[...selectedViolationStatuses],finalRemedies);
  };
  


  const waivableReasons = waivableReasonsData;

  const [selectedReasons, setSelectedReasons] = useState(waivableReasons); 

  const filteredWaivableReasons = waivableReasons.filter((data) =>
    data.rules_and_alerts_waivable_reasons.toLowerCase().includes(searchReasonTerm.toLowerCase())
  );

  // const handleReasonCheckboxChange = (user) => {
  //   if (selectedReasons.some((selected) => selected.rules_and_alerts_waivable_reasons_id === user.rules_and_alerts_waivable_reasons_id)) {
  //     setSelectedReasons(selectedReasons.filter((u) => u.rules_and_alerts_waivable_reasons_id !== user.rules_and_alerts_waivable_reasons_id)); // Remove user
  //   } else {
  //     setSelectedReasons([...selectedReasons, user]); // Add user
  //   }
  // };

  const handleReasonCheckboxChange = (user) => {
    setSelectedReasons((prevReasons) => {
      const updatedReasons = prevReasons.some(
        (selected) => selected.rules_and_alerts_waivable_reasons_id === user.rules_and_alerts_waivable_reasons_id
      )
        ? prevReasons.filter(
            (selected) => selected.rules_and_alerts_waivable_reasons_id !== user.rules_and_alerts_waivable_reasons_id
          ) // Remove reason
        : [...prevReasons, user]; // Add reason if not already selected

        const finalValue = updatedReasons.map(item => item.rules_and_alerts_waivable_reasons);
        setSelectedWaivableReasons(finalValue);

      console.log('Updated Selected Reasons:', updatedReasons);

      // Apply filters using the latest selected reasons
      applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus],[...selectedBreakStatus],finalValue,[...selectedViolationStatuses],[...selectedRemedies]);

      return updatedReasons;
    });
};

console.log('selectedReasonsData--->',selectedReasons)


  // const handleReasonSelectAll = () => {
  //   if (selectedReasons.length === waivableReasons.length) {
  //     setSelectedReasons([]); // Deselect all
  //   } else {
  //     setSelectedReasons([...waivableReasons]); // Select all users
  //   }
  // };

  const handleReasonSelectAll = () => {
    const allSelected = selectedReasons.length === waivableReasons.length; // Check if all are selected
    const updatedReasons = allSelected ? [] : [...waivableReasons]; // Toggle between select all and deselect all
    setSelectedReasons(updatedReasons);
    const finalValue = updatedReasons.map(item => item.rules_and_alerts_waivable_reasons);
    setSelectedWaivableReasons(finalValue);
    applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus],[...selectedBreakStatus], finalValue,[...selectedViolationStatuses],[...selectedRemedies]);
  };
  


  const status = breakStatusData;

  const [selectedStatusvalue, setSelectedStatusvalue] = useState(status);

  const filteredStatus = status.filter((value) =>
    value.breakstatus.toLowerCase().includes(searchStatusTerm.toLowerCase())
  );

  // const handleStatusCheckboxChange = (data) => {
  //   if (selectedStatusvalue.some((selected) => selected.breakstatus === data.breakstatus)) {
  //     setSelectedStatusvalue(selectedStatusvalue.filter((u) => u.breakstatus !== data.breakstatus)); // Remove user
  //   } else {
  //     setSelectedStatusvalue([...selectedStatusvalue, data]); // Add user
  //   }
  // };

  // const handleStatusSelectAll = () => {
  //   if (selectedStatusvalue.length === status.length) {
  //     setSelectedStatusvalue([]); // Deselect all
  //     applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus], []);
  //   } else {
  //     setSelectedStatusvalue([...status]); // Select all users
  //     applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus],[...status]);
  //   }
  // };

  const handleStatusSelectAll = () => {
    const allSelected = selectedStatusvalue.length === status.length;
    const updatedStatus = allSelected ? [] : [...status]; // Toggle between select all and deselect all
    setSelectedStatusvalue(updatedStatus);
    console.log('updatedStatusupdatedStatus->>>',updatedStatus)
    const finalValue = updatedStatus.map(item => item.breakstatus)
    setSelectedBreakStatus(finalValue);
    applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus], finalValue,[...selectedWaivableReasons],[...selectedViolationStatuses],[...selectedRemedies]);
  };
  

  const violationStatus = violationStatusData;

  const [selectedViolationStatusvalue, setSelectedViolationStatusvalue] = useState(violationStatus); 

  const filteredViolationStatus = violationStatus.filter((value) =>
    value.violationstatus.toLowerCase().includes(searchViolationStatusTerm.toLowerCase())
  );

  // const handleViolationStatusCheckboxChange = (data) => {
  //   if (selectedViolationStatusvalue.some((selected) => selected.violationstatus === data.violationstatus)) {
  //     setSelectedViolationStatusvalue(selectedViolationStatusvalue.filter((u) => u.violationstatus !== data.violationstatus)); // Remove user
  //   } else {
  //     setSelectedViolationStatusvalue([...selectedViolationStatusvalue, data]); // Add user
  //   }
  // };

  const handleViolationStatusCheckboxChange = (data) => {
    setSelectedViolationStatusvalue((prevStatus) => {
      const updatedViolationStatus = prevStatus.some(
        (selected) => selected.violationstatus === data.violationstatus
      )
        ? prevStatus.filter(
            (selected) => selected.violationstatus !== data.violationstatus
          ) // Remove status
        : [...prevStatus, data]; // Add status if not already selected
  
      const finalViolationStatusValue = updatedViolationStatus.map(item => item.violationstatus);
      setSelectedViolationStatuses(finalViolationStatusValue);
  
      console.log('Updated Selected Violation Status:', updatedViolationStatus);
  
      // Apply filters using the latest selected violation status
      applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus], [...selectedBreakStatus],[...selectedWaivableReasons], finalViolationStatusValue,[...selectedRemedies]);
  
      return updatedViolationStatus;
    });
  };
  

  // const handleViolationStatusSelectAll = () => {
  //   if (selectedViolationStatusvalue.length === violationStatus.length) {
  //     setSelectedViolationStatusvalue([]); // Deselect all
  //   } else {
  //     setSelectedViolationStatusvalue([...violationStatus]); // Select all users
  //   }
  // };

  const handleViolationStatusSelectAll = () => {
    const allSelected = selectedViolationStatusvalue.length === violationStatus.length;
    const updatedViolationStatus = allSelected ? [] : [...violationStatus]; // Toggle between select all and deselect all
    setSelectedViolationStatusvalue(updatedViolationStatus);
    console.log('updatedViolationStatus->>>', updatedViolationStatus);
    
    const finalValue = updatedViolationStatus.map(item => item.violationstatus); // Assuming violationstatus is the key you want to use
    setSelectedViolationStatuses(finalValue);
    applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus], [...selectedBreakStatus],[...selectedWaivableReasons], finalValue,[...selectedRemedies]);
  };
  


  // Mock user roles data
  const userList = userData;

  const [selectedUsers, setSelectedUsers] = useState(userList); 

  console.log('selectedUsers--->',selectedUsers)
  
  // Filter user list based on search input
  const filteredUserRoles = userList.filter((user) =>
    user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Checkbox handler
  // const handleCheckboxChange = (user) => {
  //   if (selectedUsers.some((selected) => selected.staff_id === user.staff_id)) {
  //     setSelectedUsers(selectedUsers.filter((u) => u.staff_id !== user.staff_id)); // Remove user
  //     setStaffId()
  //   } else {
  //     setSelectedUsers([...selectedUsers, user]); // Add user
  //   }
  // };

  const handleCheckboxChange = (user) => {
    let updatedSelectedUsers;
    if (selectedUsers.some((selected) => selected.staff_id === user.staff_id)) {
      // Remove the user
      updatedSelectedUsers = selectedUsers.filter((u) => u.staff_id !== user.staff_id);
    } else {
      // Add the user
      updatedSelectedUsers = [...selectedUsers, user];
    }
  
    setSelectedUsers(updatedSelectedUsers);
  
    // Update staffId with only staff_id values
    const updatedStaffIds = updatedSelectedUsers.map((item) => item.staff_id.toString());
    updatedStaffIds.length === 0 ? setStaffId([0]) : setStaffId(updatedStaffIds);
    setBreakCountData([]);
    setViolationCountData([]);
  };
  

  // Select All handler
  // const handleSelectAll = () => {
  //   if (selectedUsers.length === userList.length) {
  //     setSelectedUsers([]); // Deselect all
  //   } else {
  //     setSelectedUsers([...userList]); // Select all users
  //   }
  // };

  const handleSelectAll = () => {
    if (selectedUsers.length === userList.length) {
      // Deselect all
      setSelectedUsers([]);
      setBreakCountData([]);
      setViolationCountData([]);
      setStaffId([0]); 
    } else {
      // Select all users
      setSelectedUsers([...userList]);
      const allStaffIds = userList.map((user) => user.staff_id.toString());
      setStaffId(allStaffIds); // Update staffId with all staff IDs
    }
  };
  


  const [staffId, setStaffId] = useState([]); 

  console.log('staffIdDetails--->',staffId)
  console.log('selectedUsers--->',selectedUsers)

  
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0'); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adds leading zero if month < 10
    const year = date.getFullYear();
    const dateFormat = selectedPremise.date_format === 'd-m-Y' ? `${day}-${month}-${year}` : `${month}-${day}-${year}`;
    return dateFormat;
  };

  const formatDateValue = (date) => {
    const day = date.getDate().toString().padStart(2, '0'); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  
  const breakCountIconDetails = [
    { icon: PaidIcon,count:0, title: "paid" },
    { icon: UnpaidIcon,count:0,title: 'unpaid' },
    { icon: RestIcon,count:0, title: 'Rest break' },
    { icon: MealIcon,count:0, title: 'Meal break' },
    { icon: AcceptedIcon,count:0, title: 'accepted' },
    { icon: RejectedIcon,count:0, title: 'rejected' },
    { icon: ExpiredIcon,count:0, title: 'expired' },
  ];

  console.log('breakCountDatabreakCountData-->',breakCountData)

  breakCountIconDetails.forEach((iconDetail) => {
    const updatedData = breakCountData.find(
      (data) => data.title.toLowerCase().trim() === iconDetail.title.toLowerCase().trim()
    );
    console.log('updatedDataupdatedData--->',updatedData)
    if (updatedData) {
      iconDetail.count = updatedData.count; // Update count
    }
  });

  const totalBreakCount = breakCountIconDetails
  .filter(item => item.title === "Rest break" || item.title === "Meal break")
  .reduce((sum, item) => sum + item.count, 0);


  const violationIconDetails = [
    { icon: OpenViolation, count:0,title: 'open', color: 'text-danger' },
    { icon: ApprovedViolation,count:0, title: 'approved' , color: 'text-danger' },
  ];

  violationIconDetails.forEach((iconDetail) => {
    const updatedData = violationCountData.find(
      (data) => data.title.toLowerCase() === iconDetail.title.toLowerCase().trim()
    );

    if (updatedData) {
      iconDetail.count = updatedData.count; // Update count
    }
  });

  console.log('violationCountData-->',violationCountData)

  const totalViolationCount = violationIconDetails.reduce((sum, item) => sum + item.count, 0);

  const [tableData, setTableData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [checkedRows, setCheckedRows] = React.useState({});

  console.log('start_date--->',formatDateValue(dateRange[0].startDate))
  console.log('endDate--->',formatDateValue(dateRange[0].endDate))

  console.log('tableData--->',tableData)
  console.log('filterTableData--->',filterTableData)

  const fetchUserAndDateDetails = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        toast.error('Authentication token is missing!', {
          position: 'top-right',
          autoClose: 4000,
        });
        return;
      }
  
      if (!dateRange?.[0]?.startDate || !dateRange?.[0]?.endDate || !selectedPremise?.premise_id) {
        toast.error('Required filters are missing. Please check your inputs.', {
          position: 'top-right',
          autoClose: 4000,
        });
        return;
      }
  
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
  
      const payload = {
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
        staff_id: staffId,
        premise_id: encryptData(selectedPremise.premise_id.toString()),
      };
  
      console.log('Payload:', payload);
  
      const response = await apiRequest(
        'api/user/getFilteredBreakRulesData',
        'POST',
        payload,
        headers
      );
  
      console.log('Response:', response);
  
      if (response?.data?.status_code === 200 && response?.data?.data) {
        const { GetfilterBreakrulesdata: fetchedData, countdata } = response.data?.data;
        setBreakCountData(countdata?.breakcounts || []);
        setViolationCountData(countdata?.violationcounts || []);
        if (!fetchedData?.length) {
          // toast.warn('No data found!', {
          //   position: 'top-right',
          //   autoClose: 4000,
          // });
          setFilterTableData([]);
          setIsLoading(false);
          return;
        }
        if(selectedBreakType.length > 0 && selectedPaymentStatus.length > 0 && selectedStatusvalue.length > 0 && selectedReasons.length > 0){
          const filteredData = filterDataByBreakTypeAndPayment(fetchedData,selectedBreakType,selectedPaymentStatus,selectedBreakStatus,selectedWaivableReasons,selectedViolationStatuses,selectedRemedies);
          console.log('filteredData--->1st');
          if(resetFilterBtn === true){
            setFilterTableData(fetchedData);
            setBreakCountData(countdata?.breakcounts || []);
            setViolationCountData(countdata?.violationcounts || []);
          }else{
            setFilterTableData(filteredData);
          }
        }
        else if(selectedBreakType.length === 0 && selectedPaymentStatus.length > 0 && selectedStatusvalue.length > 0){
          setFilterTableData([]);
          console.log('filteredData--->2nd');
        }else{
          console.log('filteredData--->3nd',selectedBreakType);
          console.log('filteredData--->4nd',selectedPaymentStatus);
          console.log('filteredData--->5nd',selectedStatusvalue);
          
          setFilterTableData(fetchedData);
        }
        // Apply filters
  
        setTableData(fetchedData);
        setIsLoading(false);
        console.log('FilterMainSectionAPI Call');
      } else {
        toast.error('Failed to fetch data. Please try again.', {
          position: 'top-right',
          autoClose: 4000,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(`An error occurred: ${error.message}`, {
        position: 'top-right',
        autoClose: 4000,
      });
      setIsLoading(false);
    }
  };



  const filterDataByBreakTypeAndPayment = (data, breakTypes, paymentStatuses,breakStatus,selectedReasons,selectedViolationStatus,selectedRemedies) => {

    console.log('breakTypessss-->',breakTypes)
    // If no break types are selected, return an empty array
    if (!breakTypes.length) {
      return [];
    }
  
    // Apply break type filter
    let filteredData = data.filter((item) =>
      breakTypes.includes(item.rules_and_alerts_breaktype ? item.rules_and_alerts_breaktype.trim() : "")
    );
  
    // Apply payment status filter if applicable
    if (paymentStatuses.length > 0) {
      filteredData = filteredData.filter((item) =>
        paymentStatuses.includes(item.paid ? item.paid.trim() : "")
      );
    }

    if (breakStatus.length > 0) {
      filteredData = filteredData.filter((item) =>
        breakStatus.includes(item.event ? item.event.trim() : "")
      );
    }

    if (selectedReasons.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedReasons.includes(item.waivable_reasons)
      );
    }

    if (selectedViolationStatus.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedViolationStatus.includes(item.violation_status === 'yes' ? item.v_event === 'sent' ? 'open' : 'approved' : 'empty')
      );
    }

    if (selectedRemedies.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedRemedies.includes(item.violation_status === 'yes' ? item.v_event === 'approved' ? item.remedy : 'empty' : 'empty')
      );
    }

    setBreakCountData([]);
    setViolationCountData([]);

    const updatedBreakCounts = countBreakData(filteredData);
    const updatedViolationCounts = countViolationData(filteredData);

    console.log('updatedViolationCounts--->',updatedViolationCounts)

    setBreakCountData(updatedBreakCounts);
    setViolationCountData(updatedViolationCounts);
  
    return filteredData;
  };


  const fetchFilterSectionDetails = async () => {
    try {
      const authToken = localStorage.getItem('authToken');

      const headers = {
        "Authorization": `Bearer ${authToken}`,
      };

      const payload = {
        "start_date": encryptData(formatDateValue(dateRange[0].startDate)),
        "end_date": encryptData(formatDateValue(dateRange[0].endDate)),
        "staff_id": staffId,
        "premise_id": encryptData(selectedPremise.premise_id.toString()),
      };

      console.log('payload--->', payload);

      const response = await apiRequest(
        `api/user/getFilteredBreakRulesData`,
        'POST',
        payload,
        headers
      );

      console.log('response--->', response);

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const data = response.data?.data;
        console.log('FilterSectionAPICall')
        // Set all filter data
        setPaymentStatusData(data.filterdata.paymentstatus);
        setSelectedPaymentStatus(
          data.filterdata.paymentstatus.map((status) => status.paid)
        );
        setbreakTypeData(data.filterdata.breaktype);
        setSelectedBreakType(
          data.filterdata.breaktype.map(
            (breakType) => breakType.rules_and_alerts_breaktype.trim()
          )
        );
        // Violation Remedy 
        setViolationRemedyData(data.filterdata.violationremedy);
        setRemedyOption(data.filterdata.violationremedy);
        setSelectedRemedy(
          data.filterdata.violationremedy.map((remedy) => ({
            rules_and_alerts_violation_remedy_id: remedy.rules_and_alerts_violation_remedy_id,
            remedy: remedy.remedy,
          }))
        );
        setSelectedRemedies(
          data.filterdata.violationremedy.map((remedy) => remedy.remedy)
        )
        // Waivable Reasons 
        setWaivableReasonsData(data.filterdata.waivablereasons);
        setSelectedReasons(
          data.filterdata.waivablereasons.map((reasons) => ({
            rules_and_alerts_waivable_reasons_id: reasons.rules_and_alerts_waivable_reasons_id,
            rules_and_alerts_waivable_reasons: reasons.rules_and_alerts_waivable_reasons,
          }))
        );
        setSelectedWaivableReasons(
          data.filterdata.waivablereasons.map((reasons) => reasons.rules_and_alerts_waivable_reasons)
        )
        // Break Status 
        setBreakStatusData(data.filterdata.breakstatus);
        setSelectedStatusvalue(
          data.filterdata.breakstatus.map((breakStatus) => ({
            breakstatus: breakStatus.breakstatus,
          }))
        );
        setSelectedBreakStatus(          
          data.filterdata.breakstatus.map((breakStatus) => breakStatus.breakstatus)
        )
        // Violation Status 
        setViolationStatusData(data.filterdata.violationstatus);
        setSelectedViolationStatusvalue(
          data.filterdata.violationstatus.map((dataValue) => ({
            violationstatus: dataValue.violationstatus,
          }))
        );
        setSelectedViolationStatuses(
          data.filterdata.violationstatus.map((dataValue) => dataValue.violationstatus)
        )

        setUserData(data.filterdata.users);
        setSelectedUsers(
          data.filterdata.users.map((dataValue) => ({
            staff_id: dataValue.staff_id,
            user_name: dataValue.user_name,
          }))
        );
        setIsLoading(false);  
        console.log('FilterSideSectionAPI Call');
      } else {
        console.error('Error fetching Data:');
      }
    } catch (error) {
      console.error('Error fetching premises:', error);
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 4000,
      });
    }
  };
  

  useEffect(() => {
  fetchFilterSectionDetails();
  setIsLoading(true);
}, []);

// if(resetFilter === true){
//   fetchUserAndDateDetails();
// }
console.log('resetFilter-->',resetFilter)
console.log('resetFilterBtn-->',resetFilterBtn)

useEffect(() => {
    setBreakCountData([]);
    setViolationCountData([]);
    fetchUserAndDateDetails();
    setIsLoading(true);
}, [dateRange, staffId]);  // Added selectedBreakType and selectedUsers as dependencies

  
  console.log('breakCountData-->',breakCountData)
  const handleRowClick = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  
  
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filterTableData].sort((a, b) => {
      let aValue;
      let bValue;
      if(key === 'sent_date_time'){
        aValue = moment(a[key]).format(`${a['date_format'] === 'd-m-Y' ?
          'DD-MM-YYYY' : a['date_format'] === 'm-d-Y' ? 'MM-DD-YYYY' : null
          }`)
        bValue = moment(b[key]).format(`${b['date_format'] === 'd-m-Y' ?
        'DD-MM-YYYY' : b['date_format'] === 'm-d-Y' ? 'MM-DD-YYYY' : null
        }`)
      }else if(key === 'name'){
        aValue = a['first_name']+a['last_name'];
        bValue = b['first_name']+b['last_name'];
      }else if(key === 'break_start_date_time' || key === 'break_end_date_time'){
        aValue = moment(a[key]).format('HH:mm')
        bValue = moment(b[key]).format('HH:mm')
      }
      else if(key === 'paid'){
        aValue = a[key];
        bValue = b[key];
      }
      else if(key === 'waivable_reasons'){
        aValue = a[key] === null ? '-' : a[key];
        bValue = b[key] === null ? '-' : b[key];
      }
      else if(key === 'violationApprovedOrNot'){
      
        aValue = a['violation_status'] === 'yes' ?  a['v_event'] === 'sent' ? 'Open' : 'Approved' : '-';
        bValue = b['violation_status'] === 'yes' ?  b['v_event'] === 'sent' ? 'Open' : 'Approved' : '-';;
      }
      else if(key === 'violationApprovedBy'){
        aValue = a['violation_status'] === 'yes' ?  a['approver_first_name'] === null ? '-' : a['approver_first_name'] +
                (a['approver_last_name'] !== null ? ' ' + a['approver_last_name'] : '') : '-';

        bValue = b['violation_status'] === 'yes' ?  b['approver_first_name'] === null ? '-' : b['approver_first_name'] +
        (b['approver_last_name'] !== null ? ' ' + b['approver_last_name'] : '') : '-';
      }
      else if(key === 'violationRemedy'){
        aValue = a['violation_status']  === 'yes' ? a['v_event'] === 'approved' ? a['remedy']  : '-' : '-';

        bValue = b['violation_status']  === 'yes' ? b['v_event'] === 'approved' ? b['remedy']  : '-' : '-';
      }
      else{
        aValue = a[key];
        bValue = b[key];
      }

      console.log('aValue-->',aValue);
      console.log('bValue-->',bValue);
      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    // setTableData(sortedData);
      setFilterTableData(sortedData);
  };

  const handleTableSelectAll = () => {
    const newCheckedItems = {};
    if (!selectAll) {
      filterTableData.forEach((item, index) => {
        newCheckedItems[index] = true;
      });
    }
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const handleTableCheckboxChange = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };


  // Export selected data to CSV
  const exportToCSV = () => {
    const selectedData = filterTableData.filter((_, index) => checkedItems[index]);
    
    if (selectedData.length === 0) {
      toast.info('You need to select at-least one row before exporting', {
        position: 'top-right',
        autoClose: 4000,
      });
      return;
    }

    const headers = [
      "Date", "Name", "Break Start Time", "Break End Time", "Break Payment Type", "Break Type", 
      "Break Status", "Break Waivable Reason", "Violation Status", "Violation Approved By", 
      "Violation Remedy"
    ];

    const rows = selectedData.map(item => [
      moment(item.sent_date_time).format(`${item.date_format === 'd-m-Y' ?
        'DD-MM-YYYY' : item.date_format === 'm-d-Y' ? 'MM-DD-YYYY' : null
        }`)
      ,(item.first_name || '') + (item.last_name ? ' ' + item.last_name : ''), 
      item.break_start_date_time !== null ? moment(item.break_start_date_time).format('HH:mm') : '-',
      item.break_end_date_time !== null ? moment(item.break_end_date_time).format('HH:mm') : '-',
      capitalizeFirstLetter(item.paid),
      item.rules_and_alerts_breaktype.trim(),item.event[0].toUpperCase() + item.event.slice(1),
      item.waivable_reasons === null ? '-' : item.waivable_reasons !== 'empty' ? capitalizeFirstLetter(item.waivable_reasons) : '-',
      item.violation_status === 'yes' ? item.v_event === 'sent' ? 'Open' : 'Approved' : '-', 
      item.violation_status === 'yes' ? item.approver_first_name === null
      ? '-' : item.approver_first_name + (item.approver_last_name !== null ? ' ' + item.approver_last_name : '')
      : '-', item.violation_status === 'yes' ? item.v_event === 'approved' ? capitalizeFirstLetter(item.remedy) : '-' : '-'
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.join(","))
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const fileName = `${selectedPremise.name} ${formatDate(dateRange[0].startDate)} - ${formatDate(dateRange[0].endDate)}.csv`;
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.click();
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
    <div className="filterBackButton">
    <i
        className="fa fa-long-arrow-left backIcon"
        aria-hidden="true"
        onClick={() => premiseBackButton()}
        style={{ cursor: "pointer" }}
      ></i>
    </div>
        <div className="breakAndViolationMain d-flex">
              {/* Filter Sidebar */}
              <div className={`pt-3 filter-sidebar ${filterOpen ? "open" : "collapsed"} shadow`} >
              <div className="filterIconDiv">
            {filterOpen && (
              <>
                <h1 className="text-start filter-title display-4">Filters</h1>
                {/* <button
                  className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                  onClick={() => filterOptionReset(!resetFilter)}
                >
                  <i className="fa-solid fa-arrows-rotate resetIcon"></i>
                </button> */}
                <button
                className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                onClick={handleResetClick}
              >
<img
  src={filterResetIconOne}
  alt="Reset Filter"
  style={{
    width: 19,
    marginTop: '1px',
    marginBottom: '5px',
    marginLeft: '90px',
  }}
  className="filter-icon"
/>
              </button>
              </>
            )}
            <button
              className="toggle-button mt-2 btn btn-primary d-flex align-items-center justify-content-center"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {filterOpen ? (
                <i className="fa-solid fa-arrow-left filterIcon"></i>
              ) : (
                <i className="fa-solid fa-arrow-right filterIcon"></i>
              )}
            </button>
          </div>

            {filterOpen && (
              <div className="filter-content mt-1 mb-1 filter-custom-scrollbar" 
              style={{
                // gap: "20px",
                overflowY: "auto", // Enable vertical scroll
                maxHeight: "500px", // Adjust the height as needed
                paddingRight: "10px",
                paddingLeft: "10px",
              }}>       

                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowDateOptions(!showDateOptions)}
                      style={{ cursor: "pointer" }}
                    >
                      Date Range
                      <span>
                        <i
                          className={`fa-solid ${
                            showDateOptions ? "fa-minus" : "fa-plus"
                          }`}
                        />
                      </span>
                    </label>
                  </div>
                  {showDateOptions && (
                    <div className="mt-2 radioDiv">
                      {dateRange[0].startDate && dateRange[0].endDate && (
                        <div className="mt-1 text-muted">
                          <span
                            className="d-block mt-0 dateRangespan filterLabel"
                            onClick={() => setShowDatePicker(!showDatePicker)}
                            style={{ color: "#958c8c", cursor: "pointer" }}
                          >
                            <span>{formatDate(dateRange[0].startDate)}</span> -{" "}
                            <span>
                              {formatDate(dateRange[0].endDate)}
                              <span className="dateIcon">
                                <i className="fa-solid fa-calendar-days"></i>
                              </span>
                            </span>
                          </span>
                        </div>
                      )}
                      {showDatePicker && (
                        <div
                          className="modal fade show"
                          id="dateRangeModal"
                          tabIndex="-1"
                          aria-labelledby="dateRangeModalLabel"
                          aria-hidden="false"
                          role="dialog"
                          style={{
                            display: "block",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                          }}
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content dateRangeModal">
                              <div className="modal-header">
                                <h5 className="modal-title" id="dateRangeModalLabel">
                                  Choose Date Range
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCloseDatePicker}
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <DateRangePicker
                                  onChange={(item) =>
                                    setTempDateRange([item.selection]) // Store temporary selection
                                  }
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  ranges={tempDateRange} // Use temporary state for preview
                                  direction="horizontal"
                                  maxDate={new Date()}
                                />
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-success deleteYesBtn"
                                  onClick={handleApplyDatePicker}
                                >
                                  Apply
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger deleteNoBtn"
                                  onClick={handleCloseDatePicker}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowDropdown(!showDropdown)}
                    style={{ cursor: "pointer" }}
                  >
                    Users
                    <span>
                      <i
                        className={`fa-solid ${
                          showDropdown ? "fa-minus" : "fa-plus"
                        }`}
                      />
                    </span>
                  </label>

                  {/* Dropdown Section */}
                  {showDropdown && (
                    <>
                      {/* Search Input */}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="filterSearch"
                          placeholder="Search user..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>

                      {/* Select All */}
                      <div className="mt-1 align-items-center selectAlluserMainDiv">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          checked={selectedUsers.length === userList.length} // Select All if all users are checked
                          onChange={handleSelectAll}
                          style={{ width : "13px",height:"13px",marginTop: "6px" }}
                          id="select-all"
                        />
                        <label className="form-check-label userSelectAllLabel" htmlFor="select-all">
                          Select All
                        </label>
                      </div>

                      {/* Dropdown with checkboxes */}
                      <div className="mt-1">
                        <ul
                          className="list-group filter-custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredUserRoles.map((data) => (
                            <li
                              key={data.staff_id}
                              className="list-group-item d-flex align-items-center userListSearch"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedUsers.some(
                                  (selected) => selected.staff_id === data.staff_id
                                )}
                                onChange={() => handleCheckboxChange(data)}
                                id={`checkbox-user-${data.staff_id}`}
                              />
                              <label
                                className="form-check-label checkbox-value"
                                htmlFor={`checkbox-user-${data.staff_id}`}
                              >
                                {data.user_name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Display "No results found" if search is empty */}
                      {filteredUserRoles.length === 0 && searchTerm && (
                        <div className="mt-0 text-muted statusNotFound">No users found</div>
                      )}
                    </>
                  )}
                </div>

                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowBreakOptions(!showBreakOptions)} // Toggle break options visibility
                    style={{ cursor: "pointer" }}
                  >
                    Break Type
                    <span>
                      <i
                        className={`fa-solid ${showBreakOptions ? "fa-minus" : "fa-plus"}`}
                      />
                    </span>
                  </label>

                  {showBreakOptions && (
                    <div className="mt-2 payment_status_switch">
                      {/* Dynamically map through breakTypeData */}
                      {breakTypeData.map((breakType) => (
                        <div
                          className="form-check form-switch"
                          key={breakType.rules_and_alerts_breaktype_id}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`breakType_${breakType.rules_and_alerts_breaktype_id}`}
                            checked={selectedBreakType.includes(
                              breakType.rules_and_alerts_breaktype.trim()
                            )} 
                            onChange={() =>
                              handleBreakTypeChange(breakType.rules_and_alerts_breaktype.trim())
                            }
                          />
                          <label
                            className="form-check-label payment-label"
                            htmlFor={`breakType_${breakType.rules_and_alerts_breaktype_id}`}
                            style={{
                              color: "rgb(149, 140, 140)",
                              cursor: "pointer",
                              fontWeight: "600",
                              marginRight: "10px",
                              // fontSize:"11px"
                            }}
                          >
                            { breakType.rules_and_alerts_breaktype.replace("break", "").trim()}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowPaymentOptions(!showPaymentOptions)} // Toggle payment options visibility
                      style={{ cursor: "pointer" }}
                    >
                      Payment Type
                      <span>
                        <i
                          className={`fa-solid ${
                            showPaymentOptions ? "fa-minus" : "fa-plus"
                          }`}
                        />
                      </span>
                    </label>
                  </div>

                    {showPaymentOptions && (
                      <div className="mt-2 payment_status_switch">
                        {/* Dynamically map through paymentStatusData */}
                        {paymentStatusData.map((status) => (
                          <div
                            className="form-check form-switch paymentStatusInput"
                            key={status.rules_and_alerts_paid_id}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`paymentStatus_${status.rules_and_alerts_paid_id}`}
                              checked={selectedPaymentStatus.includes(status.paid)}
                              onChange={() =>
                                handleStatusChange(status.paid)
                              }
                            />
                            <label
                              className="form-check-label payment-label"
                              htmlFor={`paymentStatus_${status.rules_and_alerts_paid_id}`}
                              style={{
                                color: "rgb(149, 140, 140)",
                                cursor: "pointer",
                                fontWeight: "600",
                              }}
                            >
                              {capitalizeFirstLetter(status.paid)}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                </div>

                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowStatusOptions(!showStatusOptions)}
                    style={{ cursor: "pointer" }}
                  >
                    Break Status
                    <span>
                      <i
                        className={`fa-solid ${showStatusOptions ? 'fa-minus' : 'fa-plus'}`}
                      />
                    </span>
                  </label>

                  {showStatusOptions && (
                    <>
                      {/* Search Input */}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="filterSearch"
                          placeholder="Search status..."
                          value={searchStatusTerm}
                          onChange={(e) => setSearchStatusTerm(e.target.value)}
                        />
                      </div>

                      {/* Select All */}
                      <div className="mt-1 align-items-center selectAlluserMainDiv">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          checked={selectedStatusvalue.length === status.length} // Select All if all users are checked
                          onChange={handleStatusSelectAll}
                          style={{ width : "13px",height:"13px",marginTop: "6px" }}
                          id="select-all-break-value"
                        />
                        <label className="form-check-label userSelectAllLabel" htmlFor="select-all-break-value">
                          Select All
                        </label>
                      </div>

                      {/* Dropdown with checkboxes */}
                      <div className="mt-1">
                        <ul
                          className="list-group custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredStatus.map((user) => (
                            <li
                              key={user.breakstatus}
                              className="list-group-item d-flex align-items-center userListSearch"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedStatusvalue.some(
                                  (selected) => selected.breakstatus === user.breakstatus
                                )}
                                onChange={() => handleStatusCheckboxChange(user)}
                                id={`checkbox-status-${user.breakstatus}`}
                              />
                              <label
                                className="form-check-label checkbox-value"
                                htmlFor={`checkbox-status-${user.breakstatus}`}
                              >
                                {capitalizeFirstLetter(user.breakstatus)}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Display "No results found" if search is empty */}
                      {filteredStatus.length === 0 && searchStatusTerm && (
                        <div className="mt-0 text-muted statusNotFound">No status found</div>
                      )}
                    </>
                  )}
                </div>

                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowWaivableOptions(!showWaivableOptions)} // Toggle payment options visibility
                      style={{ cursor: 'pointer' }}
                    >
                      Waivable Reason
                      <span>
                        <i className={`fa-solid ${showWaivableOptions ? 'fa-minus' : 'fa-plus'}`} /> {/* Font Awesome icon */}
                      </span>
                    </label>
                  </div>

                  {showWaivableOptions && (
                    <>
                      {/* Search Input */}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="filterSearch"
                          placeholder="Search reasons..."
                          value={searchReasonTerm}
                          onChange={(e) => setSearchReasonTerm(e.target.value)}
                        />
                      </div>

                      {/* Select All */}
                      <div className="mt-1 align-items-center selectAlluserMainDiv">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          checked={selectedReasons.length === waivableReasons.length} // Select All if all users are checked
                          onChange={handleReasonSelectAll}
                          style={{ width : "13px",height:"13px",marginTop: "6px" }}
                          id="select-all-reason"
                        />
                        <label className="form-check-label userSelectAllLabel" htmlFor="select-all-reason">
                          Select All
                        </label>
                      </div>

                      {/* Dropdown with checkboxes */}
                      <div className="mt-1">
                        <ul
                          className="list-group custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredWaivableReasons.map((data) => (
                            <li
                              key={data.rules_and_alerts_waivable_reasons_id}
                              className="list-group-item d-flex align-items-center userListSearch"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedReasons.some(
                                  (selected) => selected.rules_and_alerts_waivable_reasons_id === data.rules_and_alerts_waivable_reasons_id
                                )}
                                onChange={() => handleReasonCheckboxChange(data)}
                                id={`checkbox-reason-${data.rules_and_alerts_waivable_reasons_id}`}
                              />
                              <label
                                className="form-check-label checkbox-value"
                                htmlFor={`checkbox-reason-${data.rules_and_alerts_waivable_reasons_id}`}
                              >
                                {capitalizeFirstLetter(data.rules_and_alerts_waivable_reasons)}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Display "No results found" if search is empty */}
                      {filteredWaivableReasons.length === 0 && searchReasonTerm && (
                        <div className="mt-0 text-muted statusNotFound">No waivable reasons found</div>
                      )}
                    </>
                  )}
                </div>

                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowViolationStatusOptions(!showViolationStatusOptions)}
                    style={{ cursor: "pointer" }}
                  >
                    Violation Status
                    <span>
                      <i
                        className={`fa-solid ${showViolationStatusOptions ? 'fa-minus' : 'fa-plus'}`}
                      />
                    </span>
                  </label>

                  {showViolationStatusOptions && (
                    <>
                      {/* Search Input */}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="filterSearch"
                          placeholder="Search status..."
                          value={searchViolationStatusTerm}
                          onChange={(e) => setSearchViolationStatusTerm(e.target.value)}
                        />
                      </div>

                      {/* Select All */}
                      <div className="mt-1 align-items-center selectAlluserMainDiv">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          checked={selectedViolationStatusvalue.length === violationStatus.length} // Select All if all users are checked
                          onChange={handleViolationStatusSelectAll}
                          style={{ width : "13px",height:"13px",marginTop: "6px" }}
                          id="select-all-violation-value"
                        />
                        <label className="form-check-label userSelectAllLabel" htmlFor="select-all-violation-value">
                          Select All
                        </label>
                      </div>

                      {/* Dropdown with checkboxes */}
                      <div className="mt-1">
                        <ul
                          className="list-group custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredViolationStatus.map((user) => (
                            <li
                              key={user.violationstatus}
                              className="list-group-item d-flex align-items-center userListSearch"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedViolationStatusvalue.some(
                                  (selected) => selected.violationstatus === user.violationstatus
                                )}
                                onChange={() => handleViolationStatusCheckboxChange(user)}
                                id={`checkbox-status-${user.violationstatus}`}
                              />
                              <label
                                className="form-check-label checkbox-value"
                                htmlFor={`checkbox-status-${user.violationstatus}`}
                              >
                                {capitalizeFirstLetter(user.violationstatus)}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Display "No results found" if search is empty */}
                      {filteredViolationStatus.length === 0 && searchViolationStatusTerm && (
                        <div className="mt-0 text-muted statusNotFound">No status found</div>
                      )}
                    </>
                  )}
                </div>

                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowRemedyOptions(!showRemedyOptions)} // Toggle payment options visibility
                      style={{ cursor: 'pointer' }}
                    >
                      Violation Remedy
                      <span>
                        <i className={`fa-solid ${showRemedyOptions ? 'fa-minus' : 'fa-plus'}`} /> {/* Font Awesome icon */}
                      </span>
                    </label>
                  </div>

                  {showRemedyOptions && (
                    <>
                    {/* Search Input */}
                    <div className="mt-2">
                      <input
                        type="text"
                        className="filterSearch"
                        placeholder="Search violation remedy..."
                        value={searchRemedyTerm}
                        onChange={(e) => setSearchRemedyTerm(e.target.value)}
                      />
                    </div>

                    {/* Select All */}
                    <div className="mt-1 align-items-center selectAlluserMainDiv">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={selectedRemedy.length === violationRemedy.length} // Select All if all users are checked
                        onChange={handleRemedySelectAll}
                        style={{ width : "12px",height:"12px",marginTop: "6px", cursor:"pointer" }}
                        id="select-all-remedy"
                      />
                      <label className="form-check-label userSelectAllLabel" htmlFor="select-all-remedy">
                        Select All
                      </label>
                    </div>

                    {/* Dropdown with checkboxes */}
                    <div className="mt-1">
                      <ul
                        className="list-group custom-scrollbar"
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        {filteredRemedy.map((data) => (
                          <li
                            key={data.value}
                            className="list-group-item d-flex align-items-center userListSearch"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={selectedRemedy.some(
                                (selected) => selected.rules_and_alerts_violation_remedy_id === data.rules_and_alerts_violation_remedy_id
                              )}
                              onChange={() => handleRemedyCheckboxChange(data)}
                              id={`checkbox-remedy-${data.rules_and_alerts_violation_remedy_id}`}
                            />
                            <label
                              className="form-check-label checkbox-value"
                              htmlFor={`checkbox-remedy-${data.rules_and_alerts_violation_remedy_id}`}
                            >
                              {capitalizeFirstLetter(data.remedy)}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Display "No results found" if search is empty */}
                    {filteredRemedy.length === 0 && searchRemedyTerm && (
                      <div className="mt-0 text-muted statusNotFound">No violation remedy found</div>
                    )}
                    </>
                  )}
                </div>
              

              </div>
            )}
          </div>

          {/* Main Content */}
          <div className="content-area flex-grow-1 pt-1">
            {/* Top Sections */}
            <div className="shadow-sm bg-light rounded topBarMain">
              <div className="card-body">
                <div className="row">
                    {/* Break Section */}
                    <div className="col-md-8 position-relative p-0 topBarDetailsMain">
                      <h5 className="text-center mb-3 breakMainTitle">Break ({totalBreakCount})</h5>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ position: "relative", paddingLeft: "25px", paddingRight: "60px" }}
                      >
                        {/* Left Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scroll("left")}
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-left" style={{ color : 'gray' }}></i>
                        </button>

                        {/* Scrollable Content */}
                        <div
                          className="custom-scrollbar d-flex"
                          ref={scrollRef}
                          style={{
                            display: "flex",
                            // gap: "20px",
                            overflowX: "auto",
                            paddingBottom: "10px",
                            scrollBehavior: "smooth",
                          }}
                        >
                          {breakCountIconDetails.map((item, index) => (
                            <div
                              key={index}
                              className="count-box text-center p-3 bg-white shadow-sm rounded topBarBox"
                              style={{
                                minWidth: "100px", // Fixed width for each box
                              }}
                            >
                              <div className="icon mt-1 iconHeader">
                                <img src={item.icon} alt={item.title} 
                                style={{
                                  width: item.title === "accepted" || item.title === "rejected"  ? 20 : 30, // 40px if Accepted, else 30px
                                  height: item.title === "accepted" || item.title === "rejected"  ? 20 : 30,
                                  marginTop: item.title === "accepted" || item.title === "rejected" ? '5px' : '',
                                  marginBottom: item.title === "accepted" || item.title === "rejected" ? '5px' : ''
                                }}
                                // style={{ width: 30, height: 30 }} 
                                  className={`${item.icon} fa-2x filter-topbar-icons`} />
                                {/* <i className={`${item.icon} fa-2x filter-topbar-icons`}></i> */}
                              </div>
                              <h6 className="countDetails mb-0">{item.count}</h6>
                              <p className="text-muted mb-1 breakTitle">{capitalizeFirstLetter(item.title.replace("break", "").trim())}</p>
                            </div>
                          ))}
                        </div>

                        {/* Right Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scroll("right")}
                          style={{
                            position: "absolute",
                            right: "35px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-right" style={{ color : 'gray' }}></i>
                        </button>
                      </div>
                    </div>
                    {/* Violation Section */}
                    <div className="col-md-4 col-lg-4 position-relative p-0">
                      <h5 className="text-center text-danger mb-3 breakMainTitle">Violation ({totalViolationCount})</h5>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ position: "relative", paddingLeft: "5px", paddingRight: "10px" }}
                      >
                        {/* Left Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scrollViolation("left")}
                          style={{
                            position: "absolute",
                            left: "-15px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-left" style={{ color : 'gray' }}></i>
                        </button>

                        {/* Scrollable Content */}
                        <div
                          className="custom-scrollbar"
                          ref={scrollViolationRef}
                          style={{
                            display: "flex",
                            // gap: "20px",
                            overflowX: "auto", // Horizontal scroll only for Violation boxes
                            paddingBottom: "10px",
                          }}
                        >
                          {violationIconDetails.map((item, index) => (
                            <div
                              key={index}
                              className={`count-box text-center p-3 bg-white shadow-sm rounded topBarBox ${
                                item.color || ""
                              }`}
                              style={{
                                minWidth: "100px", // Fixed width for each box
                              }}
                            >
                              <div className="icon mt-1 iconHeader">
                                <img src={item.icon} alt={item.title} style={{ width: 30, height: 30 }} 
                                  className={`${item.icon} fa-2x filter-topbar-icons`} />
                                {/* <i
                                  className={`${item.icon} fa-2x filter-topbar-icons`}
                                ></i> */}
                              </div>
                              <h6 className={`countDetails mb-0 ${item.title === 'open' ? 'text-danger' : '' }`}>{item.count}</h6>
                              <p className="text-muted mb-1 breakTitle">{capitalizeFirstLetter(item.title)}</p>
                            </div>
                          ))}
                        </div>

                        {/* Right Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scrollViolation("right")}
                          style={{
                            position: "absolute",
                            right: "16px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-right" style={{ color : 'gray' }}></i>
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>


            <div className="container mt-4 filterTableViewDiv">
              <div className="card shadow">
                <div className="card-header text-white d-flex justify-content-between align-items-center filterCardback">
                  <h5 className="mb-0 filter-table-title"></h5>
                  {/* <button className="btn btn-light exportData xlsxFormat" style={{marginLeft:"480px"}} onClick={exportToXLSX}>Export XLSX</button> */}
                  {/* <button className="btn btn-light exportData">
                  </button> */}
                  <img src={CSVExportImage} className="csvExportImage" onClick={exportToCSV} />
                  
                </div>
                <div className="card-body">
                  <div
                    className="table-responsive filter-table-custom-scrollbar"
                    style={{ maxHeight: "400px", overflow: "auto" }}
                  >
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th rowSpan="2" className="text-center align-middle">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleTableSelectAll}
                            />
                          </th>
                          <th rowSpan="2" style={{ minWidth: "90px" }} 
                              className="text-center align-middle"
                              onClick={() => handleSort("sent_date_time")}>
                            Date
                            {sortConfig.key === "sent_date_time" && (
                                <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                            )}
                          </th>
                          <th rowSpan="2" style={{ minWidth: "90px" }} 
                              className="text-center align-middle"
                              onClick={() => handleSort("name")}>
                            Name
                            {sortConfig.key === "name" && (
                                <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                            )}
                          </th>
                          <th colSpan="6" className="text-center break-column align-middle">
                            Break
                          </th>
                          <th colSpan="3" className="text-center violation-column align-middle">
                            Violation
                          </th>
                        </tr>
                        <tr>
                          <th className="break-column" onClick={() => handleSort("break_start_date_time")}>
                            <div className="thMainDiv">
                              <div className="thLabelDiv">
                                Start Time
                              </div>
                              <div className="thDirection">
                                {sortConfig.key === "break_start_date_time" && (
                                  <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                                )}
                              </div>
                            </div>
                          </th>
                          <th className="break-column" onClick={() => handleSort("break_end_date_time")}>
                            <div className="thMainDiv">
                              <div className="thLabelDiv">
                                End Time
                              </div>
                              <div className="thDirection">
                                {sortConfig.key === "break_end_date_time" && (
                                  <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                                )}
                              </div>
                            </div>
                          </th>
                          <th className="break-column" onClick={() => handleSort("paid")}>
                            <div className="thMainDiv">
                              <div className="thLabelDiv">
                                Payment Type
                              </div>
                              <div className="thDirection">
                                {sortConfig.key === "paid" && (
                                  <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                                )}
                              </div>
                            </div>
                          </th>
                          <th className="break-column" onClick={() => handleSort("rules_and_alerts_breaktype")}>
                            <div className="thMainDiv">
                              <div className="thLabelDiv">
                                Break Type
                              </div>
                              <div className="thDirection">
                                {sortConfig.key === "rules_and_alerts_breaktype" && (
                                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                                  )}
                              </div>
                            </div>
                          </th>
                          <th className="break-column" style={{ minWidth: "80px" }} 
                          onClick={() => handleSort("event")}>
                            Status
                          {sortConfig.key === "event" && (
                              <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort("waivable_reasons")}>
                            <div className="thMainDiv">
                              <div className="thLabelDiv">
                                Waivable Reason
                              </div>
                              <div className="thDirection">
                                {sortConfig.key === "waivable_reasons" && (
                                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                                  )}
                              </div>
                            </div>
                          </th>
                          <th  className="violation-column" onClick={() => handleSort("violationApprovedOrNot")}>
                          <div className="thMainDiv">
                              <div className="thLabelDiv">
                                Status
                              </div>
                              <div className="thDirection">
                                {sortConfig.key === "violationApprovedOrNot" && (
                                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                                  )}
                              </div>
                            </div>
                          </th>
                          <th  className="violation-column" onClick={() => handleSort("violationApprovedBy")}>
                            <div className="thMainDiv">
                              <div className="thLabelDiv">
                                Approved By
                              </div>
                              <div className="thDirection">
                                {sortConfig.key === "violationApprovedBy" && (
                                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                                  )}
                              </div>
                            </div>
                          </th>
                          <th  className="violation-column" onClick={() => handleSort("violationRemedy")}>
                            <div className="thMainDiv">
                              <div className="thLabelDiv">
                              Remedy
                              </div>
                              <div className="thDirection">
                              {sortConfig.key === "violationRemedy" && (
                                <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                              )}
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {isLoading ? (
                        <>
                        {Array.from({ length: 10 }).map((_, index) => (
                          <tr key={index}>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                            <td>
                              <Shimmer width="100%" height="20px" />
                            </td>
                          </tr>
                        ))}
                        </>
                      ) : (
                        <>  
                        {filterTableData.length === 0 ? (
                          <tr>
                            <td colSpan="12" className="text-center align-middle">
                              No Data Found
                            </td>
                          </tr>
                        ) : (

                          filterTableData.map((data, dataIndex) => (
                          <React.Fragment key={dataIndex}>
                              <tr key={dataIndex} 
                               onClick={() => handleRowClick(dataIndex)}>
                                    <td
                                      className="text-center align-middle"
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-checkbox"
                                        checked={!!checkedItems[dataIndex]}
                                        onChange={() => handleTableCheckboxChange(dataIndex)}
                                        
                                      />
                                    </td>
                                    <td
                                      className="text-center align-middle"
                                    >
                                      {/* {moment(data.sent_date_time).format('DD-MM-YYYY')} */}
                                      {data.sent_date_time && data.date_format ? moment(data.sent_date_time).format(`${data.date_format === 'd-m-Y' ?
                                        'DD-MM-YYYY' : data.date_format === 'm-d-Y' ? 'MM-DD-YYYY' : null
                                        }`) : "-"
                                      }
                                    </td>
                                  <td
                                    className="text-center align-middle"
                                  >
                                  <strong>
                                  {(data.first_name || '') + (data.last_name ? ' ' + data.last_name : '')}
                                  </strong>
                                  </td>
                                <td>{data.break_start_date_time ? moment(data.break_start_date_time).format('HH:mm') : '-'}</td>
                                <td>{data.break_end_date_time ? moment(data.break_end_date_time).format('HH:mm') : '-'}</td>
                                <td>{data.paid ? capitalizeFirstLetter(data.paid) : '-'}</td>
                                <td>{data.rules_and_alerts_breaktype ? data.rules_and_alerts_breaktype.trim() : ""}</td>
                                <td>{data.event ? capitalizeFirstLetter(data.event) : '-'}</td>
                                <td>{data.waivable_reasons === null ? '-' : data.waivable_reasons !== 'empty' ? capitalizeFirstLetter(data.waivable_reasons) : '-'}</td>
                                <td>{data.violation_status === 'yes' ? data.v_event === 'sent' ? 'Open' : 'Approved' : '-'}</td>
                                <td>
                                  {data.violation_status === 'yes'
                                    ? data.approver_first_name === null
                                      ? '-'
                                      : data.approver_first_name +
                                        (data.approver_last_name !== null ? ' ' + data.approver_last_name : '')
                                    : '-'}
                                </td>

                                <td>
                                  {data.violation_status === 'yes' ? (
                                    data.v_event === 'approved' ? (
                                      capitalizeFirstLetter(data.remedy)
                                    ) : data.v_event === 'sent' ? (
                                      <button className="btn btn-primary btn-sm approveBtn" onClick={() => handleButtonClick(data)}>
                                        Approve
                                      </button>
                                    ) : (
                                      '-'
                                    )
                                  ) : (
                                    '-'
                                  )}
                                </td>
                              </tr>
                          </React.Fragment>
                          ))
                        )}
                        </>
                        )}
                      </tbody>
                    </table>

                    {isModalOpen && (
                      <div
                        className="modal fade show d-block"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title violationHeader" id="exampleModalLabel">
                                Approve the violation
                              </h5>
                              <button
                                type="button"
                                className="btn-close approveClose"
                                onClick={handleCloseModal}
                                aria-label="Close"
                              ></button>
                            </div>
                            <Formik
                              initialValues={{ selectedOption: options[0] || null }}
                              validationSchema={validationSchema}
                              onSubmit={saveSelection}
                            >
                              {({ setFieldValue, errors, touched }) => (
                                <Form>
                                  <div className="modal-body">
                                    <Field name="selectedOption">
                                      {({ field }) => (
                                        <div>
                                          <label className="approveLabel">
                                          Please select the violation outcome before approving :
                                          </label>
                                          <Select
                                              id="dropdownSelect"
                                              options={options}
                                              value={field.value}
                                              onChange={(option) => setFieldValue('selectedOption', option)} // Set selected option value
                                              placeholder="Select an option"
                                              styles={{
                                                option: (provided, state) => ({
                                                  ...provided,
                                                  color: state.isSelected ? 'white' : '#9a9898', // Change color when selected and unselected
                                                  backgroundColor: state.isSelected 
                                                  ? '#6089db' 
                                                  : state.isFocused 
                                                  ? '#f1f1f1' // Hover background color
                                                  : 'white', // Default background color
                                                  fontSize: '13px', // Font size for options
                                                  padding: '10px', // Padding for options
                                                  cursor: 'pointer',
                                                  fontWeight : '600'
                                                }),
                                                control: (provided) => ({
                                                  ...provided,
                                                  fontSize: '12px', // Font size for the control (input box)
                                                  borderColor: '#4071d4d4', // Border color for the control
                                                  boxShadow: 'none', // Remove default focus shadow
                                                  '&:hover': {
                                                    borderColor: '#0056b3', // Border color on hover
                                                  },
                                                }),
                                                singleValue: (provided) => ({
                                                  ...provided,
                                                  color: '#989898', // Color for the selected value
                                                  fontSize: '15px', // Font size for the selected value
                                                  fontWeight : '600'
                                                }),
                                                placeholder: (provided) => ({
                                                  ...provided,
                                                  color: 'grey', // Placeholder text color
                                                  fontSize: '13px', // Placeholder font size
                                                }),
                                              }}
                                            />

                                          <ErrorMessage name="selectedOption" component="div" className="text-danger register-error form-error" />
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="submit"
                                      className="btn btn-primary deleteYesBtn"
                                    >
                                      Apply
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-secondary deleteNoBtn"
                                      onClick={handleCloseModal}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>


          </div>
    </div>
    </>


  );
};

export default BreakAndViolationReport;
