import React, { useState, useEffect } from "react";
import Sidebar from '../Layout/Sidebar';
import Topbar from '../Layout/Topbar';
import './Premises.css';
import { ToastContainer, toast } from 'react-toastify';
import { useToast } from "../../contexts/ToastContext";
import { apiRequest } from "../../services/api";
import Loader from "../Common/Loader";
import { Breadcrumb } from 'react-bootstrap';


const PremiseList = ({pageLoading,Title,SubTitle,premiseData }) => {
    const { toastMessage, clearToast } = useToast();
    const [premiseList, setPremiseList] = useState([]);
    const [selectedPremise, setSelectedPremise] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // New state for search term

    const handleCardClick = (premise) => {
        premiseData(premise); // Set the selected premise
    };

    const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term as user types
    };


    const filteredPremises = premiseList.filter((premise) => {
    return premise.name.toLowerCase().includes(searchTerm.toLowerCase()) || premise.city.toLowerCase().includes(searchTerm.toLowerCase());
    });

    useEffect(() => {
    if (toastMessage) {
        toast.success(toastMessage);
        clearToast(); // Clear the toast message after displaying it
    }
    }, [toastMessage, clearToast]);
    

    useEffect(() => {
    pageLoading(true);
    const customerDetails = JSON.parse(localStorage.getItem('customerDetails'));
    const authToken = localStorage.getItem('authToken');
    
    const fetchAllPremise = async () => {
        try {
        const headers = {
            "Authorization": `Bearer ${authToken}`,
        };
        const premiseResponse = await apiRequest(
            `web_api/admin/getPremisesByUserId?customer_account_id=${customerDetails.customerAccountId}`,
            'GET',
            null,
            headers
        );
        
        if (premiseResponse?.data?.status_code === 200 && premiseResponse?.data?.data?.premise_details) {
            const premise_details = premiseResponse.data?.data?.premise_details;
            setPremiseList(premise_details);
            setTimeout(() => {
            pageLoading(false);
            }, 200);
        } else {
            setTimeout(() => {
            pageLoading(false);
            }, 200);
            console.error('Error: Invalid status code', premiseResponse?.data?.status_code);
        }
        } catch (error) {
        console.error('Error fetching premises:', error);
        toast.error(`${error}`, {
            position: 'top-right',
            autoClose: 4000,
        });
        }
    };
    
    fetchAllPremise();
    }, []);

    return (
        <>
          <div className="contentHeader d-flex align-items-center justify-content-between pb-3">
            <div className='contentDiv'>
                {Title !== 'Premises' ? (
                    <Breadcrumb className='contentTitle'>
                        <Breadcrumb.Item active>{Title}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{SubTitle}</Breadcrumb.Item>
                    </Breadcrumb>
                ) :
                (
                    <Breadcrumb className='contentTitle'>
                    <Breadcrumb.Item active>{Title}</Breadcrumb.Item>
                    </Breadcrumb>
                )
                }

                {/* <span className="contentTitle">Report</span> */}
              {/* <span className='contentTitle'>{Title}</span> */}
            </div>
            {/* Search bar on the same row */}
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange} // Handle input change
              />
            </div>
          </div>
          <div className="premiseDetails">
            <div className="row">
              <div className="row mt-0 g-4">
                {filteredPremises.length > 0 ? (
                  filteredPremises.map((premiseData) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                      key={premiseData.premise_id}
                    >
                      <div
                        className="card setting-card"
                        onClick={() => handleCardClick(premiseData)} // Handle card click
                        style={{ cursor: "pointer" }}
                      >
                        <div className="card-image-div">
                          <img
                            src={premiseData.photo}
                            className="card-img-top"
                            alt={premiseData.title}
                          />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{premiseData.name}</h5>
                          <p className="card-text">
                            {premiseData.city} - {premiseData.state}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1 style={{ fontSize: "1.2rem", fontWeight: "bold", color: "#2d64bc" }}>
                      No Premises Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no premises available. Create a new premise to get started.
                    </p>
                    <button
                      className="btn policyAddBtn"
                      style={{ padding: "4px 10px", fontSize: "0.8rem" }}
                    >
                      <i className="fa fa-plus p-1" aria-hidden="true"></i>
                      Create Premises
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>       
        </>
    );
}

export default PremiseList;