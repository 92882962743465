// src/services/api.js
import axios from 'axios';
// Gamma :
export const API_BASE_URL = 'https://businesstime.app/business-i_backend_uat/public/';
// export const API_BASE_URL = 'https://businesstime.us/business-i_backend_uat/public/';

// Live :
// export const API_BASE_URL = 'https://businesstime.app/businesstime_api/public/';
// export const API_BASE_URL = 'https://businesstime.us/businesstime_api/public/';




export const apiRequest = async (endpoint, method = 'GET', data = null, headers = {}, params = {}) => {
  try {
    const response = await axios({
      method,
      url: `${API_BASE_URL}${endpoint}`,
      data,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      params, // Add query parameters here
      timeout: 10000, // Set a timeout for the request (in milliseconds)
    });
    
    // Optional: Log the response
    // console.log('API Response:', response.data);

    return { success: true, data: response.data };
  } catch (error) {
    // console.error('API Error:', error);
    return { 
      success: false, 
      error: error.response?.data?.message || 'API Error' 
    };
  }
};
