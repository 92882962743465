import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastProvider } from './contexts/ToastContext';
import 'react-toastify/dist/ReactToastify.css';
import Registration from './components/Auth/Registration';
import './App.css';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Settings from './components/Setting/Settings';
import PrivateRoute from './components/PrivateRoute';
import Users from './components/User/Users';
import Premises from './components/Premise/Premises';
import VerifyEmail from './components/Auth/VerifyEmail';
import Report from './components/Report/Report';
import Timing from './components/Report/Timing/Timing';
import BreakAndViolation from './components/Report/BreakAndViolation/BreakAndViolation';
import PremiseList from './components/Premise/PremiseList';
import BreakAndViolationReport from './components/Report/BreakAndViolation/BreakAndViolationReport';
import ForgotPassword from './components/Auth/ForgotPassword';
import OtpVerification from './components/Auth/OtpVerification';
import ChangePassword from './components/Auth/ChangePassword';
import PremiseAction from './components/Premise/PremiseAction';


const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // const token = sessionStorage.getItem('authToken');
    const token = localStorage.getItem('authToken');
    // console.log("Token found:", token);
    setIsAuthenticated(!!token);
  }, []);

  return(
    <ToastProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/email/verify/:id/:hash" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp-verification" element={<OtpVerification />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/signin" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/premises"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Premises />
                </PrivateRoute>
              }
            />
            <Route
              path="/premise/create"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <PremiseAction />
                </PrivateRoute>
              }
            />
            <Route
              path="/premise/update"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <PremiseAction />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/report"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Report />
                </PrivateRoute>
              }
            />
            <Route
              path="/report/timing"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Timing />
                </PrivateRoute>
              }
            />
            <Route
              path="/report/break-violation"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <BreakAndViolation />
                </PrivateRoute>
              }
            />
            <Route
              path="/break-and-violation-report"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <BreakAndViolationReport />
                </PrivateRoute>
              }
            />
                {/* <Route path="/premise-list" element={<PremiseList />} />
                <Route path="/break-and-violation-report" element={<BreakAndViolationReport />} /> */}
            <Route
              path="/settings"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Settings />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </ToastProvider>
  )
}

export default App;
