import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Loader from '../Common/Loader';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import './ForgotPassword.css';
import Logo from '../../../src/assets/logo.png';
import Close from '../../../src/assets/close.png';
import { apiRequest } from '../../services/api'; 
import { useToast } from "../../contexts/ToastContext";


const ForgotPassword = () => {

  const navigate = useNavigate();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false); 
  // State to hold initial email value
  const [initialEmail, setInitialEmail] = useState("");

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email required"),
  });

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };


  const handleSubmit = async (values) => {
    setLoading(true); 
    console.log("Form data:", values);
    
    // Save email to localStorage
    localStorage.setItem("forgotPasswordEmail", values.email);

    const email = values.email;
    const payload = {
      email 
    };    
    console.log('payload-->',payload)
    try {
      const response = await apiRequest('web_api/admin/forgot_password', 'POST', payload); 
      console.log('responseAPIs--->',response)
      if (response?.data?.status === 200) {
        showToast(response.data?.message);
        navigate('/otp-verification',{state : {forgot_password_email : email}});
        // Handle successful login (e.g., redirect, update state)
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      toast.error('Login failed. Please try again.'); 
    } finally {
      setLoading(false); 
    }

  };

  useEffect(() => {
    // Pre-populate the email field from localStorage if available
    const savedEmail = localStorage.getItem("forgotPasswordEmail");
    if (savedEmail) {
      setInitialEmail(savedEmail); // Set the initial state to the saved email
    }
  }, []);

  return (
    <div className="container-fluid p-0">
    {/* Header */}
    {loading && <Loader />} 
    <ToastContainer />
    <header className="header-section">
      <div className="container row">
        <div className="LogoDiv col-md-8">
        <img src={Logo} className="logoImage" alt="Logo" />
        </div>
        <div className="headerDesc col-md-4">
        <span className="contactUsDesc">Talk with our team at <span className="text-primary contactNo">+1.800.803.1349</span></span>
        </div>
        {/* <h1 className="text-center text-white">Welcome to Business Time!</h1> */}
      </div>
    </header>

    <div className="main-content d-flex vh-100 justify-content-center align-items-center bg-light">
        {/* Stylish Card Container */}
        <div className="col-md-12 col-lg-4 forgot-card position-relative">
        <div className="position-absolute top-0 end-0 forgot-close">
          <button className="btn closeBtn" onClick={handleBackClick}>
            <img className="closeImage" src={Close} alt="close"/>
          </button>
        </div>
        <div className="p-4 forgot-main-div">
          <h2 className="text-center mb-4 forgot-password-title">Forgot Password</h2>
          <p className="text-center mb-4 forgot-password-desc">Enter your registered email Id</p>
          <Formik
            initialValues={{ email: initialEmail || "" }} // Set initialValues from state
            validationSchema={validationSchema}
            enableReinitialize // Allow reinitialization when initialEmail changes
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
            <div className="forgot-password-form">
              <Form autoComplete="off">
                <div className="row justify-content-center">
                    <div className="row mb-0 loginInputDiv">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Field
                                type="email"
                                name="email"
                                id="email"
                                className="login-form-input forgot-form-input"
                                placeholder=" "
                                autoFocus
                                />
                                <label htmlFor="email" className="form-label">
                                Email <span className="text-danger">*</span>
                                </label>
                            </div>
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger forgot-password-error"
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <button type="submit" className="btn btn-primary forgot-button" disabled={isSubmitting}>
                            Continue
                            </button>
                        </div>
                    </div>
                </div>
              </Form>
            </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </div>
  );
}

export default ForgotPassword;
