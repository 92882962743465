import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumb } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./PremiseAction.css";
import Switch from "react-switch";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import FileUploadImage from "../../assets/upload.png";
import { Country, State, City } from "country-state-city";
import { encryptData } from "../Common/encryptionUtils";
import { apiRequest } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
// import 'react-clock/dist/Clock.css';

const PremiseAction = () => {
  const location = useLocation();
  const premiseData = location.state?.premise;

  const [openTime, setOpenTime] = useState(
    premiseData?.open_time?.slice(0, -3) || "09:30"
  );
  const [closeTime, setCloseTime] = useState(
    premiseData?.close_time?.slice(0, -3) || "19:00"
  );

  const initialValues = {
    title: premiseData?.title || "",
    name: premiseData?.name || "",
    subName: premiseData?.sub_name || "",
    address: premiseData?.address || "",
    dateFormat: premiseData?.date_format || "",
    country: premiseData?.country || "",
    state: premiseData?.state || "",
    city: premiseData?.city || "",
    premise_bt_enabled:
      premiseData?.premise_bt_enabled === "true" ? true : false,
    openTime: openTime,
    closeTime: closeTime,
    photo: "",
    timezone: premiseData?.time_zone || "",
    isEdit: !!premiseData,
  };
  console.log("premiseData--->", premiseData);

  console.log("Country--->", Country.getAllCountries());
  // Validation schema using Yup
  const updateValidationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    name: Yup.string()
        .trim()
        .required("Name is required")
        .test(
          "unique-premises-name",
          "This premises name is already taken. Please try a different one.",
          function (value) {
            return !isDuplicateUpdatePremisesName(value);
          }
        ),
    address: Yup.string().required("Address is required"),
    dateFormat: Yup.string().required("Date Format is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    timezone: Yup.string().required("Timezone is required"),
    premise_bt_enabled: Yup.boolean().required("Bluetooth is required"),
  });

  const createValidationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    name: Yup.string()
      .trim()
      .required("Name is required")
      .test(
        "unique-premises-name",
        "This premises name is already taken. Please try a different one.",
        function (value) {
          return !isDuplicatePremisesName(value);
        }
      ),
    address: Yup.string().required("Address is required"),
    dateFormat: Yup.string().required("Date Format is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    timezone: Yup.string().required("Timezone is required"),
    photo: Yup.string().required("Premises Image is required"),
    premise_bt_enabled: Yup.boolean().required("Bluetooth is required"),
  });

  const premisesList = JSON.parse(localStorage.getItem("PremisesList"));
  const isDuplicatePremisesName = (name) => {
    return premisesList.some(
      (premises) => premises.name.toLowerCase() === name.toLowerCase()
    );
  };
  const premisesListDetails = premisesList.filter((premises) => premises.name !== initialValues.name);
  console.log('premisesListDetails-->',premisesListDetails)
  const isDuplicateUpdatePremisesName = (name) => {
    return premisesListDetails.some(
      (premises) => premises.name.toLowerCase() === name.toLowerCase()
    );
  };

  console.log("openTime-->", openTime);
  console.log("openTime-->", closeTime);

  const [imagePreview, setImagePreview] = useState(premiseData?.photo || null);

  // const handleFileChange = (event) => {

  // };

  console.log("imagePreview--->", imagePreview);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selecetedStates, setSelecetedStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [timezones, setTimezones] = useState([]);

  console.log("states--->", states);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    // Load all countries
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  const handleCancelButton = () => {
    navigate("/premises");
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    console.log("Form Data:", values);
    // const countryData = countries.find((s) => s.isoCode === values.country);
    // const stateData = states.find((s) => s.isoCode === values.state);
    // console.log("countries--->", countryData);
    // values.country = countryData.name;
    // values.state = stateData.name;

    console.log("Form Data:11", values);
    const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
    const authToken = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    console.log("payloadvalues-->", values);
    const payload = {
      title: encryptData(values.title),
      name: encryptData(values.name),
      address: encryptData(values.address),
      date_format: encryptData(values.dateFormat),
      country: encryptData(values.country),
      state: encryptData(values.state),
      city: values.city ? encryptData(values.city) : encryptData(values.state),
      photo: values.photo,
      premise_bt_enabled: encryptData(values.premise_bt_enabled.toString()),
      open_time: encryptData(values.openTime),
      close_time: encryptData(values.closeTime),
      customer_account_id: encryptData(
        customerDetails.customerAccountId.toString()
      ),
      time_zone: encryptData(values.timezone),
      sub_name: encryptData(values.subName),
    };

    if (premiseData?.name) {
      try {
        console.log("Updatepayload-->", payload);
        const response = await apiRequest(
          `web_api/admin/updatePremise/${premiseData?.premise_id}`,
          "POST",
          payload,
          headers
        );
        // console.log('response--->',response)
        if (response.data?.status == 200) {
          // showToast(response.data?.message);
          navigate("/premises"); // Navigate to SignIn
          setLoading(false);
          setTimeout(() => {
            toast.success("Premises updated successfully");
            setSubmitting(false);
          }, 100);
        } else {
          setLoading(false);
          console.log("sdsdsds", response.data);
          toast.error(
            response.data?.message || "An unexpected error occurred."
          );
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          error.response?.data?.message || "Login failed. Please try again."
        ); // Handle specific error messages from API
      } finally {
        setLoading(false);
      }
    } else {
      try {
        console.log("payload-->", payload);
        const response = await apiRequest(
          "web_api/admin/createPremise",
          "POST",
          payload,
          headers
        );
        // console.log('response--->',response)
        if (response.data?.status == 200) {
          // showToast(response.data?.message);
          navigate("/premises"); // Navigate to SignIn
          setLoading(false);
          setTimeout(() => {
            toast.success("Premises created successfully");
            setSubmitting(false);
          }, 100);
        } else {
          setLoading(false);
          console.log("sdsdsds", response.data);
          toast.error(
            response.data?.message || "An unexpected error occurred."
          );
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          error.response?.data?.message || "Login failed. Please try again."
        ); // Handle specific error messages from API
      } finally {
        setLoading(false);
      }
    }
  };

  // if(){

  // }
  useEffect(() => {
    if (premiseData?.country) {
      const country = Country.getAllCountries().find(
        (c) => c.name === premiseData.country
      );
      if (country) {
        const fetchedStates = State.getStatesOfCountry(country.isoCode);
        setStates(fetchedStates);
        setSelectedCountry(country.isoCode);

        setTimezones(country ? country.timezones : "");

        if (premiseData.state) {
          const state = fetchedStates.find((s) => s.name === premiseData.state);
          if (state) {
            const fetchedCities = City.getCitiesOfState(
              country.isoCode,
              state.isoCode
            );
            setCities(fetchedCities);
            setSelectedState(state.isoCode);
          }
        }
      }
    }
  }, [premiseData?.country, premiseData?.state]);

  console.log("initialValues--->", initialValues);

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar />
        <ToastContainer />
        {loading && <Loader />}
        <div className="content">
          <div className="contentHeader d-flex align-items-center gap-3 pb-3">
            <div className="contentDiv">
              <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>Premises</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {premiseData?.title ? "Update" : "Add"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card shadow premiseAddMainDiv">
                <Formik
                  initialValues={initialValues}
                  validationSchema={
                    premiseData?.name
                      ? updateValidationSchema
                      : createValidationSchema
                  }
                  onSubmit={handleFormSubmit}
                  // onSubmit={(values, { setSubmitting }) => {
                  //   console.log("Form Data:", values);
                  //   console.log('countries--->',countries);
                  //   console.log('countries--->',selectedCountry);
                  //   const countryData = countries.find((s) => s.isoCode === values.country);
                  //   const stateData = states.find((s) => s.isoCode === values.state);
                  //   console.log('countries--->',countryData);
                  //   values.country = countryData.name;
                  //   values.state = stateData.name;

                  //   console.log("countries", values);
                  //   // You can replace this with an actual API call
                  //   setTimeout(() => {
                  //     toast.success("Form Submitted Successfully!");
                  //     setSubmitting(false);
                  //   }, 1000);
                  // }}
                >
                  {({
                    setFieldValue,
                    errors,
                    touched,
                    values,
                    isSubmitting,
                  }) => (
                    <Form className="premiseForm">
                      {/* <h1 className="text-center font-weight-bold mb-4">
                        <span className="text-primary header-title">Add Premise</span>
                      </h1> */}
                      <div className="row formDiv">
                        {/* Left Column */}
                        <div className="col-md-6 col-12 premiseDetailsDiv">
                          {/* Title */}
                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="title"
                                id="title"
                                className="form-input"
                                autoComplete="off"
                              >
                                <option value="">Select Title</option>
                                <option value="showroom">Showroom</option>
                                <option value="office">Office</option>
                                <option value="shop">Shop</option>
                              </Field>
                              <label htmlFor="title" className="form-label">
                                Title <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          {/* Name */}
                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-input"
                                placeholder=" "
                                autoComplete="off"
                              />
                              <label htmlFor="name" className="form-label">
                                Premises Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          {/* Sub Name */}

                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="subName"
                                id="subName"
                                className="form-input"
                                autoComplete="off"
                              >
                                <option value="">Select sub name</option>
                                <option value="office">Office</option>
                                <option value="retailstore">
                                  Retail store
                                </option>
                              </Field>
                              <label htmlFor="subName" className="form-label">
                                Sub Name
                              </label>
                            </div>
                            <ErrorMessage
                              name="subName"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          {/* Address */}
                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="textarea"
                                name="address"
                                id="address"
                                className="form-input"
                                placeholder=" "
                                autoComplete="off"
                                rows="3" // Adjust the number of rows for height
                              />
                              <label htmlFor="address" className="form-label">
                                Address <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="country"
                                id="country"
                                className="form-input"
                                autoComplete="off"
                                onChange={(e) => {
                                  const countryName = e.target.value;

                                  const country = countries.find(
                                    (c) => c.name === countryName
                                  );
                                  setSelectedCountry(country.isoCode);
                                  console.log("countryObject--->", country);
                                  // Set the value in Formik
                                  setFieldValue("country", countryName);
                                  setTimezones(
                                    country ? country.timezones : ""
                                  );
                                  // Fetch states for selected country
                                  const statesByCountry =
                                    State.getStatesOfCountry(country.isoCode);

                                  console.log(
                                    "statesByCountry-->",
                                    statesByCountry
                                  );
                                  setStates(statesByCountry);
                                  setSelectedState(""); // Reset state
                                  setCities([]); // Reset cities
                                }}
                              >
                                <option value="" selected disabled>
                                  Select country
                                </option>
                                {countries.map((country) => (
                                  <option
                                    key={country.name}
                                    value={country.name}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </Field>
                              <label htmlFor="country" className="form-label">
                                Country <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          {/* <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="country"
                                id="country"
                                className="form-input"
                                autoComplete="off"
                              >
                                <option value="" selected disabled>
                                  Select country
                                </option>
                                <option value="India">India</option>
                                <option value="USA">US</option>
                              </Field>
                              <label htmlFor="country" className="form-label">
                                Country <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div> */}
                          {/* 
                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="state"
                                id="state"
                                className="form-input"
                                autoComplete="off"
                              >
                                <option value="" selected disabled>
                                  Select state
                                </option>
                                <option value="Karnataka">Tamilnadu</option>
                                <option value="California">California</option>
                              </Field>
                              <label htmlFor="state" className="form-label">
                                State <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div> */}

                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="timezone"
                                id="timezone"
                                className="form-input"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue("timezone", e.target.value);
                                }}
                              >
                                <option value="" selected disabled>
                                  Select time zone
                                </option>
                                {timezones.map((timezone) => (
                                  <option
                                    key={timezone.zoneName}
                                    value={timezone.zoneName}
                                  >
                                    {timezone.zoneName}
                                  </option>
                                ))}
                              </Field>
                              <label htmlFor="timezone" className="form-label">
                                Time Zone <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="timezone"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>
                        </div>

                        {/* Right Column */}
                        <div className="col-md-6 col-12 premiseDetailsDiv1">
                          {/* Bluetooth */}

                          {/* <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="city"
                                id="city"
                                className="form-input"
                                autoComplete="off"
                              >
                                <option value="" selected disabled>
                                  Select city
                                </option>
                                <option value="Bangalore">Bangalore</option>
                                <option value="New York">New York</option>
                              </Field>
                              <label htmlFor="city" className="form-label">
                                City <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div> */}

                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="state"
                                id="state"
                                className="form-input"
                                autoComplete="off"
                                onChange={(e) => {
                                  const stateName = e.target.value;
                                  setSelectedState(stateName);

                                  // Set the value in Formik
                                  setFieldValue("state", stateName);
                                  console.log("stateName-->", stateName);
                                  const state = states.find(
                                    (s) => s.name === stateName
                                  );

                                  console.log("stateData--->", state.isoCode);
                                  console.log(
                                    "selectedCountry--->",
                                    selectedCountry
                                  );
                                  // Fetch cities for selected state
                                  const citiesByState = City.getCitiesOfState(
                                    selectedCountry,
                                    state.isoCode
                                  );
                                  setCities(citiesByState);
                                }}
                              >
                                <option value="" selected disabled>
                                  Select state
                                </option>
                                {states.map((state) => (
                                  <option key={state.name} value={state.name}>
                                    {state.name}
                                  </option>
                                ))}
                              </Field>
                              <label htmlFor="state" className="form-label">
                                State <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="city"
                                id="city"
                                className="form-input"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue("city", e.target.value);
                                }}
                              >
                                <option value="" selected disabled>
                                  Select city
                                </option>
                                {cities.map((city) => (
                                  <option key={city.name} value={city.name}>
                                    {city.name}
                                  </option>
                                ))}
                              </Field>
                              <label htmlFor="city" className="form-label">
                                City
                              </label>
                            </div>
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          <div className="mb-3">
                            <div className="form-group">
                              <Field
                                as="select"
                                name="dateFormat"
                                id="dateFormat"
                                className="form-input"
                                autoComplete="off"
                              >
                                <option value="">Select date format</option>
                                <option value="d-m-Y">India (d-m-Y)</option>
                                <option value="m-d-Y">Us (m-d-Y)</option>
                              </Field>
                              <label
                                htmlFor="dateFormat"
                                className="form-label"
                              >
                                Date Format{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="dateFormat"
                              component="div"
                              className="text-danger register-error"
                            />
                          </div>

                          <div className="overall-time-div">
                            <div className="timeMainDiv">
                              {/* <div className="mb-3"> */}
                              <div className="form-group timeOpenDiv">
                                <label
                                  htmlFor="timeField"
                                  className="time-label"
                                >
                                  Open Time{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="openTimeMainDiv">
                                  {/* Hours Select */}
                                  <input
                                    type="time"
                                    id="openTime"
                                    value={openTime}
                                    onClick={(e) => e.target.showPicker()} // Show picker on click
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={(e) => {
                                      setFieldValue("openTime", e.target.value);
                                      setOpenTime(e.target.value);
                                    }}
                                    // onChange={handleOpenTimeChange}
                                    className="styled-time-input"
                                  />
                                </div>
                              </div>
                              {/* </div> */}
                              {/* <div className="mb-3 mr-5"> */}
                              <div className="form-group timeOpenDiv">
                                <label
                                  htmlFor="timeField"
                                  className="mr-0 time-label"
                                >
                                  Close Time{" "}
                                  <span className="text-danger mr-5">*</span>
                                </label>
                                <div className="openTimeMainDiv">
                                  {/* Hours Select */}
                                  <input
                                    type="time"
                                    id="closeTime"
                                    value={closeTime}
                                    onClick={(e) => e.target.showPicker()} // Show picker on click
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "closeTime",
                                        e.target.value
                                      );
                                      setCloseTime(e.target.value);
                                    }}
                                    // onChange={handleCloseTimeChange}
                                    className="styled-time-input"
                                  />
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>

                          <div className="container mt-1">
                            <div className="row align-items-center">
                              {/* Image Upload Section */}
                              <div className="col-md-6">
                                <label
                                  htmlFor="timeField"
                                  className="mr-5 time-label"
                                >
                                  Premises Image{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <label
                                  htmlFor="fileInput"
                                  className="file-label"
                                >
                                  <input
                                    type="file"
                                    id="fileInput"
                                    className="file-input"
                                    accept="image/*"
                                    onChange={(e) => {
                                      const file = e.target.files[0];
                                      if (file) {
                                        if (file.size > 2 * 1024 * 1024) {
                                          // Ensure size is less than 2MB
                                          toast.info(
                                            "File size exceeds 2 MB. Please upload a smaller file."
                                          );
                                          // alert("File size exceeds 2 MB. Please upload a smaller file.");
                                          return;
                                        }

                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                          setImagePreview(reader.result); // Base64 data
                                          setFieldValue("photo", reader.result);
                                        };
                                        reader.readAsDataURL(file);
                                      }
                                    }}
                                  />
                                  <div className="file-box">
                                    {imagePreview ? (
                                      <img
                                        src={imagePreview}
                                        alt="Preview"
                                        className="image-preview"
                                      />
                                    ) : (
                                      <>
                                        <span className="text-image">
                                          <img
                                            src={FileUploadImage}
                                            className="ImageUpload"
                                            alt="Upload Icon"
                                          />
                                        </span>
                                        <h1 className="upload-heading">
                                          Upload Image
                                        </h1>
                                        <p className="image-instruction">
                                          Image size must be less than 2 MB
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  <div className="chooseFileDiv">
                                    <span className="btn btn-primary mt-2 chooseFileBtn">Choose File</span>
                                  </div>
                                </label>
                                <br />
                                <ErrorMessage
                                  name="photo"
                                  component="div"
                                  className="text-danger register-error premiseImageError"
                                />
                              </div>
                              <div className="col-md-6 bluetoothSwitchMainDiv">
                                <div className="form-group">
                                  <div>
                                    <label
                                      htmlFor="premise_bt_enabled"
                                      className="form-label mb-2 bluetooth-label"
                                    >
                                      Bluetooth{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="bluetoothSwitchDiv">
                                  <div>
                                    <Switch
                                      onColor="#4caf50" // Green when enabled
                                      offColor="#f44336" // Red when disabled
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={21}
                                      width={40}
                                      className="bluetoothSwitch"
                                      handleDiameter={20}
                                      id="premise_bt_enabled"
                                      checked={values.premise_bt_enabled} // Bind to Formik state
                                      onChange={(checked) =>
                                        setFieldValue(
                                          "premise_bt_enabled",
                                          checked
                                        )
                                      } // Update Formik state
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt-4 PremiseSubmitDiv">
                        <button
                          type="submit"
                          className="btn btn-success deleteYesBtn"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger cancelBtn deleteNoBtn"
                          onClick={() => handleCancelButton()}
                        >
                          Cancel
                        </button>
                        {/* <button
                          type="submit"
                          className="btn btn-primary px-4"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button> */}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiseAction;
