import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import logo from '../../assets/logo.svg';

const menuItems = [
  { iconClass: "fas fa-home", label: "Dashboard", path: "/dashboard" },
  { iconClass: "fas fa-city", label: "Premises", path: "/premises" },
  { iconClass: "fas fa-users", label: "Users", path: "/users" },
  {
    iconClass: "fas fa-chart-bar",
    label: "Reports",
    path: "/report",
    subMenu: [
      { iconClass: "fa-regular fa-clock", label: "Timing", path: "/report/timing" },
      { iconClass: "fa-solid fa-mug-saucer", label: "Breaks and Violations", path: "/report/break-violation" },
    ],
  },
  { iconClass: "fas fa-gear", label: "Settings", path: "/settings" },
];

const Sidebar = () => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [expandedMenu, setExpandedMenu] = useState('Reports');
  const navigate = useNavigate();
  console.log('expandedMenu-->',expandedMenu)
  useEffect(() => {
    setActivePath(location.pathname);

    localStorage.removeItem('startWorkHours');
    localStorage.removeItem('startWorkMins');
    localStorage.removeItem('startWorkMins1');

    localStorage.removeItem('violationHours');
    localStorage.removeItem('violationMins');
    localStorage.removeItem('breakMinTime');
    console.log('activePath-->',activePath)
    if(activePath !== "/settings"){
      localStorage.removeItem('currentStep');
      localStorage.removeItem('selectedPolicyPremise');
      localStorage.removeItem('selectedPolicy');
    }
    if(activePath !== "/report/break-violation"){
      localStorage.removeItem('currentBreakStep');
      localStorage.removeItem('selectedBreakPremise');
      localStorage.removeItem('breakPremise');
    }
  }, [location]);

  useEffect(() => {
    // Check if the active path is a report submenu path to expand the Reports menu
    const isReportPath = activePath.includes('/report');
    if (isReportPath) {
      setExpandedMenu('Reports');
    }
  }, [activePath]);

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  const toggleSubMenu = (label) => {
    setExpandedMenu((prev) => (prev === label ? null : label));
  };

  return (
    <div className="sidebar bg-gradient">
      <div className="sidebarDiv">
        <div className="sidebarLogoDiv text-center">
          <img
            src={logo}
            alt="Logo"
            className="sideBarLogoImage"
            style={{ cursor: "pointer" }}
            onClick={handleLogoClick}
          />
        </div>
        <div className="sidebarListDiv">
          <ul className="list-unstyled">
            {menuItems.map((item, index) => {
              const isActive = activePath === item.path || 
                (item.path === '/premises' && activePath.includes('/premises')) ||
                (item.path === '/report' && activePath.includes('/report'));
              const hasSubMenu = !!item.subMenu;

              return (
                <React.Fragment key={index}>
                  <li
                    className={`menu-item ${isActive ? 'active' : ''}`}
                    onClick={() => hasSubMenu && toggleSubMenu(item.label)}
                  >
                    <Link to={hasSubMenu ? '#' : item.path} className="d-flex align-items-center">
                      <i className={`${item.iconClass} me-2`}></i>
                      <span>{item.label}</span>
                      {/* {hasSubMenu && (
                        <i
                          className={`ms-auto expandReport fas ${expandedMenu === item.label ? 'fa-minus' : 'fa-plus'}`}
                        ></i>
                      )} */}
                    </Link>
                  </li>
                  {hasSubMenu && expandedMenu === item.label && (
                    <ul className="submenu ps-3">
                      {item.subMenu.map((subItem, subIndex) => {
                        const isSubActive = activePath === subItem.path;
                        return (
                          <li
                            key={subIndex}
                            className={`submenu-item submenutag d-flex align-items-center ${isSubActive ? 'active' : ''}`}
                          >
                            <i className={`${subItem.iconClass} me-2`} style={{ marginLeft: subItem.iconClass === 'fa-regular fa-clock' ? '3px' : '0' }}></i>
                            <Link className='submenutag' to={subItem.path}>{subItem.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};


export default Sidebar;
