import React, { useState,useRef } from "react";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import "./Report.css";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS
import Select from 'react-select';
import PaidIcon from '../../assets/filter_icons/paid.png';
import UnpaidIcon from '../../assets/filter_icons/unpaid.png';
import RestIcon from '../../assets/filter_icons/rest_break.png';
import MealIcon from '../../assets/filter_icons/meal_break.png';
import AcceptedIcon from '../../assets/filter_icons/break_accepted.png';
import RejectedIcon from '../../assets/filter_icons/break_reject.png';
import ExpiredIcon from '../../assets/filter_icons/break_expired.png';
import ActiveIcon from '../../assets/filter_icons/break_send.png';
import OpenViolation from '../../assets/filter_icons/open_violation.png';
import ApprovedViolation from '../../assets/filter_icons/violation_approved.png';


const Report = () => {
  
  const [filterOpen, setFilterOpen] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(["Paid","Unpaid"]); // Keep track of the selected status
  const [showDateOptions, setShowDateOptions] = useState(false); // For Payment Status toggle
  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // For Payment Status toggle
  const [showBreakOptions, setShowBreakOptions] = useState(false); // For Break Type toggle
  const [showStatusOptions, setShowStatusOptions] = useState(false); 
  const [showRemedyOptions, setShowRemedyOptions] = useState(false); // For Remedy toggle
  const [showWaivableOptions, setShowWaivableOptions] = useState(false); // For Remedy toggle
  const [selectedBreakType, setSelectedBreakType] = useState(["Rest","Meal"]); // Break type state

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const scrollRef = useRef(null);
  const scrollViolationRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollViolation = (direction) => {
    if (scrollViolationRef.current) {
      const scrollAmountValue = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollViolationRef.current.scrollBy({ left: scrollAmountValue, behavior: "smooth" });
      
      // Debugging scroll position
      console.log(scrollViolationRef.current.scrollLeft);
    }
  };
  
  const handleStatusChange = (status) => {
    setSelectedPaymentStatus(prevStatus => {
      if (prevStatus.includes(status)) {
        // If the status is already selected, remove it (uncheck)
        return prevStatus.filter(item => item !== status);
      } else {
        // If the status is not selected, add it (check)
        return [...prevStatus, status];
      }
    });
  };
console.log('selectedPaymentStatus-->',selectedPaymentStatus)
  const handleBreakTypeChange = (status) => {
    setSelectedBreakType(prevStatus => {
      if (prevStatus.includes(status)) {
        // If the status is already selected, remove it (uncheck)
        return prevStatus.filter(item => item !== status);
      } else {
        // If the status is not selected, add it (check)
        return [...prevStatus, status];
      }
    });
  };

  console.log('selectedBreakType-->',selectedBreakType)

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchReasonTerm, setSearchReasonTerm] = useState("");
  const [searchRemedyTerm, setSearchRemedyTerm] = useState("");
  const [searchStatusTerm, setSearchStatusTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]); 
  const [selectedReasons, setSelectedReasons] = useState([]); 
  const [selectedRemedy, setSelectedRemedy] = useState([]); 

  const [selectedStatusvalue, setSelectedStatusvalue] = useState([]); 


  // Mock user roles data
  const userRoles = [
    { value: 1, label: "User 1" },
    { value: 2, label: "User 2" },
    { value: 3, label: "User 3" },
    { value: 4, label: "User 4" },
    { value: 5, label: "User 5" },
    { value: 6, label: "User 6" },
    { value: 7, label: "User 7" },
    { value: 8, label: "User 8" },
    { value: 9, label: "User 9" },
    { value: 10, label: "User 10" },
    { value: 11, label: "User 11" },
    { value: 12, label: "User 12" },
    { value: 13, label: "User 13" },
    { value: 14, label: "User 14" },
    { value: 15, label: "User 15" },
    { value: 16, label: "User 16" },
  ];

  const status = [
    { value: 1, label: "Accepted" },
    { value: 2, label: "Rejected" },
    { value: 3, label: "Expired" },
  ];

  const waivableReasons = [
    { value: 1, label: "6 hour shift" },
    { value: 2, label: "compensate" },
    { value: 3, label: "other" },
  ];

  const violationRemedy = [
    { value: 1, label: "pay_penalty" },
    { value: 2, label: "compensate with 1 hour of pay" },
    { value: 3, label: "Already informed & approved" },
  ];

  // Filter user list based on search input
  const filteredUserRoles = userRoles.filter((user) =>
    user.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredStatus = status.filter((value) =>
    value.label.toLowerCase().includes(searchStatusTerm.toLowerCase())
  );

  const filteredWaivableReasons = waivableReasons.filter((data) =>
    data.label.toLowerCase().includes(searchReasonTerm.toLowerCase())
  );

  const filteredRemedy = violationRemedy.filter((data) =>
    data.label.toLowerCase().includes(searchRemedyTerm.toLowerCase())
  );

  // Checkbox handler
  const handleCheckboxChange = (user) => {
    if (selectedUsers.some((selected) => selected.value === user.value)) {
      setSelectedUsers(selectedUsers.filter((u) => u.value !== user.value)); // Remove user
    } else {
      setSelectedUsers([...selectedUsers, user]); // Add user
    }
  };

  const handleReasonCheckboxChange = (user) => {
    if (selectedReasons.some((selected) => selected.value === user.value)) {
      setSelectedReasons(selectedReasons.filter((u) => u.value !== user.value)); // Remove user
    } else {
      setSelectedReasons([...selectedReasons, user]); // Add user
    }
  };

  const handleRemedyCheckboxChange = (user) => {
    if (selectedRemedy.some((selected) => selected.value === user.value)) {
      setSelectedRemedy(selectedRemedy.filter((u) => u.value !== user.value)); // Remove user
    } else {
      setSelectedRemedy([...selectedRemedy, user]); // Add user
    }
  };

  const handleStatusCheckboxChange = (data) => {
    if (selectedStatusvalue.some((selected) => selected.value === data.value)) {
      setSelectedStatusvalue(selectedStatusvalue.filter((u) => u.value !== data.value)); // Remove user
    } else {
      setSelectedStatusvalue([...selectedStatusvalue, data]); // Add user
    }
  };

  // Select All handler
  const handleSelectAll = () => {
    if (selectedUsers.length === userRoles.length) {
      setSelectedUsers([]); // Deselect all
    } else {
      setSelectedUsers([...userRoles]); // Select all users
    }
  };

  const handleReasonSelectAll = () => {
    if (selectedReasons.length === waivableReasons.length) {
      setSelectedReasons([]); // Deselect all
    } else {
      setSelectedReasons([...waivableReasons]); // Select all users
    }
  };

  const handleRemedySelectAll = () => {
    if (selectedRemedy.length === violationRemedy.length) {
      setSelectedRemedy([]); // Deselect all
    } else {
      setSelectedRemedy([...waivableReasons]); // Select all users
    }
  };
  
  const handleStatusSelectAll = () => {
    if (selectedStatusvalue.length === status.length) {
      setSelectedStatusvalue([]); // Deselect all
    } else {
      setSelectedStatusvalue([...status]); // Select all users
    }
  };
  


  
  // Mock API Data for Testing
  const mockData = [
    { id: 1, name: "John Doe", date: "2024-12-10", type: "Paid Break", location: "New York", status: "Active", violation: "None", department: "HR", manager: "Alice" },
    { id: 2, name: "Jane Smith", date: "2024-12-11", type: "Unpaid Break", location: "Los Angeles", status: "Inactive", violation: "Minor", department: "Finance", manager: "Bob" },
    { id: 3, name: "Alice Brown", date: "2024-12-12", type: "Paid Break", location: "Chicago", status: "Active", violation: "Major", department: "IT", manager: "Charlie" },
    { id: 4, name: "Mark Wilson", date: "2024-12-12", type: "Resolved Violation", location: "San Francisco", status: "Resolved", violation: "None", department: "Sales", manager: "David" },
    { id: 5, name: "Chris Adams", date: "2024-12-13", type: "Pending Violation", location: "Seattle", status: "Pending", violation: "Severe", department: "Marketing", manager: "Eve" },
    { id: 6, name: "Eva Thomas", date: "2024-12-14", type: "Paid Break", location: "Dallas", status: "Active", violation: "None", department: "HR", manager: "Alice" },
    { id: 7, name: "Michael Lee", date: "2024-12-15", type: "Unpaid Break", location: "Austin", status: "Inactive", violation: "Minor", department: "Finance", manager: "Bob" },
    { id: 8, name: "Sophia Green", date: "2024-12-16", type: "Paid Break", location: "Miami", status: "Active", violation: "Major", department: "IT", manager: "Charlie" },
    { id: 9, name: "James White", date: "2024-12-17", type: "Resolved Violation", location: "San Diego", status: "Resolved", violation: "None", department: "Sales", manager: "David" },
    { id: 10, name: "Lucas Black", date: "2024-12-18", type: "Pending Violation", location: "Portland", status: "Pending", violation: "Severe", department: "Marketing", manager: "Eve" },
  ];



  // Select/Deselect a Single Row
  const handleRowSelect = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };
  

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0'); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  const breakCounts = [
    { icon: PaidIcon, count: 15, title: 'Paid' },
    { icon: UnpaidIcon, count: 14, title: 'Unpaid' },
    { icon: RestIcon, count: 15, title: 'Rest' },
    { icon: MealIcon, count: 12, title: 'Meal' },
    { icon: AcceptedIcon, count: 10, title: 'Accepted' },
    { icon: RejectedIcon, count: 12, title: 'Rejected' },
    { icon: ExpiredIcon, count: 15, title: 'Expired' },
  ];

  const violationCounts = [
    { icon: OpenViolation, count: 10, title: 'Open', color: 'text-danger' },
    { icon: ApprovedViolation, count: 15, title: 'Approved' , color: 'text-danger' },
  ];

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar />
        <ToastContainer />
        <div className="content d-flex">
          {/* Filter Sidebar */}
          <div className={`pt-3 filter-sidebar ${filterOpen ? "open" : "collapsed"} shadow`} >
            <div className="filterIconDiv ">
              {filterOpen && (
                <h1 className="text-start filter-title display-4">Filters</h1>
              )}
              <button
                className="toggle-button mt-2 btn btn-primary d-flex align-items-center justify-content-center"
                onClick={() => setFilterOpen(!filterOpen)}
              >
                {filterOpen ? (
                  <i className="fa-solid fa-arrow-left filterIcon"></i>
                ) : (
                  <i className="fa-solid fa-arrow-right filterIcon"></i>
                )}
              </button>
            </div>
            {filterOpen && (
              <div className="filter-content mt-1 mb-1 filter-custom-scrollbar" 
              style={{
                // gap: "20px",
                overflowY: "auto", // Enable vertical scroll
                maxHeight: "500px", // Adjust the height as needed
                paddingRight: "10px",
                paddingLeft: "10px",
              }}>       
                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowDateOptions(!showDateOptions)} // Toggle payment options visibility
                      style={{ cursor: "pointer" }}
                    >
                      Date Range
                      <span>
                      <i className={`fa-solid ${showDateOptions ? 'fa-minus' : 'fa-plus'}`} /> {/* Font Awesome icon */}
                      </span>
                    </label>
                  </div>
                  {showDateOptions && (
                    <div className="mt-2 radioDiv">
                      {/* Date Range Applied Text */}
                      {dateRange[0].startDate && dateRange[0].endDate && (
                        <div className="mt-1 text-muted">
                          {/* <strong className="filterLabel">Date Range Applied:</strong> */}
                          <span className="d-block mt-0 dateRangespan filterLabel" onClick={() => setShowDatePicker(!showDatePicker)} style={{ color: '#958c8c', cursor: 'pointer'  }}>
                            <span>{formatDate(dateRange[0].startDate)}</span> - <span>{formatDate(dateRange[0].endDate)}
                            <span className="dateIcon"><i class="fa-solid fa-calendar-days"></i></span></span>
                          </span>
                        </div>
                      )}
                      {showDatePicker && (
                        <div
                          className="modal fade show"
                          id="dateRangeModal"
                          tabIndex="-1"
                          aria-labelledby="dateRangeModalLabel"
                          aria-hidden="false"
                          role="dialog"

                          style={{ display: 'block',backgroundColor: 'rgba(0, 0, 0, 0.5)', }}  // Ensures the modal is visible when showDatePicker is true
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content dateRangeModal">
                              {/* Modal Header */}
                              <div className="modal-header">
                                <h5 className="modal-title" id="dateRangeModalLabel">Choose Date Range</h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCloseDatePicker}
                                  aria-label="Close"
                                ></button>
                              </div>

                              {/* Modal Body */}
                              <div className="modal-body">
                                <DateRangePicker
                                  onChange={(item) => setDateRange([item.selection])}
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  ranges={dateRange}
                                  direction="horizontal"
                                  maxDate={new Date()}
                                />
                              </div>

                              {/* Modal Footer */}
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-success deleteYesBtn"
                                  onClick={() => {
                                    // toast.success(
                                    //   `Date Range Applied: ${dateRange[0].startDate.toLocaleDateString()} - ${dateRange[0].endDate.toLocaleDateString()}`
                                    // );
                                    setShowDatePicker(false);
                                  }}
                                >
                                  Apply
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger deleteNoBtn"
                                  onClick={handleCloseDatePicker}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowPaymentOptions(!showPaymentOptions)} // Toggle payment options visibility
                      style={{ cursor: 'pointer' }}
                    >
                      Payment Status
                      <span>
                        <i className={`fa-solid ${showPaymentOptions ? 'fa-minus' : 'fa-plus'}`} /> {/* Font Awesome icon */}
                      </span>
                    </label>
                  </div>

                  {showPaymentOptions && (
                    <div className="mt-2 payment_status_switch">
                      {/* Paid Toggle Switch */}
                      <div className="form-check form-switch form-switch-payment">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="paymentStatusPaid"
                          checked={selectedPaymentStatus.includes('Paid')} // 'Paid' is part of the selectedPaymentStatus array
                          onChange={() => handleStatusChange('Paid')}
                        />
                        <label className="form-check-label payment-label" htmlFor="paymentStatusPaid" style={{ color: "rgb(149, 140, 140)" ,cursor: 'pointer',fontWeight : '600' }}>
                          Paid
                        </label>
                      </div>

                      {/* Unpaid Toggle Switch */}
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="paymentStatusUnpaid"
                          checked={selectedPaymentStatus.includes('Unpaid')} // 'Unpaid' is part of the selectedPaymentStatus array
                          onChange={() => handleStatusChange('Unpaid')}
                        />
                        <label className="form-check-label payment-label" htmlFor="paymentStatusUnpaid" style={{ color: "rgb(149, 140, 140)" ,cursor: 'pointer',fontWeight : '600' }}>
                          Unpaid
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowBreakOptions(!showBreakOptions)} // Toggle break options visibility
                    style={{ cursor: "pointer" }}
                  >
                    Break Type
                    <span>
                      <i className={`fa-solid ${showBreakOptions ? 'fa-minus' : 'fa-plus'}`} /> {/* Font Awesome icon */}
                    </span>
                  </label>

                  {showBreakOptions && (
                    <div className="mt-2 payment_status_switch">
                    {/* Paid Toggle Switch */}
                    <div className="form-check form-switch form-switch-payment">
                      <input
                        style={{ color: '#007bff', cursor: 'pointer'  }}
                        className="form-check-input"
                        type="checkbox"
                        id="breakStatusMeal"
                        checked={selectedBreakType.includes('Meal')} // 'Paid' is part of the selectedPaymentStatus array
                        onChange={() => handleBreakTypeChange('Meal')}
                      />
                      <label className="form-check-label payment-label" htmlFor="breakStatusMeal" style={{color: "rgb(149, 140, 140)" , cursor: 'pointer',fontWeight : '600'  }}>
                        Meal
                      </label>
                    </div>

                    {/* Unpaid Toggle Switch */}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="breakStatusRest"
                        checked={selectedBreakType.includes('Rest')} // 'Unpaid' is part of the selectedPaymentStatus array
                        onChange={() => handleBreakTypeChange('Rest')}
                      />
                      <label className="form-check-label payment-label" htmlFor="breakStatusRest" style={{color: "rgb(149, 140, 140)" , cursor: 'pointer',fontWeight : '600' }}>
                        Rest
                      </label>
                    </div>
                  </div>
                  )}
                </div>

                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowRemedyOptions(!showRemedyOptions)} // Toggle payment options visibility
                      style={{ cursor: 'pointer' }}
                    >
                      Violation Remedy
                      <span>
                        <i className={`fa-solid ${showRemedyOptions ? 'fa-minus' : 'fa-plus'}`} /> {/* Font Awesome icon */}
                      </span>
                    </label>
                  </div>

                  {showRemedyOptions && (
                    <>
                    {/* Search Input */}
                    <div className="mt-2">
                      <input
                        type="text"
                        className="filterSearch"
                        placeholder="Search violation remedy..."
                        value={searchRemedyTerm}
                        onChange={(e) => setSearchRemedyTerm(e.target.value)}
                      />
                    </div>

                    {/* Select All */}
                    <div className="mt-1 align-items-center selectAlluserMainDiv">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={selectedRemedy.length === violationRemedy.length} // Select All if all users are checked
                        onChange={handleRemedySelectAll}
                        style={{ width : "13px",height:"13px",marginTop: "6px", cursor:"pointer" }}
                        id="select-all-remedy"
                      />
                      <label className="form-check-label userSelectAllLabel" htmlFor="select-all-remedy">
                        Select All
                      </label>
                    </div>

                    {/* Dropdown with checkboxes */}
                    <div className="mt-1">
                      <ul
                        className="list-group custom-scrollbar"
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        {filteredRemedy.map((data) => (
                          <li
                            key={data.value}
                            className="list-group-item d-flex align-items-center userListSearch"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={selectedRemedy.some(
                                (selected) => selected.value === data.value
                              )}
                              onChange={() => handleRemedyCheckboxChange(data)}
                              id={`checkbox-remedy-${data.value}`}
                            />
                            <label
                              className="form-check-label checkbox-value"
                              htmlFor={`checkbox-remedy-${data.value}`}
                            >
                              {data.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Display "No results found" if search is empty */}
                    {filteredRemedy.length === 0 && searchRemedyTerm && (
                      <div className="mt-0 text-muted statusNotFound">No violation remedy found</div>
                    )}
                    </>
                  )}
                </div>

                <div className="mb-0 border-top border-bottom py-2">
                  <div>
                    <label
                      className="d-flex justify-content-between filterLabel"
                      onClick={() => setShowWaivableOptions(!showWaivableOptions)} // Toggle payment options visibility
                      style={{ cursor: 'pointer' }}
                    >
                      Waivable Reasons
                      <span>
                        <i className={`fa-solid ${showWaivableOptions ? 'fa-minus' : 'fa-plus'}`} /> {/* Font Awesome icon */}
                      </span>
                    </label>
                  </div>

                  {showWaivableOptions && (
                    <>
                      {/* Search Input */}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="filterSearch"
                          placeholder="Search reasons..."
                          value={searchReasonTerm}
                          onChange={(e) => setSearchReasonTerm(e.target.value)}
                        />
                      </div>

                      {/* Select All */}
                      <div className="mt-1 align-items-center selectAlluserMainDiv">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          checked={selectedReasons.length === waivableReasons.length} // Select All if all users are checked
                          onChange={handleReasonSelectAll}
                          style={{ width : "13px",height:"13px",marginTop: "6px" }}
                          id="select-all-reason"
                        />
                        <label className="form-check-label userSelectAllLabel" htmlFor="select-all-reason">
                          Select All
                        </label>
                      </div>

                      {/* Dropdown with checkboxes */}
                      <div className="mt-1">
                        <ul
                          className="list-group custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredWaivableReasons.map((data) => (
                            <li
                              key={data.value}
                              className="list-group-item d-flex align-items-center userListSearch"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedReasons.some(
                                  (selected) => selected.value === data.value
                                )}
                                onChange={() => handleReasonCheckboxChange(data)}
                                id={`checkbox-reason-${data.value}`}
                              />
                              <label
                                className="form-check-label checkbox-value"
                                htmlFor={`checkbox-reason-${data.value}`}
                              >
                                {data.label}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Display "No results found" if search is empty */}
                      {filteredWaivableReasons.length === 0 && searchReasonTerm && (
                        <div className="mt-0 text-muted statusNotFound">No waivable reasons found</div>
                      )}
                    </>
                  )}
                </div>

                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowStatusOptions(!showStatusOptions)}
                    style={{ cursor: "pointer" }}
                  >
                    Status
                    <span>
                      <i
                        className={`fa-solid ${showStatusOptions ? 'fa-minus' : 'fa-plus'}`}
                      />
                    </span>
                  </label>

                  {showStatusOptions && (
                    <>
                      {/* Search Input */}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="filterSearch"
                          placeholder="Search status..."
                          value={searchStatusTerm}
                          onChange={(e) => setSearchStatusTerm(e.target.value)}
                        />
                      </div>

                      {/* Select All */}
                      <div className="mt-1 align-items-center selectAlluserMainDiv">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          checked={selectedStatusvalue.length === status.length} // Select All if all users are checked
                          onChange={handleStatusSelectAll}
                          style={{ width : "13px",height:"13px",marginTop: "6px" }}
                          id="select-all-value"
                        />
                        <label className="form-check-label userSelectAllLabel" htmlFor="select-all-value">
                          Select All
                        </label>
                      </div>

                      {/* Dropdown with checkboxes */}
                      <div className="mt-1">
                        <ul
                          className="list-group custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredStatus.map((user) => (
                            <li
                              key={user.value}
                              className="list-group-item d-flex align-items-center userListSearch"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedStatusvalue.some(
                                  (selected) => selected.value === user.value
                                )}
                                onChange={() => handleStatusCheckboxChange(user)}
                                id={`checkbox-status-${user.value}`}
                              />
                              <label
                                className="form-check-label checkbox-value"
                                htmlFor={`checkbox-status-${user.value}`}
                              >
                                {user.label}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Display "No results found" if search is empty */}
                      {filteredStatus.length === 0 && searchStatusTerm && (
                        <div className="mt-0 text-muted statusNotFound">No status found</div>
                      )}
                    </>
                  )}
                </div>
                

                <div className="border-top border-bottom py-2">
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowDropdown(!showDropdown)}
                    style={{ cursor: "pointer" }}
                  >
                    Users
                    <span>
                      <i
                        className={`fa-solid ${
                          showDropdown ? "fa-minus" : "fa-plus"
                        }`}
                      />
                    </span>
                  </label>

                  {/* Dropdown Section */}
                  {showDropdown && (
                    <>
                      {/* Search Input */}
                      <div className="mt-2">
                        <input
                          type="text"
                          className="filterSearch"
                          placeholder="Search user..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>

                      {/* Select All */}
                      <div className="mt-1 align-items-center selectAlluserMainDiv">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          checked={selectedUsers.length === userRoles.length} // Select All if all users are checked
                          onChange={handleSelectAll}
                          style={{ width : "13px",height:"13px",marginTop: "6px" }}
                          id="select-all"
                        />
                        <label className="form-check-label userSelectAllLabel" htmlFor="select-all">
                          Select All
                        </label>
                      </div>

                      {/* Dropdown with checkboxes */}
                      <div className="mt-1">
                        <ul
                          className="list-group filter-custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {filteredUserRoles.map((data) => (
                            <li
                              key={data.value}
                              className="list-group-item d-flex align-items-center userListSearch"
                              style={{ cursor: "pointer" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={selectedUsers.some(
                                  (selected) => selected.value === data.value
                                )}
                                onChange={() => handleCheckboxChange(data)}
                                id={`checkbox-user-${data.value}`}
                              />
                              <label
                                className="form-check-label checkbox-value"
                                htmlFor={`checkbox-user-${data.value}`}
                              >
                                {data.label}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Display "No results found" if search is empty */}
                      {filteredUserRoles.length === 0 && searchTerm && (
                        <div className="mt-0 text-muted statusNotFound">No users found</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Main Content */}
          <div className="content-area flex-grow-1 pt-1">
            {/* Top Sections */}
            <div className="shadow-sm bg-light rounded topBarMain">
              <div className="card-body">
                <div className="row">
                    {/* Break Section */}
                    <div className="col-md-8 position-relative p-0 topBarDetailsMain">
                      <h5 className="text-center mb-3 breakMainTitle">Break (30)</h5>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ position: "relative", paddingLeft: "25px", paddingRight: "60px" }}
                      >
                        {/* Left Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scroll("left")}
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-left" style={{ color : 'gray' }}></i>
                        </button>

                        {/* Scrollable Content */}
                        <div
                          className="custom-scrollbar d-flex"
                          ref={scrollRef}
                          style={{
                            display: "flex",
                            // gap: "20px",
                            overflowX: "auto",
                            paddingBottom: "10px",
                            scrollBehavior: "smooth",
                          }}
                        >
                          {breakCounts.map((item, index) => (
                            <div
                              key={index}
                              className="count-box text-center p-3 bg-white shadow-sm rounded topBarBox"
                              style={{
                                minWidth: "100px", // Fixed width for each box
                              }}
                            >
                              <div className="icon mt-1 iconHeader">
                                <img src={item.icon} alt={item.title} 
                                style={{
                                  width: item.title === "Accepted" || item.title === "Rejected"  ? 20 : 30, // 40px if Accepted, else 30px
                                  height: item.title === "Accepted" || item.title === "Rejected"  ? 20 : 30,
                                  marginTop: item.title === "Accepted" || item.title === "Rejected" ? '5px' : '',
                                  marginBottom: item.title === "Accepted" || item.title === "Rejected" ? '5px' : ''
                                }}
                                // style={{ width: 30, height: 30 }} 
                                  className={`${item.icon} fa-2x filter-topbar-icons`} />
                                {/* <i className={`${item.icon} fa-2x filter-topbar-icons`}></i> */}
                              </div>
                              <h6 className="countDetails mb-0">{item.count}</h6>
                              <p className="text-muted mb-1 breakTitle">{item.title}</p>
                            </div>
                          ))}
                        </div>

                        {/* Right Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scroll("right")}
                          style={{
                            position: "absolute",
                            right: "35px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-right" style={{ color : 'gray' }}></i>
                        </button>
                      </div>
                    </div>
                    {/* Violation Section */}
                    <div className="col-md-4 col-lg-4 position-relative p-0">
                      <h5 className="text-center text-danger mb-3 breakMainTitle">Violation (25)</h5>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ position: "relative", paddingLeft: "5px", paddingRight: "10px" }}
                      >
                        {/* Left Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scrollViolation("left")}
                          style={{
                            position: "absolute",
                            left: "-15px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-left" style={{ color : 'gray' }}></i>
                        </button>

                        {/* Scrollable Content */}
                        <div
                          className="custom-scrollbar"
                          ref={scrollViolationRef}
                          style={{
                            display: "flex",
                            // gap: "20px",
                            overflowX: "auto", // Horizontal scroll only for Violation boxes
                            paddingBottom: "10px",
                          }}
                        >
                          {violationCounts.map((item, index) => (
                            <div
                              key={index}
                              className={`count-box text-center p-3 bg-white shadow-sm rounded topBarBox ${
                                item.color || ""
                              }`}
                              style={{
                                minWidth: "100px", // Fixed width for each box
                              }}
                            >
                              <div className="icon mt-1 iconHeader">
                                <img src={item.icon} alt={item.title} style={{ width: 30, height: 30 }} 
                                  className={`${item.icon} fa-2x filter-topbar-icons`} />
                                {/* <i
                                  className={`${item.icon} fa-2x filter-topbar-icons`}
                                ></i> */}
                              </div>
                              <h6 className={`countDetails mb-0 ${item.title === 'Open' ? 'text-danger' : '' }`}>{item.count}</h6>
                              <p className="text-muted mb-1 breakTitle">{item.title}</p>
                            </div>
                          ))}
                        </div>

                        {/* Right Arrow */}
                        <button
                          className="btn btn-light shadow-sm rounded-circle"
                          onClick={() => scrollViolation("right")}
                          style={{
                            position: "absolute",
                            right: "16px",
                            top: "25px",
                            fontSize: "10px",
                            zIndex: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "15px",
                          }}
                        >
                          <i className="fas fa-chevron-right" style={{ color : 'gray' }}></i>
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            {/* Data List Table with Scroll */}
            {/* <div className="table-wrapper">
              <table className="table table-bordered shadow-sm">
                <thead className="table-light">
                  <tr>
                    <th style={{ width: "5%" }}>
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th style={{ width: "15%" }}>Name</th>
                    <th style={{ width: "10%" }}>Date</th>
                    <th style={{ width: "10%" }}>Type</th>
                    <th style={{ width: "15%" }}>Location</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "10%" }}>Violation</th>
                    <th style={{ width: "10%" }}>Department</th>
                    <th style={{ width: "15%" }}>Manager</th>
                  </tr>
                </thead>
                <tbody>
                  {mockData.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(item.id)}
                          onChange={() => handleRowSelect(item.id)}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.date}</td>
                      <td>{item.type}</td>
                      <td>{item.location}</td>
                      <td>{item.status}</td>
                      <td>{item.violation}</td>
                      <td>{item.department}</td>
                      <td>{item.manager}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
