import React, { useState, useEffect } from "react";
import Sidebar from '../../Layout/Sidebar';
import Topbar from '../../Layout/Topbar';
import './BreakAndViolation.css';
import { ToastContainer, toast } from 'react-toastify';
import PremiseList from "../../Premise/PremiseList";
import Loader from "../../Common/Loader";
import BreakAndViolationReport from "./BreakAndViolationReport";

const BreakAndViolation = () => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [selectedPremise, setSelectedPremise] = useState(null);

    const pageLoading = (data) => {
        setLoading(data);
    }

    const premiseData = (data) => {
        console.log('premiseDatapremiseData--->', data);
        localStorage.removeItem('breakPremise');
        setSelectedPremise(data);
        localStorage.setItem("selectedBreakPremise", JSON.stringify(data));
        localStorage.setItem('breakPremise', data.name);
        setStep(2);
        window.history.pushState({ step: 2 }, "", "");
    }

    const premiseBackButton = (data) => {
        setStep(1);
        window.history.pushState({ step: 1 }, "", "");
    }

    useEffect(() => {
        const savedStep = localStorage.getItem("currentBreakStep");
        const selectedBreakPremise = JSON.parse(localStorage.getItem("selectedBreakPremise"));
        console.log('savedStep-->', savedStep);
        if (savedStep) {
          setSelectedPremise(selectedBreakPremise);
          setStep(parseInt(savedStep, 10));
        }

        // Listen for browser back button
        const handlePopState = (event) => {
          console.log('event-->',event)
            if (event.state) {
                setStep(1);
            }
        };

        window.addEventListener("popstate", handlePopState);

        // Cleanup the event listener when component unmounts
        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem("currentBreakStep", step);
        localStorage.removeItem('breakPremise');
    }, [step]);

    console.log('Current_step-->', step);

    const Title = 'Reports';
    const SubTitle = 'Breaks and Violations';

    return (
        <div className="dashboard-layout">
            <Sidebar />
            <div className="main-content dashboardMain">
                <Topbar />
                <ToastContainer />
                {loading && <Loader />}
                <div className="content">
                    {step === 1 ?
                        (
                            <PremiseList pageLoading={pageLoading} Title={Title} SubTitle={SubTitle} premiseData={premiseData} />
                        ) :
                        step === 2 ?
                            (
                                <BreakAndViolationReport selectedPremise={selectedPremise} premiseBackButton={premiseBackButton} />
                            ) :
                            null
                    }
                </div>
            </div>
        </div>
    );
};

export default BreakAndViolation;
