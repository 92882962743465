import React, { useState,useEffect } from "react";
import { Form, Button, Card, Dropdown, Container, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { Formik,Field,ErrorMessage } from "formik";
import './PolicyForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import { apiRequest } from "../../../services/api";
import { useToast } from "../../../contexts/ToastContext";
import { Next } from "react-bootstrap/esm/PageItem";
import { useNavigate } from "react-router-dom";
import { FaTimes } from 'react-icons/fa';

// StepProgress component with rounded tick marks
const StepProgress = ({ currentStep }) => {
  const steps = ["Policies", "Rules", "Save"];

  return (
    <div className="mb-4 position-relative">
      {/* Steps */}
      <div className="d-flex justify-content-between stepBarDiv position-relative w-100">
        {steps.map((step, index) => (
          <div
            key={`step-${index}`}
            className="text-center stepBar"
            style={{ flex: 1, position: "relative" }}
          >
            <div
              className={`step-circle ${index <= currentStep ? "completed" : ""}`}
              style={{
                width: "30px", // Circle size
                height: "30px", // Circle size
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto", // Center the circle
                border: "2px solid",
                borderColor: index <= currentStep ? "#007bff" : "#dcdcdc",
                backgroundColor: index <= currentStep ? "#007bff" : "transparent",
                color: index <= currentStep ? "#fff" : "#dcdcdc",
              }}
            >
              {index <= currentStep ? "✔" : ""}
            </div>
            <span
              className={`mt-2 d-block stepTitle ${
                index <= currentStep ? "text-primary" : "text-muted"
              }`}
              style={{ fontSize: "12px" }}
            >
              {step}
            </span>
          </div>
        ))}
      </div>

      {/* Progress Bar Segments */}
      <div className="stepBarLine"
        style={{
          position: "absolute",
          top: "13px", // Position the bars below the steps
          left: 0,
          right: 0,
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 stepbarwidth">
          {steps.slice(0, -1).map((_, index) => (
            <div
              key={`bar-${index}`}
              className={`bar-${index}`}
              style={{
                flex: 1,
                height: "2px",
                backgroundColor: index < currentStep ? "#007bff" : "#dcdcdc",
                margin: "0 10px", // Space between bars
                borderRadius: "4px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// const PoliciesStep = ({ onNext,policyFormbackButton,premiseName }) => {
//   // Get tomorrow's date in `yyyy-mm-dd` format
//   const navigate = useNavigate();
//   const getPolicyDetails = (values) => {
//     localStorage.setItem('policyName',values.policyName);
//     localStorage.setItem('policyDate',values.policyDate);
//   }
//   const getTomorrowDate = () => {
//     const tomorrow = new Date();
//     tomorrow.setDate(tomorrow.getDate() + 2); // Add 1 day
//     return tomorrow.toISOString().split("T")[0]; // Convert to yyyy-mm-dd
//   };

//   const policyBackButton = () =>{
//     console.log('sds-->',)
//     policyFormbackButton();
//   }

//   const initialValues = {
//     policyName: localStorage.getItem("policyName") || premiseName,
//     policyDate: localStorage.getItem("policyDate") || getTomorrowDate(), // Set the default value to tomorrow's date
//   };

//   const validationSchema = Yup.object({
//     policyName: Yup.string()
//       .trim()
//       .min(5, "Policy name must be at least 5 characters long.")
//       .required("Policy name is required."),
//     policyDate: Yup.date()
//       .min(getTomorrowDate(), "Policy date must be in the future.")
//       .required("Policy date is required."),
//   });

//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={(values) => {
//         onNext(values); // Pass the validated form values to the next step
//         getPolicyDetails(values);
//       }}
//     >
//       {({ handleSubmit }) => (
//         <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
//           <Card.Body>
//             <div className="row">
//               <div className="col-6">
//               <i
//                 className="fa fa-long-arrow-left backIcon"
//                 aria-hidden="true"
//                 onClick={() => policyBackButton()}
//                 style={{ cursor: "pointer" }}
//               ></i>
//               </div>
//               <div className="col-6">
//               </div>
//             </div>
//             <h4 className="text-primary mb-4 text-center policyFormTitle">Policies</h4>
//             <Form noValidate onSubmit={handleSubmit} className="PolicyCreateForm" autoComplete="OFF">
//             <div className="row mb-2 loginInputDiv policyValue">
//               <div className="col-md-12"> 
//               <div className="form-group mb-4">
//                 <Field
//                   type="text"
//                   name="policyName"
//                   id="policyName"
//                   className="login-form-input policy-input"
//                 />
//                 <label htmlFor="policyName" className="form-label">
//                   Policy Name <span className="text-danger">*</span>
//                 </label>
//               </div>
//               </div>
//               <ErrorMessage
//                   name="policyName"
//                   component="div"
//                   className="text-danger policy-error mb-2"
//                 />
//               <div className="col-md-12 mt-4"> 
//               <div className="form-group mb-3 date-picker-wrapper">
//               <Field
//                 type="date"
//                 name="policyDate"
//                 id="policyDate"
//                 className="login-form-input policy-input styled-date-picker"
//                 min={getTomorrowDate()} // Set minimum date to tomorrow
//                 onClick={(e) => e.target.showPicker()} // Show picker on click
//                 onKeyDown={(e) => e.preventDefault()} // Disable manual typing
//               />
//               <label htmlFor="policyDate" className="form-label">
//                 Policy Date <span className="text-danger">*</span>
//               </label>
//               <ErrorMessage name="policyDate" component="div" className="text-danger" />
//             </div>
//               </div>
//               </div>
//               {/* <div className="d-flex justify-content-between mt-4">
//                 <Button variant="light" disabled>
//                   Previous
//                 </Button>
//                 <Button variant="primary" >
//                   Next
//                 </Button>
//               </div> */}

//               <div className="d-flex justify-content-end mt-5">
//                 {/* <Button className="previousBtn" variant="light" disabled>
//                   Previous
//                 </Button> */}
//                 <Button className="previousBtn" type="submit" variant="primary">
//                   Next
//                 </Button>
//               </div>
//             </Form>
//           </Card.Body>
//         </Card>
//       )}
//     </Formik>
//   );
// };


// const PoliciesStep = ({ onNext, policyFormbackButton, premiseName, policyList }) => {
//   const navigate = useNavigate();

//   const getPolicyDetails = (values) => {
//     localStorage.setItem("policyName", values.policyName);
//     localStorage.setItem("policyDate", values.policyDate);
//   };

//   const getTomorrowDate = () => {
//     const tomorrow = new Date();
//     tomorrow.setDate(tomorrow.getDate() + 1); // Add 1 day
//     return tomorrow.toISOString().split("T")[0]; // Convert to yyyy-mm-dd
//   };

//   const policyBackButton = () => {
//     policyFormbackButton();
//   };

//   const initialValues = {
//     policyName: localStorage.getItem("policyName") || premiseName,
//     policyDate: localStorage.getItem("policyDate") || getTomorrowDate(), // Set the default value to tomorrow's date
//   };

//   // Check if the policy name exists
//   const isDuplicatePolicyName = (name) => {
//     return policyList.some(
//       (policy) => policy.policy_name.toLowerCase() === name.toLowerCase()
//     );
//   };

//   // Check if the policy date exists for the same premise
//   const isDuplicatePolicyDate = (date) => {
//     return policyList.some(
//       (policy) => policy.start_date_time.split(" ")[0] === date
//     );
//   };

//   const validationSchema = Yup.object({
//     policyName: Yup.string()
//       .trim()
//       .min(5, "Policy name must be at least 5 characters long.")
//       .required("Policy name is required.")
//       .test(
//         "unique-policy-name",
//         "Policy name already exists. Please choose a different name.",
//         function (value) {
//           return !isDuplicatePolicyName(value);
//         }
//       ),
//     policyDate: Yup.date()
//       .min(getTomorrowDate(), "Policy date must be in the future.")
//       .required("Policy date is required.")
//       .test(
//         "unique-policy-date",
//         "Policy date already exists for the same premise.",
//         function (value) {
//           return !isDuplicatePolicyDate(value);
//         }
//       ),
//   });

//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={(values) => {
//         onNext(values); // Pass the validated form values to the next step
//         getPolicyDetails(values);
//       }}
//     >
//       {({ handleSubmit }) => (
//         <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
//           <Card.Body>
//             <div className="row">
//               <div className="col-6">
//                 <i
//                   className="fa fa-long-arrow-left backIcon"
//                   aria-hidden="true"
//                   onClick={() => policyBackButton()}
//                   style={{ cursor: "pointer" }}
//                 ></i>
//               </div>
//               <div className="col-6"></div>
//             </div>
//             <h4 className="text-primary mb-4 text-center policyFormTitle">
//               Policies
//             </h4>
//             <Form noValidate onSubmit={handleSubmit} className="PolicyCreateForm" autoComplete="OFF">
//               <div className="row mb-2 loginInputDiv policyValue">
//                 <div className="col-md-12">
//                   <div className="form-group mb-4">
//                     <Field
//                       type="text"
//                       name="policyName"
//                       id="policyName"
//                       className="login-form-input policy-input"
//                     />
//                     <label htmlFor="policyName" className="form-label">
//                       Policy Name <span className="text-danger">*</span>
//                     </label>
//                   </div>
//                 </div>
//                 <ErrorMessage
//                   name="policyName"
//                   component="div"
//                   className="text-danger policy-error mb-2"
//                 />
//                 <div className="col-md-12 mt-4">
//                   <div className="form-group date-picker-wrapper policyMainForm">
//                     <Field
//                       type="date"
//                       name="policyDate"
//                       id="policyDate"
//                       className="login-form-input policy-input styled-date-picker"
//                       min={getTomorrowDate()} // Set minimum date to tomorrow
//                       onClick={(e) => e.target.showPicker()} // Show picker on click
//                       onKeyDown={(e) => e.preventDefault()} // Disable manual typing
//                     />
//                     <label htmlFor="policyDate" className="form-label">
//                       Policy Date <span className="text-danger">*</span>
//                     </label>
//                   </div>
//                   <ErrorMessage name="policyDate" component="div" className="text-danger register-error policy-form-error" />
//                 </div>
//               </div>
//               <div className="d-flex justify-content-end mt-5">
//                 <Button className="previousBtn" type="submit" variant="primary">
//                   Next
//                 </Button>
//               </div>
//             </Form>
//           </Card.Body>
//         </Card>
//       )}
//     </Formik>
//   );
// };

const PoliciesStep = ({ onNext, policyFormbackButton, premiseName, policyList }) => {
  const navigate = useNavigate();

  const getPolicyDetails = (values) => {
    localStorage.setItem("policyName", values.policyName);
    localStorage.setItem("policyDate", values.policyDate);
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2); // Add 2 days
    return tomorrow.toISOString().split("T")[0]; // Convert to yyyy-mm-dd
  };

  const policyBackButton = () => {
    policyFormbackButton();
  };

  const initialValues = {
    policyName: localStorage.getItem("policyName") || premiseName,
    policyDate: localStorage.getItem("policyDate") || getTomorrowDate(), // Default to tomorrow's date
  };

  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Check if the policy name exists
  const isDuplicatePolicyName = (name) => {
    return policyList.some(
      (policy) => policy.policy_name.toLowerCase() === name.toLowerCase()
    );
  };

  // Check if the policy date exists for the same premise
  const isDuplicatePolicyDate = (date) => {
    return policyList.some((policy) => {
      // Extract only the date part from the start_date_time
      const policyDate = policy.start_date_time.split(" ")[0]; 
      return policyDate === date; // Compare only the date portion
    });
  };

  const validationSchema = Yup.object({
    policyName: Yup.string()
      .trim()
      .min(5, "Policy name must be at least 5 characters long.")
      .required("Policy name is required.")
      .test(
        "unique-policy-name",
        "This policy name is already taken. Please try a different one.",
        function (value) {
          return !isDuplicatePolicyName(value);
        }
      ),
    policyDate: Yup.date()
      .min(getTomorrowDate(), "Policy date must be in the future.")
      .required("Policy date is required.")
      .test(
        "unique-policy-date",
        "This policy date is already taken. Please try a different one.",
        function (value) {
          if (!value) return true; // Skip if the value is null/undefined
          const selectedDate = formatLocalDate(value); // Use the helper function
          console.log('selectedDate--->',selectedDate)
          return !isDuplicatePolicyDate(selectedDate);
        }
      ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onNext(values); // Pass the validated form values to the next step
        getPolicyDetails(values);
      }}
    >
      {({ handleSubmit }) => (
        <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
          <Card.Body>
            <div className="row">
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  onClick={() => policyBackButton()}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col-6"></div>
            </div>
            <h4 className="text-primary mb-4 text-center policyFormTitle">Policies</h4>
            <Form noValidate onSubmit={handleSubmit} className="PolicyCreateForm" autoComplete="OFF">
              <div className="row mb-2 loginInputDiv policyValue">
                <div className="col-md-12">
                  <div className="form-group mb-4">
                    <Field
                      type="text"
                      name="policyName"
                      id="policyName"
                      className="login-form-input policy-input"
                    />
                    <label htmlFor="policyName" className="form-label">
                      Policy Name <span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="policyName"
                  component="div"
                  className="text-danger policy-name-error policy-error mb-2"
                />
                <div className="col-md-12 mt-4">
                  <div className="form-group mb-2 date-picker-wrapper">
                    <Field
                      type="date"
                      name="policyDate"
                      id="policyDate"
                      className="login-form-input policy-input styled-date-picker"
                      min={getTomorrowDate()} // Set minimum date to tomorrow
                      onClick={(e) => e.target.showPicker()} // Show picker on click
                      onKeyDown={(e) => e.preventDefault()} // Disable manual typing
                    />
                    <label htmlFor="policyDate" className="form-label">
                      Policy Date <span className="text-danger">*</span>
                    </label>
                  </div>
                  <ErrorMessage name="policyDate" component="div" className="text-danger policy-error mb-2" />
                </div>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <Button className="previousBtn" type="submit" variant="primary">
                  Next
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};



const RulesStep = ({ onPrevious, onNext }) => {
  const [breakType,setBreakType] = useState([]);
  const [rules, setRules] = useState(() => {
    // Retrieve rules from local storage or initialize with a default rule
    const savedRules = localStorage.getItem("rules");
    return savedRules
      ? JSON.parse(savedRules)
      : [
          {
            rule_name: "",
            workDuration: "1h",
            workDurationMins: "0m",
            breakDuration: "",
            breakType: localStorage.getItem("breakType"),
            notificationType: localStorage.getItem("notificationType"),
            rules_and_alerts_uitext_id: null,
            rules_and_alerts_breaktype_id : null,
            reminderInterval: "5",
            reminderCount: "3",
            restBreak1: true,
            acceptBreak: true,
            rejectBreak: true,
            addViolation : true,
            penalty: false,
            violation: {
              violation_rule_name : "",
              workDuration1: "1h",  // Default value
              workDurationMins1: "15m",      // Default value
              notificationType1: "send notification to the manager & admin",    // Initialize violation message if needed
              violationDate1: null,     // Store violation date if needed
              violation_rules_and_alerts_uitext_id : "2"
            },
          },
        ];
  });

  const [showModal, setShowModal] = useState(false);
  const [ruleType,setRuleType] = useState([]);
  const [ruleTypeViolation,setRuleTypeViolation] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  // const [breakMinTime, setBreakMinTime] = useState({});
  // const [startWorkHours, setStartWorkHours] = useState(localStorage.getItem("startWorkHours") ? : localStorage.getItem("notificationType") : {});
  // const [startWorkMins, setStartWorkMins] = useState({});
  // const [startWorkMins1, setStartWorkMins1] = useState({});
  // const [violationHours, setViolationHours] = useState({});
  // const [violationMins, setViolationMins] = useState({});

  const [startWorkHours, setStartWorkHours] = useState(() => {
    const savedValue = localStorage.getItem("startWorkHours");
    return savedValue ? JSON.parse(savedValue) : {}; // Fallback to an empty object
  });

  const [startWorkMins, setStartWorkMins] = useState(() => {
    const savedValue = localStorage.getItem("startWorkMins");
    return savedValue ? JSON.parse(savedValue) : {}; // Fallback to an empty object
  });

  const [startWorkMins1, setStartWorkMins1] = useState(() => {
    const savedValue = localStorage.getItem("startWorkMins1");
    return savedValue ? JSON.parse(savedValue) : {}; // Fallback to an empty object
  });

  const [violationHours, setViolationHours] = useState(() => {
    const savedValue = localStorage.getItem("violationHours");
    return savedValue ? JSON.parse(savedValue) : {}; // Fallback to an empty object
  });

  const [violationMins, setViolationMins] = useState(() => {
    const savedValue = localStorage.getItem("violationMins");
    return savedValue ? JSON.parse(savedValue) : {}; // Fallback to an empty object
  });

  const [breakMinTime, setBreakMinTime] = useState(() => {
    const savedValue = localStorage.getItem("breakMinTime");
    return savedValue ? JSON.parse(savedValue) : {}; // Fallback to an empty object
  });

  // Sync state to localStorage on change
  useEffect(() => {
    localStorage.setItem("startWorkHours", JSON.stringify(startWorkHours));
    localStorage.setItem("startWorkMins", JSON.stringify(startWorkMins));
    localStorage.setItem("startWorkMins1", JSON.stringify(startWorkMins1));
    localStorage.setItem("violationHours", JSON.stringify(violationHours));
    localStorage.setItem("violationMins", JSON.stringify(violationMins));
    localStorage.setItem("breakMinTime", JSON.stringify(breakMinTime));
  }, [startWorkHours, startWorkMins, startWorkMins1, violationHours, violationMins, breakMinTime]);

  console.log('startWorkHours--->',startWorkHours)
  console.log('startWorkMins--->',startWorkMins)
  console.log('startWorkMins1--->',startWorkMins1)
  console.log('violationHours--->',violationHours)
  console.log('violationMins--->',violationMins)
  console.log('breakMinTime--->',breakMinTime)
  
  // Save rules to local storage whenever they are updated
  useEffect(() => {
    console.log('rulesIntial--->',rules)
    if(rules.rule_name !== ""){
      localStorage.setItem("rules", JSON.stringify(rules));
    }
  }, [rules]);

  console.log('startWorkHours--->',startWorkHours)
    // Save rules to local storage whenever they are updated
    useEffect(() => {
      const getRulesUiText = async () => {
        const authToken = localStorage.getItem('authToken');
        const headers = {
          "Authorization" : `Bearer ${authToken}`
        }
        const rulesResponse = await apiRequest('web_api/admin/getRulesUiText','GET',null,headers)
        // console.log('rulesResponse--->',rulesResponse)
        if(rulesResponse?.data?.status_code == 200){
          const rule_list = rulesResponse.data?.data?.get_rules_and_alerts_uitext;
          // console.log('rulesResponse--->',rule_list)
          let rule_list_violation = rule_list.filter(item => item.text_to_be_displayed !== "send notification to the user");
          setRuleTypeViolation(rule_list_violation);
          setRuleType(rule_list);
          localStorage.setItem('notificationType',rule_list[0]?.text_to_be_displayed);
          localStorage.setItem('notificationType1',rule_list_violation[0]?.text_to_be_displayed);
          localStorage.setItem('rules_and_alerts_uitext_id',rule_list[0]?.rules_and_alerts_uitext_id);
          localStorage.setItem('violation_rules_and_alerts_uitext_id',rule_list_violation[0]?.rules_and_alerts_uitext_id);
          setRules((prevRules) => {
            const updatedRules = [...prevRules];
            if (updatedRules.length > 0) {
              // Update only the first index's notificationType
              updatedRules[0] = {
                ...updatedRules[0],
                notificationType: rule_list[0]?.text_to_be_displayed, // Update this field if needed
                rules_and_alerts_uitext_id: rule_list[0]?.rules_and_alerts_uitext_id,
              };
            } else {
              // Handle case where prevRules is empty (add initial rule object)
              updatedRules.push({
                notificationType: rule_list[0]?.text_to_be_displayed,
                rules_and_alerts_uitext_id: rule_list[0]?.rules_and_alerts_uitext_id,
              });
            }
            return updatedRules;
          });
          
        }
      }

      const getBreakType = async () => {
        const authToken = localStorage.getItem('authToken');
        const headers = {
          "Authorization" : `Bearer ${authToken}`
        }
        const rulesResponse = await apiRequest('web_api/admin/getBreakType','GET',null,headers)
        // console.log('rulesResponse--->',rulesResponse)
        if(rulesResponse?.data?.status_code == 200){
          let break_list = rulesResponse.data?.data?.get_staff_break_type;
          // console.log('BreakTypeResponse--->',break_list)
          break_list = break_list.filter(item => item.rules_and_alerts_breaktype !== "Violation");
          setBreakType(break_list);
          localStorage.setItem('breakType',break_list[0]?.rules_and_alerts_breaktype);
          localStorage.setItem('rules_and_alerts_breaktype_id',break_list[0]?.rules_and_alerts_breaktype_id);
          localStorage.setItem('breakDuration',break_list[0]?.minimum_minutes);
          setRules((prevRules) => {
            const updatedRules = [...prevRules];
            if (updatedRules.length > 0) {
              // Update only the first index's breakType
              updatedRules[0] = {
                ...updatedRules[0],
                breakType: break_list[0]?.rules_and_alerts_breaktype, // Update this field if needed
                rules_and_alerts_breaktype_id: break_list[0]?.rules_and_alerts_breaktype_id,
                breakDuration:break_list[0]?.minimum_minutes
              };
            } else {
              // Handle case where prevRules is empty (add initial rule object)
              updatedRules.push({
                breakType: break_list[0]?.rules_and_alerts_breaktype,
                rules_and_alerts_breaktype_id: break_list[0]?.rules_and_alerts_breaktype_id
              });
            }
            return updatedRules;
          });
        }
      }
      getRulesUiText();
      getBreakType();
    }, []);
  // console.log('breakMinTime--->',breakMinTime)
  // Helper functions for dropdown options
  // const generateHourOptions = (index) =>
  //   [...Array(24-1)].map((_, hour) => (
  //     <Dropdown.Item 
  //       className="dropdownList"
  //       key={hour}
  //       onClick={() => handleChange("workDuration", `${hour + 1}h`, index)}
  //     >
  //       {hour + 1}h
  //     </Dropdown.Item>
  //   ));

    const generateHourOptions = (index) => {
      const startHour = startWorkHours[index] !== undefined ? startWorkHours[index] : 1; // Use startHour if defined
      return [...Array(24 - startHour)] // Create an array starting from startHour up to 24
        .map((_, hour) => hour + startHour) // Generate hours starting from startHour
        .map(hour => (
          <Dropdown.Item
            className="dropdownList"
            key={hour}
            onClick={() => handleChange("workDuration", `${hour}h`, index)}
          >
            {hour}h
          </Dropdown.Item>
        ));
    };

    const generateHourOptions1 = (index) => {
      const startHour = violationHours[index] !== undefined ? violationHours[index] : 1;
      return [...Array(24 - startHour)] // Create an array starting from startHour up to 24
        .map((_, hour) => hour + startHour) // Generate hours starting from startHour
        .map(hour => (
          <Dropdown.Item
            className="dropdownList"
            key={hour}
            onClick={() => handleChange("workDuration1", `${hour}h`, index, "workDuration1")}
          >
            {hour}h
          </Dropdown.Item>
        ));
    };

      // const generateMinuteOptions1 = (index) => {
      //   const startMinute = violationMins[index] !== undefined ? violationMins[index] : 15; // Default to 0 if undefined
      //   return [...Array(13 - Math.floor(startMinute / 5))] // Adjust array size based on startMinute
      //     .map((_, i) => i * 5 + startMinute) // Generate multiples of 5 starting from startMinute
      //     .map(minute => (
      //       <Dropdown.Item
      //         className="dropdownList"
      //         key={minute}
      //         onClick={() =>
      //           handleChange("workDurationMins1", `${minute}m`, index, "workDurationMins1")
      //         }
      //       >
      //       {`${minute}m`} 
      //       </Dropdown.Item>
      //     ));
      // };

      const generateMinuteOptions1 = (index) => {
        const startMinute = violationMins[index] !== undefined ? violationMins[index] : 15; // Default to 15 if undefined
        console.log('violationMins--->', violationMins);
        console.log('startMinute--->', startMinute);
      
        return [...Array(12)] // Array of 12 items for multiples of 5 (0 to 55)
          .map((_, i) => i * 5) // Generate multiples of 5 (0, 5, 10, ..., 55)
          .concat(59) // Add 59 explicitly
          .filter(minute => minute >= startMinute) // Filter to include only values >= startMinute
          .map(minute => (
            <Dropdown.Item
              className="dropdownList"
              key={minute}
              onClick={() =>
                handleChange("workDurationMins1", `${minute}m`, index, "workDurationMins1")
              }
            >
              {`${minute}m`}
            </Dropdown.Item>
          ));
      };
      
      
    // const generateMinuteOptions = (type, index) => {
    //   const minimumMinutes = startWorkMins[index] !== undefined ? startWorkMins[index] : 0;
    // console.log('startWorkMins--->',startWorkMins)
    // console.log('minimumMinutes--->',minimumMinutes)
    //   return [...Array(13)] // Array of 13 items (0m to 60m in 5-minute increments)
    //     .map((_, i) => i * 5) // Generate multiples of 5 (0, 5, 10, ...)
    //     .filter(minute =>
    //       type === "workDurationMins"
    //         ? minute >= minimumMinutes
    //         : true
    //     ) // Apply filters for minimum minutes
    //     .map(minute => (
    //       <Dropdown.Item
    //         className="dropdownList"
    //         key={minute}
    //         onClick={() =>
    //           handleChange(type, `${minute}${type !== "breakDuration" ? "m" : ""}`, index)
    //         }
    //       >
    //         {`${minute}m`} 
    //         {/* {minute}m */}
    //       </Dropdown.Item>
    //     ));
    // };

    const generateMinuteOptions = (type, index) => {
      const minimumMinutes = startWorkMins[index] !== undefined ? startWorkMins[index] : 0;
      console.log('startWorkMins--->', startWorkMins);
      console.log('minimumMinutes--->', minimumMinutes);
    
      return [...Array(12)] // Array of 12 items for multiples of 5 (0 to 55)
        .map((_, i) => i * 5) // Generate multiples of 5 (0, 5, 10, ..., 55)
        .concat(59) // Add 59 explicitly
        .filter(minute =>
          type === "workDurationMins"
            ? minute >= minimumMinutes
            : true
        ) // Apply filters for minimum minutes
        .map(minute => (
          <Dropdown.Item
            className="dropdownList"
            key={minute}
            onClick={() =>
              handleChange(type, `${minute}${type !== "breakDuration" ? "m" : ""}`, index)
            }
          >
            {`${minute}m`}
          </Dropdown.Item>
        ));
    };
    

    const generateMinuteOptionsForBreak = (type, index) => {
      const minimumMinutes = breakMinTime[index] !== undefined ? breakMinTime[index] : 10;
    console.log('breakMinTime--->',breakMinTime)
    console.log('minimumMinutes--->',minimumMinutes)
      return [...Array(12)] // Array of 12 items for multiples of 5 (0 to 55)
        .map((_, i) => i * 5) // Generate multiples of 5 (0, 5, 10, ..., 55)
        .concat(59) // Add 59 explicitly
        .filter(minute =>
          type === "breakDuration"
            ? minute >= minimumMinutes
            : type === "reminderInterval"
            ? minute >= 1
            : true
        ) // Apply filters for minimum minutes
        .map(minute => (
          <Dropdown.Item
            className="dropdownList"
            key={minute}
            onClick={() =>
              handleChange(type, `${minute}${type !== "breakDuration" ? "m" : ""}`, index)
            }
          >
            {`${minute}m`} 
            {/* {minute}m */}
          </Dropdown.Item>
        ));
    };

    const generateMinuteOptionsForExprire = (type, index) => {
      const minimumMinutes = breakMinTime[index] !== undefined ? breakMinTime[index] : 10;
    
      return [...Array(12)] // Array of 12 items for multiples of 5 (0 to 55)
        .map((_, i) => i * 5) // Generate multiples of 5 (0, 5, 10, ..., 55)
        .concat(59) // Add 59 explicitly
        .filter(minute =>
          type === "breakDuration"
            ? minute >= minimumMinutes
            : type === "reminderInterval"
            ? minute >= 1
            : true
        ) // Apply filters for minimum minutes
        .map(minute => (
          <Dropdown.Item
            className="dropdownList"
            key={minute}
            onClick={() =>
              handleChange(type, `${minute}`, index)
            }
          >
            {minute === 60 ? `${minute - 1}m` : `${minute}m`} 
            {/* {minute}m */}
          </Dropdown.Item>
        ));
    };


  const handleChange = (key, value, index, violationKey = null) => {
    setRules((prevRules) => {
      const updatedRules = [...prevRules];
  
      // If violationKey is provided, update the violation object
      if (violationKey) {
        if(violationKey === "violation_rule_name"){
          setViolationErrorFields((prevErrors) => prevErrors.filter((i) => i !== index));
        }
        if (key === "notificationType1") {
          updatedRules[index].violation = {
            ...updatedRules[index].violation,
            [key]: value.text,  // Update the displayed text in violation
            [`${key}Id`]: value.id,  // Update the associated ID in violation
            [`violation_rules_and_alerts_uitext_id`]: value.id, 
          };
        } else {
          updatedRules[index].violation[violationKey] = value;  // For other violation properties
        }

        if (key === "workDuration1") {
          console.log('updatedRules[index].workDuration--->',updatedRules[index].workDuration);
          console.log('updatedRules[index].violation[workDuration1]',updatedRules[index].violation['workDuration1']);
          if(updatedRules[index].workDuration !== updatedRules[index].violation['workDuration1']){
            updatedRules[index] = {
              ...updatedRules[index],
              violation: {
                ...updatedRules[index].violation,
                // workDurationMins1: `${0}m`, 
              }
            };
            setViolationMins((prev) => ({ ...prev, [index]: (0 + 0) }));
          }else{
            const workDuration =
            key === "workDuration" ? value : updatedRules[index].workDuration;
            const workDurationMins =
            key === "workDurationMins" ? value : updatedRules[index].workDurationMins;
            console.log('workDurationMinsaaa-->',workDurationMins)
            const workDurationHoursValue = parseInt(workDuration.replace("h", ""), 10) || 0;
            let workDurationMinsValue = parseInt(workDurationMins.replace("m", ""), 10) || 0;
            console.log('hoursWorkDurationMins-->',workDurationMinsValue)
            if(workDurationMinsValue > 45){
              workDurationMinsValue = workDurationMinsValue == '59' 
              ? (workDurationMinsValue+1) : workDurationMinsValue;
              console.log('45above')
              console.log('hoursWorkDurationMins-->',workDurationMinsValue)
              updatedRules[index] = {
                ...updatedRules[index],
                violation: {
                  ...updatedRules[index].violation,
                  workDurationMins1: `${(workDurationMinsValue + 15) - 60}m`, // Sync violation_rule_name with rule_name
                }
              };
              setViolationMins((prev) => ({ ...prev, [index]: ((workDurationMinsValue + 15) - 60) }));
            }
            else{
              updatedRules[index] = {
                ...updatedRules[index],
                violation: {
                  ...updatedRules[index].violation,
                  workDurationMins1: `${workDurationMinsValue + 15}m`, // Sync violation_rule_name with rule_name
                }
              };
              setViolationMins((prev) => ({ ...prev, [index]: (workDurationMinsValue + 15) }));
            }
          }
        }

      } else {
        // if(key === "addViolation"){
        //   console.log('updatedRules[index].addViolation-->',updatedRules[index].addViolation)
        //   if(updatedRules[index].addViolation === true){
        //     updatedRules[index].violation = {};
        //     console.log('violation object',updatedRules[index].violation);
        //   }
        // }
        if (key === "workDuration") {
          console.log('workDuration-->',value)
          const workDuration =
          key === "workDuration" ? value : updatedRules[index].workDuration;
          const workDurationMins =
          key === "workDurationMins" ? value : updatedRules[index].workDurationMins;
          const workDurationHoursValue = parseInt(workDuration.replace("h", ""), 10) || 0;
          const workDurationMinsValue = parseInt(workDurationMins.replace("m", ""), 10) || 0;

          updatedRules[index] = {
            ...updatedRules[index],
            workDuration: value,
            // workDurationMins: '0m',
            violation: {
              ...updatedRules[index].violation,
              workDuration1: value, // Sync violation_rule_name with rule_name
            }
          };
          setViolationHours((prev) => ({ ...prev, [index]: workDurationHoursValue }));
          // if(workDurationMinsValue > 45){
          //   console.log('65above');
          //   console.log(`65above,${workDurationHoursValue + 1}h`);
          //   console.log(`65above,${(workDurationMinsValue + 15) - 60}m`);
          //   // updatedRules[index] = {
          //   //   violation: {
          //   //     ...updatedRules[index].violation,
          //   //     workDuration1: `${workDurationHoursValue + 1}h`,
          //   //     workDurationMins1: `${(workDurationMinsValue + 15) - 60}m`, // Sync violation_rule_name with rule_name
          //   //   }
          //   // };
          // }
          // else{
            updatedRules[index] = {
              ...updatedRules[index],
              violation: {
                ...updatedRules[index].violation,
                // workDurationMins1: `${15}m`,
              }
            };
            // setViolationMins((prev) => ({ ...prev, [index]: (0 + 15) }));
          // }
          console.log('updatedRules[index].workDuration-->',workDurationHoursValue)
          console.log('startWorkHours[index]-->',startWorkHours[index])
          if(workDurationHoursValue === startWorkHours[index]){
            console.log('startWorkMins[index]-->',startWorkMins[index])
            updatedRules[index] = {
              ...updatedRules[index],
              workDurationMins: `${startWorkMins1[index]}m`,
            };
            setStartWorkMins((prev) => ({ ...prev, [index]: startWorkMins1[index] }));
          }else{
            setStartWorkMins((prev) => ({ ...prev, [index]: (0 + 0) }));
          }
        }
        if (key === "workDurationMins") {
          const workDuration =
          key === "workDuration" ? value : updatedRules[index].workDuration;
          const workDurationMins =
          key === "workDurationMins" ? value : updatedRules[index].workDurationMins;
          console.log('workDurationMinsaaa-->',workDurationMins)
          const workDurationHoursValue = parseInt(workDuration.replace("h", ""), 10) || 0;
          let workDurationMinsValue = parseInt(workDurationMins.replace("m", ""), 10) || 0;
          console.log('hoursWorkDurationMins-->',workDurationMinsValue)
          if(workDurationMinsValue > 45){
            workDurationMinsValue = workDurationMinsValue == '59' 
            ? (workDurationMinsValue+1) : workDurationMinsValue;
            updatedRules[index] = {
              ...updatedRules[index],
              workDurationMins: value,  // Update rule name
              violation: {
                ...updatedRules[index].violation,
                workDuration1: `${workDurationHoursValue + 1}h`,
                workDurationMins1: `${(workDurationMinsValue + 15) - 60}m`, // Sync violation_rule_name with rule_name
              }
            };
            setViolationMins((prev) => ({ ...prev, [index]: ((workDurationMinsValue + 15) - 60) }));
          }
          else{
            console.log('workDurationMinsValue123--->',workDurationMinsValue)
            workDurationMinsValue = workDurationMinsValue == '45' ? (workDurationMinsValue -1) : workDurationMinsValue;
            updatedRules[index] = {
              ...updatedRules[index],
              workDurationMins: value,  // Update rule name
              violation: {
                ...updatedRules[index].violation,
                workDurationMins1: `${workDurationMinsValue + 15}m`,
              }
            };
            setViolationMins((prev) => ({ ...prev, [index]: (workDurationMinsValue + 15) }));
          }
        }
        if(key === "rule_name"){
          const sanitizedValue = value
          .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
          .trim();
          console.log('sanitizedValue--->',sanitizedValue)
          updatedRules[index] = {
            ...updatedRules[index],
            rule_name: sanitizedValue,  // Update rule name
            violation: {
              ...updatedRules[index].violation,
              violation_rule_name: `${sanitizedValue} Violation`, // Sync violation_rule_name with rule_name
            }
          };
          setErrorFields((prevErrors) => prevErrors.filter((i) => i !== index));
          setViolationErrorFields((prevErrors) => prevErrors.filter((i) => i !== index));
        }
        // Recalculate continuous_hours whenever workDuration or workDurationMins changes
        // if (key === "workDuration" || key === "workDurationMins") {
          const workDuration =
            key === "workDuration" ? value : updatedRules[index].workDuration;
          const workDurationMins =
            key === "workDurationMins" ? value : updatedRules[index].workDurationMins;
  
          const hours = parseInt(workDuration.replace("h", ""), 10) || 0;
          const minutes = parseInt(workDurationMins.replace("m", ""), 10) || 0;
  
          const totalMinutes = hours * 60 + minutes;
          const formattedHours = Math.floor(totalMinutes / 60)
            .toString()
            .padStart(2, "0");
          const formattedMinutes = (totalMinutes % 60).toString().padStart(2, "0");
  
          updatedRules[index].continuous_hours = `${formattedHours}:${formattedMinutes}:00`;
        // }
  
        // Handle breakType change
        if (key === "breakType") {
          const restBreak = breakType.find(
            (item) => item.rules_and_alerts_breaktype === value.text
          );
  
          // Extract the minimum_minutes
          const minimumMinutes = restBreak ? restBreak.minimum_minutes : "Not found";
          updatedRules[index].breakDuration = minimumMinutes;
          setBreakMinTime((prev) => ({ ...prev, [index]: minimumMinutes }));
          // console.log('updatedRules[index].breakType-->', minimumMinutes);
        }
  
        // Handle breakDuration formatting
        const breakDuration =
          key === "breakDuration" ? value : updatedRules[index].breakDuration;
  
        const formattedHours1 = "00"; // Hours will always be "00"
        const formattedMinutes1 = breakDuration.toString().padStart(2, "0"); // Calculate minutes and format to 2 digits
  
        updatedRules[index].total_break_minutes = `${formattedHours1}:${formattedMinutes1}:00`;
  
        // Handle reminderInterval formatting
        const reminderInterval =
          key === "reminderInterval" ? value : updatedRules[index].reminderInterval;
        console.log('reminderInterval--->',reminderInterval)
        const formattedHours2 = "00"; // Hours will always be "00"
        const formattedMinutes2 = reminderInterval.toString().padStart(2, "0"); // Format minutes
        console.log('formattedMinutes2--->',formattedMinutes2)
        updatedRules[index].notification_expiration_duration = `${formattedHours2}:${formattedMinutes2}:00`;
      }
  
      // If the key is notificationType or breakType, update the corresponding property
      if (key === "notificationType" || key === "breakType") {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value.text, // Update the displayed text
          [`${key}Id`]: value.id, // Update the associated ID
        };
      } else {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value,
        };
      }

      if (key === "notificationType") {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value.text, // Update the displayed text
          [`rules_and_alerts_uitext_id`]: value.id, // Update the associated ID
        };
      }

      if (key === "breakType") {
        updatedRules[index] = {
          ...updatedRules[index],
          [key]: value.text, // Update the displayed text
          [`rules_and_alerts_breaktype_id`]: value.id, // Update the associated ID
        };
      }

      // Update other properties as needed
      updatedRules[index].waivable_status = updatedRules[index].rejectBreak === true ? "waivable" : "mandatory";
      updatedRules[index].max_waivable_hours = "";
      updatedRules[index].required_prior_break = "";
      updatedRules[index].waiver_conditions = "";
      updatedRules[index].violation_notification = "true";

      // This violation section hours and minuties

      // if (key === "workDuration1" || key === "workDurationMins1") {

      console.log('updatedRules[index].addViolation-->',updatedRules[index].addViolation)
      if(updatedRules[index].addViolation){
            const workDuration1 =
            key === "workDuration1" ? value : updatedRules[index].violation.workDuration1;
          const workDurationMins1 =
            key === "workDurationMins1" ? value : updatedRules[index].violation.workDurationMins1;

          const hours1 = parseInt(workDuration1.replace("h", ""), 10) || 0;
          const minutes1 = parseInt(workDurationMins1.replace("m", ""), 10) || 0;
          console.log('minutes1-->',minutes1)

          const totalMinutes1 = hours1 * 60 + minutes1;
          const formattedHours1 = Math.floor(totalMinutes1 / 60)
            .toString()
            .padStart(2, "0");
          const formattedMinutes1 = (totalMinutes1 % 60).toString().padStart(2, "0");
          console.log('formattedMinutes1-->',formattedMinutes1)

          updatedRules[index].violation.violation_continuous_hours = `${formattedHours1}:${formattedMinutes1}:00`;
    // }
      }else{
        updatedRules[index].violation = {
          violation_rule_name : "",
          workDuration1: "1h",  // Default value
          workDurationMins1: "15m",      // Default value
          notificationType1: "send notification to the manager & admin",    // Initialize violation message if needed
          violationDate1: null,     // Store violation date if needed
          violation_rules_and_alerts_uitext_id : "2"
        };
      }
  
      return updatedRules;
    });
  };
  

  // const addNewRule = () => {

  //     setRules([
  //       ...rules,
  //       {
  //         rule_name: "",
  //         workDuration: "1h",
  //         workDurationMins: "0m",
  //         reminderInterval: "30",
  //         breakDuration: localStorage.getItem("breakDuration"),
  //         breakType: localStorage.getItem("breakType") || "",
  //         rules_and_alerts_breaktype_id: localStorage.getItem("rules_and_alerts_breaktype_id") || "",
  //         notificationType: localStorage.getItem("notificationType") || "",
  //         rules_and_alerts_uitext_id: localStorage.getItem("rules_and_alerts_uitext_id") || "",
  //         reminderCount: "3",
  //         restBreak1: true,
  //         acceptBreak: true,
  //         rejectBreak: true,
  //         addViolation: true,
  //         penalty: false,
  //         violation: {
  //           violation_rule_name: "",
  //           workDuration1: "1h",
  //           workDurationMins1: "15m",
  //           notificationType1: localStorage.getItem("notificationType1"),
  //           violationDate1: null,
  //           violation_rules_and_alerts_uitext_id: "2",
  //         },
  //       },
  //     ]);
  // };
  

  const addNewRule = () => {
    // Helper to parse "1h" and "15m" to minutes
    const parseDurationToMinutes = (duration) => {
      const hours = parseInt(duration.match(/(\d+)h/)?.[1] || 0, 10);
      const minutes = parseInt(duration.match(/(\d+)m/)?.[1] || 0, 10);
      return hours * 60 + minutes;
    };
  
    // Helper to convert minutes back to "1h" and "15m" format
    const convertMinutesToDuration = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return {
        workDuration: `${hours}h`,
        workDurationMins: `${minutes}m`,
      };
    };
  
    const convertMinutesToDurationViolation = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return {
        workDuration1: `${hours}h`,
        workDurationMins1: `${minutes}m`,
      };
    };
  
    // Check if there are existing rules
    if (rules.length === 0) {
      // First rule defaults
      setRules([
        ...rules,
        {
          rule_name: "",
          workDuration: "1h",
          workDurationMins: "0m",
          reminderInterval: "30",
          breakDuration: localStorage.getItem("breakDuration"),
          breakType: localStorage.getItem("breakType") || "",
          rules_and_alerts_breaktype_id: localStorage.getItem("rules_and_alerts_breaktype_id") || "",
          notificationType: localStorage.getItem("notificationType") || "",
          rules_and_alerts_uitext_id: localStorage.getItem("rules_and_alerts_uitext_id") || "",
          reminderCount: "3",
          restBreak1: true,
          acceptBreak: true,
          rejectBreak: true,
          addViolation: true,
          penalty: false,
          violation: {
            violation_rule_name: "",
            workDuration1: "1h",
            workDurationMins1: "15m",
            notificationType1: localStorage.getItem("notificationType1"),
            violationDate1: null,
            violation_rules_and_alerts_uitext_id: "2",
          },
        },
      ]);
    } else {
      // Find the last index
      const lastIndex = rules.length - 1;
      const previousRule = rules[lastIndex];
      console.log('previousRule.workDurationMins-->',previousRule.workDurationMins)
      const prevWorkDurationMinutes = parseDurationToMinutes(previousRule.workDuration);
      const prevWorkDurationMinsMinutes = 
      parseDurationToMinutes(previousRule.workDurationMins == '59m' ? `60m` : previousRule.workDurationMins);
      const prevViolationWorkDurationMinutes = parseDurationToMinutes(previousRule.violation.workDuration1);
      const prevViolationWorkDurationMinsMinutes = 
      parseDurationToMinutes(previousRule.violation.workDurationMins1 == '59m' ? `60m` : previousRule.violation.workDurationMins1);
      const prevWorkExpireDuration = previousRule.reminderInterval;
      console.log('prevWorkDurationMinutes-->',prevWorkDurationMinutes)
      console.log('prevWorkDurationMinsMinutes-->',prevWorkDurationMinsMinutes)
      console.log('prevWorkExpireDuration-->',prevWorkExpireDuration)
      const prevTotalMinutes = prevWorkDurationMinutes + prevWorkDurationMinsMinutes;
      const prevViolationTotalMinutes = prevViolationWorkDurationMinutes + prevViolationWorkDurationMinsMinutes;
      console.log('prevTotalMinutes-->',prevTotalMinutes)
      console.log('prevViolationTotalMinutes-->',prevViolationTotalMinutes)
      const differentMins = prevViolationTotalMinutes - prevTotalMinutes;
      const addExtraMins = differentMins > parseInt(prevWorkExpireDuration) ? differentMins : parseInt(prevWorkExpireDuration);
      console.log('addExtraMins-->',addExtraMins)
      const newTotalMinutes = prevTotalMinutes + addExtraMins + 5;
      console.log('newTotalMinutes-->',newTotalMinutes)
      // Calculate new durations
      const newDurations = convertMinutesToDuration(newTotalMinutes);
      const newDurationsViolation = convertMinutesToDurationViolation(newTotalMinutes+15);
      console.log('newDurations-->',newDurations);
      console.log('newDurationsViolation-->',newDurationsViolation);
      const hours = parseInt(newDurations.workDuration.match(/(\d+)h/)?.[1] || 0, 10);
      const minutes = parseInt(newDurations.workDurationMins.match(/(\d+)m/)?.[1] || 0, 10);
      console.log('Preminutes-->',minutes);
      setStartWorkHours((prev) => ({ ...prev, [lastIndex+1]: hours }));
      setStartWorkMins((prev) => ({ ...prev, [lastIndex+1]: minutes }));
      setStartWorkMins1((prev) => ({ ...prev, [lastIndex+1]: minutes }));
      const hoursViolation = parseInt(newDurationsViolation.workDuration1.match(/(\d+)h/)?.[1] || 0, 10);
      const minutesViolation = parseInt(newDurationsViolation.workDurationMins1.match(/(\d+)m/)?.[1] || 0, 10);
      setViolationHours((prev) => ({ ...prev, [lastIndex+1]: hoursViolation }));
      setViolationMins((prev) => ({ ...prev, [lastIndex+1]: minutesViolation }));
      setRules([
        ...rules,
        {
          ...previousRule, // Copy the default structure from the last rule
          ...newDurations, // Apply the new calculated work durations
          rule_name: "", // Reset or customize other fields
          violation: {
            ...previousRule.violation, // Copy violation structure
            violation_rule_name : "",
            ...newDurationsViolation
          },
        },
      ]);
    }
  };
  


  // Delete a rule
  const deleteRule = (index) => {
    const updatedRules = rules.filter((_, i) => i !== index);
    setRules(updatedRules);
  };

  // Modal handling
  const handleShow = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentIndex(null);
  };

  const handleConfirm = () => {
    toast.success("Rule deleted successfully!", {
      position: "top-right",
      autoClose: 4000,
    });
    deleteRule(currentIndex);
    handleClose();
  };

  const numberToWords = (num) => {
    const words = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    return words[num] || num; // Fallback to the number itself if out of range
  };

  const [error, setError] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [violationErrorFields, setViolationErrorFields] = useState([]);

  // const validateRules = () => {
  //   const errors = [];
  //   const errorViolation = [];
  //   rules.forEach((rule, index) => {
  //     if (!rule.rule_name.trim()) {
  //       errors.push(index); // track the rule index with empty rule name
  //     }
  //     console.log('rule.addViolation-->',rule.addViolation)
  //     if(rule.addViolation){
  //       if (!rule.violation.violation_rule_name.trim()) {
  //         console.log('rule.addViolation-->11',rule.addViolation)
  //         errorViolation.push(index); // track the rule index with empty rule name
  //       }
  //       setViolationErrorFields(errorViolation);
  //     }
  //   });
  //   setErrorFields(errors);
  //   return errors.length === 0 && errorViolation.length === 0;
  // };

  const validateRules = () => {
    const errors = [];
    const errorViolation = [];
  
    rules.forEach((rule, index) => {
      // Custom validation for rule_name
      if (!rule.rule_name.trim() || rule.rule_name.trim().length < 5) {
        errors.push(index); // Track the rule index if rule_name is empty or has less than 5 characters
      }
  
      console.log('rule.addViolation-->', rule.addViolation);
      if (rule.addViolation) {
        // Custom validation for violation_rule_name
        if (!rule.violation.violation_rule_name.trim() || rule.violation.violation_rule_name.trim().length < 5) {
          console.log('rule.addViolation-->11', rule.addViolation);
          errorViolation.push(index); // Track the rule index if violation_rule_name is empty or has less than 5 characters
        }
        setViolationErrorFields(errorViolation);
      }
    });
  
    setErrorFields(errors);
    return errors.length === 0 && errorViolation.length === 0;
  };

    // Helper function to convert "3h" and "0m" to total minutes
const parseWorkDurationToMinutes = (workDuration, workDurationMins) => {
  const hours = workDuration ? parseInt(workDuration.replace("h", ""), 10) : 0;
  const minutes = workDurationMins ? parseInt(workDurationMins.replace("m", ""), 10) : 0;
  return hours * 60 + minutes;
};

const convertMinutesToDuration = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return {
    workDuration: `${hours}h`,
    workDurationMins: `${minutes}m`,
  };
};

  const validateWorkDurations = (rules) => {
    for (let i = 0; i < rules.length - 1; i++) {
      let currentTime = parseWorkDurationToMinutes(
        rules[i]?.workDuration,
        rules[i]?.workDurationMins
      );
      const nextTime = parseWorkDurationToMinutes(
        rules[i + 1]?.workDuration,
        rules[i + 1]?.workDurationMins
      );
  
      if (isNaN(currentTime) || isNaN(nextTime)) {
        console.error(`Error: Invalid workDuration or workDurationMins at index ${i} or ${i + 1}.`);
        return false;
      }
  
            const parseDurationToMinutes = (duration) => {
          const hours = parseInt(duration.match(/(\d+)h/)?.[1] || 0, 10);
          const minutes = parseInt(duration.match(/(\d+)m/)?.[1] || 0, 10);
          return hours * 60 + minutes;
        };
        console.log('rules[i - 1]?.workDuration--->',rules[i]?.workDuration)
        const prevWorkDurationMinutes = parseDurationToMinutes(rules[i]?.workDuration);
        console.log('prevWorkDurationMinutes-->',prevWorkDurationMinutes)
        const prevViolationWorkDurationMinutes = parseDurationToMinutes(rules[i]?.violation.workDuration1);
        console.log('prevViolationWorkDurationMinutes-->',prevViolationWorkDurationMinutes)
        const prevViolationWorkDurationMinsMinutes = 
        parseDurationToMinutes(rules[i]?.violation.workDurationMins1 == '59m' ? `60m` : rules[i]?.violation.workDurationMins1);
        const prevWorkDurationMinsMinutes = 
        parseDurationToMinutes(rules[i]?.workDurationMins == '59m' ? `60m` : rules[i]?.workDurationMins);
        const prevWorkExpireDuration = rules[i]?.reminderInterval;
        console.log('prevWorkDurationMinutes-->',prevWorkDurationMinutes)
        console.log('prevWorkDurationMinsMinutes-->',prevWorkDurationMinsMinutes)
        console.log('prevWorkExpireDuration-->',prevWorkExpireDuration)
        const prevTotalMinutes = prevWorkDurationMinutes + prevWorkDurationMinsMinutes;
        const prevViolationTotalMinutes = prevViolationWorkDurationMinutes + prevViolationWorkDurationMinsMinutes;
        console.log('prevTotalMinutes-->',prevTotalMinutes)
        console.log('prevViolationTotalMinutes-->',prevViolationTotalMinutes)
        const differentMins = prevViolationTotalMinutes - prevTotalMinutes;
        const addExtraMins = differentMins > parseInt(prevWorkExpireDuration) ? differentMins : parseInt(prevWorkExpireDuration);
        console.log('addExtraMins-->',addExtraMins)
        currentTime = currentTime + addExtraMins;
        const newDurations = convertMinutesToDuration(currentTime);
        const hours = parseInt(newDurations.workDuration.match(/(\d+)h/)?.[1] || 0, 10);
        const minutes = parseInt(newDurations.workDurationMins.match(/(\d+)m/)?.[1] || 0, 10);
      if (nextTime <= currentTime) {
        console.error(
          `Error: Work duration at index ${i + 1} (${rules[i + 1]?.workDuration} ${rules[i + 1]?.workDurationMins}) should be greater than index ${i} (${rules[i]?.workDuration} ${rules[i]?.workDurationMins}).`
        );
        toast.error(`Error:The Continuous hours for ${rules[i+1]?.rule_name} must be greater than (${hours}h ${minutes}m).`, {
          position: 'top-right',
          autoClose: 8000,
        });
        return false;
      }
    }
  
    console.log("Validation passed: All work durations are valid in ascending order.");
    return true;
  };
  
  const validateWorkDurations1 = (rulesArray) => {
    for (let i = 0; i < rulesArray.length; i++) {
      const rule = rulesArray[i];
  
      if (!rule.violation) {
        toast.error(`Rule ${i + 1}: Violation object is missing.`, {
          position: "top-right",
        });
        return false;
      }
  
      // Convert main rule durations to total minutes
      const mainWorkDuration =
        parseInt(rule.workDuration.replace("h", "")) * 60 +
        parseInt(rule.workDurationMins.replace("m", ""));
  
      // Convert violation durations to total minutes
      const violationWorkDuration =
        parseInt(rule.violation.workDuration1.replace("h", "")) * 60 +
        parseInt(rule.violation.workDurationMins1.replace("m", ""));
  
      // Check if violation duration > main duration
      if (violationWorkDuration <= mainWorkDuration) {
        toast.error(
          `Error : The violation of continuous hours for ${rule.rule_name} should not exceed the continuous break hours for the same.`,{ 
            position: "top-right" ,
            autoClose: 8000,
          });
        return false;
      }
    }
    return true;
  };
  
  console.log('errorFields-->',errorFields)
    const onNexts = () => {
    if (!validateRules()) {
      console.log('Proceed to the next step11');
      return;
    }
    const isValid1 = validateWorkDurations1(rules);
    if (!isValid1) {
      console.log("Validation failed in validateWorkDurations().");
      return;
    }
    const isValid = validateWorkDurations(rules);
    if (!isValid) {
      // alert("Validation failed: Each work duration must be greater than the previous one.");
      return;
    }
    onNext();
    console.log('Proceed to the next step');
  };

  return (
    <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
      <Card.Body className="policyFormCard">
        <h4 className="text-primary mb-4 text-center policyFormTitle">Rules</h4>
        <div className="row addNewRuleDiv">
          <div className="col-md-4 mb-3">
            <button className="btn addNewRuleBtn" onClick={addNewRule}>
              <i className="fa fa-plus" aria-hidden="true"></i> New Rule
            </button>
          </div>
        </div>

        {/* Map through each rule and display it */}
        <div className="mainRuleCreateDiv rule-custom-scrollbar" style={{ maxHeight: "500px", overflowY: "auto",padding:"10px" }}>
          {rules.map((rule, index) => (
              <div key={index} className="mb-4 ruleCreationDiv">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="col-md-1">
                    <Form.Check
                      type="switch"
                      id={`rest-break-1-${index}`}
                      checked={rule.restBreak1}
                      onChange={(e) => handleChange('restBreak1', e.target.checked, index)}
                    />
                  </div>
                  <div className="col-md-4 rule_title">
                    <input
                      type="text"
                      className="inputWithBottomBorder"
                      placeholder="Rule name"
                      value={rule.rule_name}
                      maxLength={50}
                      autoFocus
                      onChange={(e) => handleChange('rule_name', e.target.value, index)}
                    />
                  </div>
                  <div className="col-md-3">
                  </div>
                  {index !== 0 && 
                  <div className="col-md-4 deleteRuleDiv">
                    <button
                      className="btn deleteRuleBtn"
                      onClick={() => handleShow(index)}
                      // onClick={() => deleteRule(index)}
                      >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>}
                </div>
                {/* Show error message under the rule name input field */}
                {errorFields.includes(index) && (
                  <div className="text-danger register-error rule_validation">Rule name must be at least 5 characters long.</div>
                )}
                <div className="mt-3 ruleDesc">
                  If the staff has been at work for{" "}
                  <Dropdown className="hourDiv">
                    <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                      {rule.workDuration}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                      {generateHourOptions(index)}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="hourDiv">
                    <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                      {rule.workDurationMins}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                      {generateMinuteOptions('workDurationMins',index)}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Dropdown className="hourDiv">
                    <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                      {rule.notificationType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                      <Dropdown.Item className="dropdownList" onClick={() => handleChange('notificationType', "send notification to the manager & admin", index)}>
                        send notification to the manager & admin
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdownList" onClick={() => handleChange('notificationType', "send notification to the user", index)}>
                        send notification to the user
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdownList" onClick={() => handleChange('notificationType', "force checkout", index)}>
                        force checkout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Dropdown className="hourDiv">
                      <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                          {rule.notificationType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      {ruleType.map((item) => (
                        <Dropdown.Item 
                        className="dropdownList" onClick={() => handleChange('notificationType',  { text: item.text_to_be_displayed, id: item.rules_and_alerts_uitext_id }, index)}
                          key={item.rules_and_alerts_uitext_id}
                        >
                          {item.text_to_be_displayed}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {rule.notificationType !== "force checkout" ?
                  (
                    <>
                    to take a{" "}
                    <Dropdown className="hourDiv">
                        <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                            {rule.breakType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {breakType.map((item) => (
                          <Dropdown.Item 
                          className="dropdownList" onClick={() => handleChange('breakType',  { text: item.rules_and_alerts_breaktype, id: item.rules_and_alerts_breaktype_id }, index)}
                            key={item.rules_and_alerts_breaktype_id}
                          >
                            {item.rules_and_alerts_breaktype}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    </>
                  ) :
                  (
                    <>
                    then staff will be checked out {" "}
                    </>
                  )  
                }
                  {/* <Dropdown className="hourDiv">
                    <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                      {rule.breakType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                      <Dropdown.Item className="dropdownList" onClick={() => handleChange('breakType', "rest break", index)}>
                        rest break
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdownList" onClick={() => handleChange('breakType', "meal break", index)}>
                        meal break
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  for the next{" "}
                  <Dropdown className="hourDiv">
                    <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                      {rule.breakDuration}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                      {generateMinuteOptionsForBreak('breakDuration',index)}
                    </Dropdown.Menu>
                  </Dropdown>{" "}
                  minutes.
                  This rule shall expire after {" "}
                    <Dropdown className="hourDiv">
                      <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                        {rule.reminderInterval}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto", minWidth: '80px' }}>
                        {generateMinuteOptionsForExprire('reminderInterval',index)}
                      </Dropdown.Menu>
                    </Dropdown>
                    minutes
                </div>

                <div className="mt-3 rulesSubDesc">
                  <h6 className="ruleheadTag">When this event occurs, the employees can:</h6>
                  <Form.Check
                    type="switch"
                    style={{ pointerEvents: 'none' }}
                    id={`accept-break-${index}`}
                    label="Accept and Start the break time"
                    checked={rule.acceptBreak}
                    onChange={(e) => handleChange('acceptBreak', e.target.checked, index)}
                    className="mt-2"
                  />
                  <Form.Check
                    type="switch"
                    id={`reject-break-${index}`}
                    label="Reject/Waive the break"
                    checked={rule.rejectBreak}
                    onChange={(e) => handleChange('rejectBreak', e.target.checked, index)}
                    className="mt-2"
                  />
                  {/* <Form.Check
                    type="checkbox"
                    label="Add a penalty of 1 hour pay at regular rate of pay per work day"
                    checked={rule.penalty}
                    onChange={(e) => handleChange('penalty', e.target.checked, index)}
                    className="mt-3 formCheckLabel"
                  /> */}

                  <Form.Check
                    type="switch"
                    id={`violation-${index}`}
                    style={{ pointerEvents: 'none' }}
                    label="Include a violation section?"
                    checked={rule.addViolation}
                    onChange={(e) => handleChange('addViolation', e.target.checked, index)}
                    className="mt-2"
                  />

                    {/* Conditionally render input field if rejectBreak is true */}
                    {rule.addViolation && (
                      <div className="mt-3 violationDesc">
                        <div className="col-md-4 col-lg-4 rule_title">
                          <input
                            type="text"
                            className="inputWithBottomBorder violationName"
                            placeholder="Violation name"
                            maxLength={50}
                            value={rule.violation.violation_rule_name}
                            
                            onChange={(e) => handleChange('violation_rule_name', e.target.value, index, 'violation_rule_name')}
                          />
                        </div>
                        {/* <div className="col-md-4 col-lg-4">
                          <span className="text-danger bold violationText">Violation</span>
                        </div> */}
                        <div className="col-md-4 col-lg-4">
                        </div>
                        {violationErrorFields.includes(index) && (
                          <div className="text-danger register-error rule_violation_validation">Violation name is required</div>
                        )}
                        <div className="mt-3 violationDesc1">
                          If the staff has been at work for{" "}
                          <Dropdown className="hourDiv">
                            <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                              {rule.violation.workDuration1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                              {generateHourOptions1(index)}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Dropdown className="hourDiv">
                            <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                              {rule.violation.workDurationMins1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto", minWidth: '80px' }}>
                              {generateMinuteOptions1(index)}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Dropdown className="hourDiv">
                            <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                              {rule.violation.notificationType1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {ruleTypeViolation.map((item) => (
                                <Dropdown.Item 
                                  className="dropdownList" 
                                  onClick={() => handleChange('notificationType1', { text: item.text_to_be_displayed, id: item.rules_and_alerts_uitext_id }, index, 'notificationType1')}
                                  key={item.rules_and_alerts_uitext_id}
                                >
                                  {item.text_to_be_displayed}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          {rule.violation.notificationType1 !== "force checkout" ?
                          (
                            <>
                              to review the{" "} 
                              <span className="">violation</span>
                            </>
                          ) :
                          (
                            <>
                          may be required.
                            </>
                          )}
                        </div>
                      </div>
                    )}

                  {/* <div className="mt-3 rulesSubDesc1">
                    That rule expires after {" "}
                    <Dropdown className="hourDiv">
                      <Dropdown.Toggle variant="light" size="sm" className="dropdownToggle durationSelectedValue">
                        {rule.reminderInterval}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto", minWidth: '80px' }}>
                        {generateMinuteOptionsForExprire('reminderInterval',index)}
                      </Dropdown.Menu>
                    </Dropdown>
                    minutes.
                  </div> */}
                </div>
              </div>

          ))}
        </div>

        {/* Buttons */}
        <div className="d-flex justify-content-end mt-4">
          <Button className="previousBtn" variant="light" onClick={onPrevious}>
            Previous
          </Button>
          <Button className="previousBtn" variant="primary" onClick={onNexts}>
            Next
          </Button>
        </div>
      </Card.Body>
      
      {/* Bootstrap Modal */}
      {showModal && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dimmed background
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content rounded-3 shadow-lg">
              <div className="modal-header bg-primary deleteMgeHeader text-white">
                <h5 className="modal-title">
                  <i className="bi bi-exclamation-triangle-fill me-2"></i>
                  Confirm Delete
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center">
                <p className="text-secondary modal-body-desc">
                  Are you sure you want to delete this rule? <br />
                  {/* <strong>This action cannot be undone.</strong> */}
                </p>
              </div>
              <div className="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary px-4 deleteNoBtn"
                  onClick={handleClose}
                  style={{ borderRadius: '8px' }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-danger px-4 deleteYesBtn"
                  onClick={handleConfirm}
                  style={{ borderRadius: '8px' }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

const SaveStep = ({ onPrevious, formData,policyCallBack,premiseId }) => {

  const { showToast } = useToast();

  const handleSubmit = () => {
    // Here, handle form submission logic (e.g., API call)
    console.log("Form Submitted:", formData);
      const authToken = localStorage.getItem('authToken');
      const createPolicy = async () => {
        try {
          const headers = {
            "Authorization": `Bearer ${authToken}`
          };
          const policyCreateResponse = await apiRequest(
            `web_api/admin/insertBreakRules`,
            'POST',
            formData,
            headers
          );
      
          // console.log('policyCreateResponse--->', policyCreateResponse);
      
          if (policyCreateResponse?.data?.status_code === 200) {
            const policy_details = policyCreateResponse.data;
            // console.log('policy_details--->', policy_details);
      
            // Call the callback function
            policyCallBack("created");
      
            // Show the toast message after policyCallBack
            setTimeout(() => {
              toast.success('Policy created successfully!', {
                position: 'top-right',
                autoClose: 4000,
              });
            }, 0); // Add a small delay to ensure policyCallBack completes
          } else {
            // Handle API response with errors or non-200 status codes
            const errorMessage = policyCreateResponse?.data?.message || 'Something went wrong.';
            toast.error(`${errorMessage}`, {
              icon: <FaTimes style={{ color: 'red', fontSize: '20px' }} />,
              position: 'top-right',
              autoClose: 4000,
            });
          }
        } catch (error) {
          // Handle network or unexpected errors
          // console.error('Error while creating policy:', error);
          toast.error('Failed to create policy. Please try again later.', {
            position: 'top-right',
            autoClose: 4000,
          });
        }
      };
      
      
      createPolicy();
      
  };

  // const [isAgreed, setIsAgreed] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const handleCheckboxChange = (e) => {
  //   setIsAgreed(e.target.checked);
  // };

  // const handleSave = () => {
  //   if (isAgreed) {
  //     handleSubmit();
  //   } else {
  //     toast.info("Please review and agree to the guidelines for creating compliant break and rest policies.", {
  //       position: "top-right",
  //       autoClose: 4000,
  //     });
  //   }
  // };

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
    if (e.target.checked) {
      setErrorMessage(""); // Clear error when checkbox is checked
    }
  };

  const handleSave = () => {
    if (isAgreed) {
      setErrorMessage(""); // Clear error before submitting
      handleSubmit(); // Call your submit logic
    } else {
      setErrorMessage("Please review and agree to the guidelines for creating compliant break and rest policies.");
    }
  };

  return (
  <Card className="shadow-sm p-4" style={{ borderRadius: "10px" }}>
    <Card.Body>
      {/* Title */}
      {/* <h4 className="text-primary mb-4 text-center policyFormTitle">Guidelines for Creating Compliant Break and Rest Policies</h4> */}

      {/* Terms and Conditions Section */}
      <div
        className="mt-4 p-4"
        style={{
          backgroundColor: "#ffff",
          border: "1px solid #ddd", // Subtle border
          borderRadius: "8px", // Rounded corners
          boxShadow: "2px 2px 12px 2px rgba(0, 0, 0, 0.15)"
        }}
      >
        {/* Descriptive Text */}
        <h4 className="text-primary mb-4 text-center policyFormTitle">Guidelines for Creating Compliant Break and Rest Policies</h4>

        {/* Detailed Terms */}
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }} className="termsDesc">
          <li className="mb-2">
          Consult your labor attorney before establishing policies, as compliance requirements vary by state. 
          </li>
          <li className="mb-2">
          It is crucial to ensure your organization's policies comply with these regulations to provide employees with the 
          appropriate rest and meal breaks required by state laws.
          </li>
          <li className="mb-2">
          This tool is designed to assist in creating break and rest policies; however, the employer holds the responsibility for ensuring compliance with local, state, 
          and federal laws.
          </li>
          <li className="mb-2">
          Additionally, employees must enable notifications for BusinessTime to receive timely reminders and alerts for breaks and rest periods.
          </li>
        </ul>

        {/* Checkbox Agreement */}
        <Form.Check
          type="checkbox"
          id = "term-and-condition"
          label="I confirm that I have read and adhered to the guidelines for creating compliant break and rest policies."
          className="mt-3 agreeDiv"
          checked={isAgreed}
          onChange={handleCheckboxChange}
        />
          {/* Error message below the checkbox */}
          {errorMessage && (
            <p className="text-danger" style={{ color: "red", marginTop: "5px",fontSize:"12px",marginLeft:"25px",
              fontWeight: 600 }}>{errorMessage}</p>
          )}
      </div>

      {/* Buttons */}
      {/* <div className="d-flex justify-content-end mt-4">
        <Button className="previousBtn me-2" variant="light" onClick={onPrevious}>
          Previous
        </Button>
        <Button
          className="saveBtn"
          variant="primary"
          onClick={() => {
            if (!isAgreed) {
              alert("Please review and agree to the guidelines for creating compliant break and rest policies.");
              return;
            }
            handleSave();
          }}
        >
          Save
        </Button>
      </div> */}
      {/* Buttons */}
      <div className="d-flex justify-content-end mt-4">
        <Button className="previousBtn" variant="light" onClick={onPrevious}>
          Previous
        </Button>
        <Button className="previousBtn" variant="primary"         
          onClick={() => {
            handleSave();
          }}>
          Save
        </Button>
      </div>
    </Card.Body>
  </Card>
  );
};

const PolicyForm = ( {policyCallBack,premiseId,premiseName,policyFormbackButton,policyList} ) => {
  // console.log('PremiseIdPolicyForm',premiseId)
  const [currentStep, setCurrentStep] = useState(0);
  const [policyName, setPolicyName] = useState("");
  const [policyDate, setPolicyDate] = useState("");
  const [rule_name, setRuleTitle] = useState("");
  const [workDuration, setWorkDuration] = useState("1h");
  const [workDurationMins, setWorkDurationMins] = useState("5m");
  const [breakDuration, setBreakDuration] = useState("5m");
  const [breakType, setBreakType] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [rules_and_alerts_uitext_id, setNotificationTypeId] = useState("");
  const [reminderInterval, setReminderInterval] = useState("10m");
  const [reminderCount, setReminderCount] = useState("3");
  const [restBreak1, setRestBreak1] = useState(true);
  const [acceptBreak, setAcceptBreak] = useState(false);
  const [rejectBreak, setRejectBreak] = useState(false);
  const [penalty, setPenalty] = useState(false);

  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 2));
  const handlePrevious = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const customerDetails = JSON.parse(localStorage.getItem('customerDetails'));
  // console.log('premiseId--->',premiseId)
  const formData = {
    user_id : customerDetails.userId,
    policy_id : "",
    premise_id : premiseId,
    policy_name : localStorage.getItem("policyName"),
    effective_start_date : localStorage.getItem("policyDate"),
    clone_policy_id : "",
    is_new_policy : "yes",
    array_insert_breakrules : JSON.parse(localStorage.getItem("rules")),
    // breakDuration,
    // breakType,
    // notificationType,
    // rules_and_alerts_uitext_id,
    // reminderInterval,
    // reminderCount,
    // restBreak1,
    // acceptBreak,
    // rejectBreak,
    // penalty,
  };

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col lg={8}>
          {/* Step Progress and Bar at the top */}
          <StepProgress currentStep={currentStep} />

          {/* Form steps */}
          {currentStep === 0 && (
            <PoliciesStep
              policyName={policyName}
              setPolicyName={setPolicyName}
              policyFormbackButton={policyFormbackButton}
              policyList={policyList}
              policyDate={policyDate}
              setPolicyDate={setPolicyDate}
              onNext={handleNext}
              premiseName ={premiseName}
            />
          )}
          {currentStep === 1 && (
            <RulesStep
              onPrevious={handlePrevious}
              onNext={handleNext}
              rule_name = {rule_name}
              setRuleTitle = {setRuleTitle}
              workDuration={workDuration}
              setWorkDuration={setWorkDuration}
              workDurationMins={workDurationMins}
              setWorkDurationMins={setWorkDurationMins}
              breakDuration={breakDuration}
              setBreakDuration={setBreakDuration}
              breakType={breakType}
              setBreakType={setBreakType}
              notificationType={notificationType}
              setNotificationType={setNotificationType}
              reminderInterval={reminderInterval}
              setReminderInterval={setReminderInterval}
              reminderCount={reminderCount}
              setReminderCount={setReminderCount}
              restBreak1={restBreak1}
              setRestBreak1={setRestBreak1}
              acceptBreak={acceptBreak}
              setAcceptBreak={setAcceptBreak}
              rejectBreak={rejectBreak}
              setRejectBreak={setRejectBreak}
              penalty={penalty}
              setPenalty={setPenalty}
            />
          )}
          {currentStep === 2 && <SaveStep policyCallBack={policyCallBack} premiseId={premiseId} onPrevious={handlePrevious} formData={formData} />}
        </Col>
      </Row>
    </Container>
  );
};

export default PolicyForm;
