// src/components/DashboardLayout.js
import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import "./Premises.css";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../contexts/ToastContext";
import { apiRequest } from "../../services/api";
import Loader from "../Common/Loader";
import PremiseList from "./PremiseList";
import { Breadcrumb } from "react-bootstrap";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AddPremise from "./PremiseAction";

const Premises = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pageLoading = (data) => {
    setLoading(data);
  };

  const premiseData = (data) => {
    console.log("premiseData111--->", data);
  };

  const [activeMenu, setActiveMenu] = useState(null);
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [premiseToDelete, setPremiseToDelete] = useState(null);

  const handleEditPremise = (premiseData) => {
    console.log("Editing premise:", premiseData);
    navigate("/premise/update", {
      state: { premise: premiseData },  // Passing premise data here
    });
    // Add your edit logic here
  };

  const handleRemovePremise = (premiseId) => {
    console.log("Removing premise with ID:", premiseId);
    setPremiseToDelete(premiseId);
    setShowModal(true);
    // Add your remove logic here
  };

  const toggleMenu = (premiseId) => {
    setActiveMenu(activeMenu === premiseId ? null : premiseId);
  };
  

  // Confirm delete and call API
  const confirmDelete = () => {
    if (premiseToDelete) {
      handleRemovePremiseAPI(premiseToDelete);
      console.log('premiseToDelete--->',premiseToDelete)
      setShowModal(false);
      setPremiseToDelete(null);
    }
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setPremiseToDelete(null);
  };

  const authToken = localStorage.getItem("authToken");

  const handleRemovePremiseAPI = async (premise_id) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const deletePremise = await apiRequest(
        `web_api/admin/deletePremise/${premise_id}`,
        "POST",
        null,
        headers
      );

      // console.log('policyCreateResponse--->', policyCreateResponse);

      if (deletePremise?.data?.status === 200) {
        const policy_details = deletePremise.data;
        setLoading(false);
        fetchAllPremise();
        toast.success(policy_details.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } else {
        // Handle API response with errors or non-200 status codes
        const errorMessage =
        deletePremise?.data?.message || "Something went wrong.";
        setLoading(true);
        toast.error(`${errorMessage}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    } catch (error) {
      // Handle network or unexpected errors
      // console.error('Error while creating policy:', error);
      toast.error("Failed to delete premises. Please try again later.", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const Title = "Premises";

  const { toastMessage, clearToast } = useToast();
  const [premiseList, setPremiseList] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term

  const handleCardClick = (premise) => {
    premiseData(premise); // Set the selected premise
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term as user types
  };

  const filteredPremises = premiseList.filter((premise) => {
    return (
      premise.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      premise.city.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);

  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const fetchAllPremise = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const premiseResponse = await apiRequest(
        `web_api/admin/getPremisesByUserId?customer_account_id=${customerDetails.customerAccountId}`,
        "GET",
        null,
        headers
      );

      if (
        premiseResponse?.data?.status_code === 200 &&
        premiseResponse?.data?.data?.premise_details
      ) {
        const premise_details = premiseResponse.data?.data?.premise_details;
        setPremiseList(premise_details);
        localStorage.setItem('PremisesList',JSON.stringify(premise_details));
        setTimeout(() => {
          pageLoading(false);
        }, 200);
      } else {
        setTimeout(() => {
          pageLoading(false);
        }, 200);
        console.error(
          "Error: Invalid status code",
          premiseResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    pageLoading(true);
    fetchAllPremise();
  }, []);

  const handleAddPremise = () => {
    navigate("/premise/create");
  };

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar />
        <ToastContainer />
        {loading && <Loader />}
        <div className="content">
          <div className="contentHeader d-flex align-items-center justify-content-between pb-3">
            <div className="contentDiv">
              <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>{Title}</Breadcrumb.Item>
              </Breadcrumb>
              {/* <span className="contentTitle">Report</span> */}
              {/* <span className='contentTitle'>{Title}</span> */}
            </div>
            {/* Search bar on the same row */}
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange} // Handle input change
              />
            </div>
          </div>
          {/* <div className="premiseDetails">
            <div className="row premisesListDiv">
              <div className="col-lg-12 policyAddRow PremiseCreateBtn">
                    <button className='btn policyAddBtn' onClick={()=>{handleAddPremise()}}> <i className="fa fa-plus" aria-hidden="true"></i> New Premises</button>
              </div>
              <div className="row mt-0 g-4">
                {filteredPremises.length > 0 ? (
                  filteredPremises.map((premiseData) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                      key={premiseData.premise_id}
                    >
                      <div
                        className="card setting-card"
                        onClick={() => handleCardClick(premiseData)} // Handle card click
                        style={{ cursor: "pointer" }}
                      >
                        <div className="card-image-div">
                          <img
                            src={premiseData.photo}
                            className="card-img-top"
                            alt={premiseData.title}
                          />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{premiseData.name}</h5>
                          <p className="card-text">
                            {premiseData.city} - {premiseData.state}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1 style={{ fontSize: "1.2rem", fontWeight: "bold", color: "#2d64bc" }}>
                      No Premises Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no premises available. Create a new premise to get started.
                    </p>
                    <button
                      className="btn policyAddBtn"
                      style={{ padding: "4px 10px", fontSize: "0.8rem" }}
                    >
                      <i className="fa fa-plus p-1" aria-hidden="true"></i>
                      Create Premises
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>   */}

          <div className="premiseDetails">
            <div className="row premisesListDiv">
              <div className="col-lg-12 policyAddRow PremiseCreateBtn">
                <button className="btn policyAddBtn" onClick={handleAddPremise}>
                  <i className="fa fa-plus" aria-hidden="true"></i> New Premises
                </button>
              </div>
              <div className="row mt-0 g-4">
                {filteredPremises.length > 0 ? (
                  filteredPremises.map((premiseData) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                      key={premiseData.premise_id}
                    >
                      <div
                        className="card setting-card position-relative"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="card-image-div"
                          onClick={() => handleCardClick(premiseData)} // Handle card click
                        >
                          <img
                            src={premiseData.photo}
                            className="card-img-top"
                            alt={premiseData.title}
                          />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{premiseData.name}</h5>
                          <p className="card-text">
                            {premiseData.city} - {premiseData.state}
                          </p>
                        </div>
                        {/* Three-dot menu */}
                        <div
                          className="menu-container position-absolute"
                          style={{ top: "10px", right: "10px" }}
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                            onClick={() => toggleMenu(premiseData.premise_id)}
                            style={{ cursor: "pointer",paddingLeft:"10px",paddingRight:"1px" }}
                          ></i>
                          {activeMenu === premiseData.premise_id && (
                            <div className="premiseActionDropDownMenu show shadow">
                              <button
                                className="btn premiseActionItem"
                                onClick={() => handleEditPremise(premiseData)}
                              >
                                Edit
                              </button>
                              <button
                                className="btn premiseActionItem text-danger"
                                onClick={() =>
                                  handleRemovePremise(premiseData.premise_id)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: "#2d64bc",
                      }}
                    >
                      No Premises Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no premises available. Create a new
                      premise to get started.
                    </p>
                    <button
                      className="btn policyAddBtn"
                      style={{ padding: "4px 10px", fontSize: "0.8rem" }}
                      onClick={handleAddPremise}
                    >
                      <i className="fa fa-plus p-1" aria-hidden="true"></i>
                      Create Premises
                    </button>
                  </div>
                )}
              </div>
            </div>
            {showModal && (
              <div
                className="modal show d-block"
                tabIndex="-1"
                role="dialog"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content rounded-3 shadow-lg">
                    <div className="modal-header bg-primary deleteMgeHeader text-white">
                      <h5 className="modal-title">
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        Confirm Delete
                      </h5>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        onClick={closeModal}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body text-center">
                      <p className="text-secondary modal-body-desc">
                        Are you sure you want to delete this premises? <br />
                        {/* <strong>This action cannot be undone.</strong> */}
                      </p>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary px-4 deleteNoBtn"
                        onClick={closeModal}
                        style={{ borderRadius: "8px" }}
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger px-4 deleteYesBtn"
                        onClick={confirmDelete}
                        style={{ borderRadius: "8px" }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Premises;
